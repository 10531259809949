import { FC } from "react"
import { PlanDetailDTO } from "../../types/api"

type Props = {
  plan: PlanDetailDTO
}

const AccountPlan: FC<Props> = ({
  plan
}) => {
  // const annualPrice = (plan.amount * 0.01).toFixed(2)
  const monthlyPrice = (plan.amount / 12 * 0.01).toFixed(2)

  return (
    <div className="account-plan">
      <div className="account-plan__header">
        <div className="account-plan__title">{plan.name}</div>

        <div className="text-sm">
          <span className="font-semibold">{monthlyPrice}€&nbsp;</span>
          / mese
        </div>
        {/* <Text weight={600}>{annualPrice}€</Text> */}
      </div>

      <div className="account-plan__body">
        <div className="text-sm">
          <span className="font-semibold">{plan.metadata.annualCredits}&nbsp;</span>
          crediti annuali
        </div>
      </div>
    </div>
  )
}

export default AccountPlan
