import classNames from "classnames"
import { createElement, FC, HTMLProps } from "react"
import { ColSpan } from "../types/components"

export interface ColProps extends Omit<HTMLProps<HTMLElement>, "wrap"> {
  as?: keyof JSX.IntrinsicElements
  xs?: ColSpan
  sm?: ColSpan
  md?: ColSpan
  lg?: ColSpan
  xl?: ColSpan
  xxl?: ColSpan
}

const Col: FC<ColProps> = ({
  as = "div",
  children,
  className,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  ...props
}) => {
  return (
    createElement(
      as,
      {
        className: classNames(
          xs ? `col-span-${xs}` : "col-span-full",
          {
            [`sm:col-span-${sm}`]: !!sm,
            [`md:col-span-${md}`]: !!md,
            [`lg:col-span-${lg}`]: !!lg,
            [`xl:col-span-${xl}`]: !!xl,
            [`2xl:col-span-${xxl}`]: !!xxl
          },
          className
        ),
        ...props
      },
      children
    )
  )
}

export default Col
