import classNames from "classnames"
import { FC, ReactElement, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import useError from "../../hooks/useError"
import { GetPatientDTO } from "../../types/api"
import api from "../../util/api"
import Input from "../Input"
import Row from "../Row"
import Text from "../Text"

type Data = {
  email: string
}

type Props = {
  id: number
  data?: Data
  onSuccess(data: GetPatientDTO): void
}

const EmailForm: FC<Props> = ({
  id,
  data,
  onSuccess
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  const { onError } = useError()
  const { control, formState, handleSubmit } = useForm<Data>({ mode: "onChange", defaultValues: {
    email: data?.email || ""
  } })

  const onSubmit = async(data: Data): Promise<void> => {
    if (!loading) {
      setLoading(true)

      try {
        const res = await api.patients.edit(id, data)

        onSuccess(res.data.payload)
      } catch (err) {
        onError(err)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <form
      id="email-form"
      className={classNames(loading && "disabled")}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <Text className="mb-4 font-semibold">Inserisci email</Text>

      <Row className="mb-8">
        <Controller
          name="email"
          control={control}
          rules={{ required: "Campo obbligatorio" }}
          render={({ field: { onChange, value } }): ReactElement => {
            return (
              <Input
                type="email"
                autoComplete="new-password"
                value={value}
                onChange={onChange}
                error={formState.errors.email?.message}
              />
            )
          }}
        />
      </Row>
    </form>
  )
}

export default EmailForm
