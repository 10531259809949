import { FC } from "react"
import { useNavigate } from "react-router-dom"
import useMonitoring from "../hooks/useMonitoring"
import { MonitoringDetails } from "../types/api"
import DotMenu from "./DotMenu"
import Row from "./Row"

type Props = {
  data: MonitoringDetails
  onSuccess(): void
}

const MonitoringTableActions: FC<Props> = ({
  data,
  onSuccess
}) => {
  const navigate = useNavigate()
  const { requestEnd, restoreMonitoring, archiveMonitoring } = useMonitoring<MonitoringDetails>()

  return (
    <Row className="table__actions" justify="center">
      <DotMenu
        options={[
          { label: "Visualizza", action: (): void => navigate(`/monitoring/${data.id}`) },
          data.active
            ? { label: "Concludi", action: (): void => requestEnd(data, () => onSuccess()) }
            : data.archived
              ? { label: "Rendi visibile", action: (): void => restoreMonitoring(data.id, () => onSuccess()) }
              : { label: "Archivia", action: (): void => archiveMonitoring(data.id, () => onSuccess()) }
        ]}
        onClick={(e): void => e.stopPropagation()}
      />
    </Row>
  )
}

export default MonitoringTableActions
