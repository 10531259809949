import { FC, useState } from "react"
import Title from "../components/Title"
import FeedbackSteps from "../components/FeedbackSteps"
import Row from "../components/Row"
import Button from "../components/Button"
import { Link } from "react-router-dom"
import Message from "$components/Message"

const Feedback: FC = () => {
  const [completed, setCompleted] = useState<boolean>(false)

  return (
    <>
      {!completed
        ?
        <>
          <Title className="mb-8">Lasciaci un feedback o segnala un problema</Title>
          <FeedbackSteps onSuccess={(): void => setCompleted(true)} />
        </>
        :
        <Message
          title="Grazie per il vostro contributo"
          icon="check"
          theme="success"
        >
          Il vostro messaggio è stato inviato correttamente.<br/>
          Verrete contattati il prima possibile.<br/>
          Vi ricordiamo che in caso di urgenza potete contattarci<br/>
          al numero <a className="text-blue underline" href="tel:00393920346629">3920346629</a>

          <Row justify="center" className="mt-4">
            <Button className="button--fixed-l" theme="blue-outline" onClick={(): void => setCompleted(false)}>Invia un altro messaggio</Button>
          </Row>

          <Row className="mt-2" justify="center">
            <Link to="/">
              <Button className="button--fixed-l">Torna alla dashboard</Button>
            </Link>
          </Row>
        </Message>
      }
    </>
  )
}

export default Feedback
