import { FC, useState } from "react"
import PhoneConfirmationForm from "$components/form/PhoneConfirmationForm"
import PhoneVerificationForm from "$components/form/PhoneVerificationForm"
import Text from "../Text"
import Title from "../Title"

const AccountPhone: FC = () => {
  const [phone, setPhone] = useState<string>()
  const [submitted, setSubmitted] = useState<boolean>(false)

  return (
    <>
      <Title className="mb-4 text-center" uppercase align="center">{
        submitted
          ? "Conferma codice"
          : "Numero di telefono"
      }</Title>

      <Text className="mb-4 text-center" size="sm">{
        submitted
          ? "Inserite il codice ricevuto via SMS"
          : "Questo numero di telefono verrà utilizzato esclusivamente da Tracymed per notificarvi le urgenze e non verrà condiviso ai pazienti"
      }</Text>

      {!submitted || !phone
        ? <PhoneVerificationForm phone={phone} onSuccess={(phone: string): void => {
          setPhone(phone)
          setSubmitted(true)
        }} />
        :
        <PhoneConfirmationForm
          phone={phone}
          onCancel={(): void => setSubmitted(false)}
        />
      }
    </>
  )
}

export default AccountPhone
