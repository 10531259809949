import Card from "$components/Card"
import InputPassword from "$components/InputPassword"
import classNames from "classnames"
import { FC, ReactElement, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Button from "../components/Button"
import Checkbox from "../components/Checkbox"
import Col from "../components/Col"
import Icon from "../components/Icon"
import Input from "../components/Input"
import Row from "../components/Row"
import Text from "../components/Text"
import { passwordRegex } from "../constants/regex"
import useError from "../hooks/useError"
import useQuery from "../hooks/useQuery"
import { RegistrationDTO } from "../types/api"
import api from "../util/api"

const Signup: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)

  const { control, formState, getValues, handleSubmit } = useForm<RegistrationDTO & { privacy: boolean }>()
  const { fieldErrors, onError } = useError()
  const navigate = useNavigate()

  const query = useQuery()

  const onSubmit = async(data: RegistrationDTO & { privacy: boolean }): Promise<void> => {
    if (!loading) {
      setLoading(true)

      try {
        const params = { ...data } as RegistrationDTO & { privacy?: boolean }
        delete params.privacy

        if (query.get("distibutor")) {
          params.distributor = query.get("distributor") as string
        }

        await api.account.register(params)

        setSubmitted(true)
      } catch (err) {
        onError(err)
      } finally {
        setLoading(false)
      }
    }
  }

  const requestConfirmation = async(): Promise<void> => {
    try {
      await api.account.sendConfirmation({ email: getValues().email })

      toast.success("Richiesta mandata con successo, controllate la vostra casella email")
    } catch (err) {
      onError(err)
    }
  }

  return (
    <div className="container py-10 min-h-full grid grid-cols-12 items-center">
      <Col lg={6} className="lg:col-start-4">
        <h1 className="flex justify-center mb-4" aria-label="Tracymed">
          <Icon name="logo" size={50} />
        </h1>

        <Card>
          <div className="text-xl font-bold text-center">{submitted ? "Congratulazioni!" : "Registrati"}</div>

          {submitted
            ?
            <>
              <Text className="mb-4">
                La registrazione è andata a buon fine.<br/>
                A breve riceverete una mail di verifica all"indirizzo da voi indicato.
              </Text>

              <Row className="mb-4">
                <Text size="sm">Non avete ricevuto la mail?&nbsp;</Text>
                <Text className="underline cursor-pointer" size="sm" theme="info" onClick={requestConfirmation}>Richiedete un nuovo invio</Text>
              </Row>

              <Row justify="center">
                <Link to="/login">
                  <Button>Vai al login</Button>
                </Link>
              </Row>
            </>
            :
            <>
              <div className="mb-4 text-center text-sm">Sei già registrato? <span className="text-blue underline cursor-pointer" onClick={(): void => navigate("/login")}>Vai al login</span></div>

              <form className={classNames("grid grid-cols-12 gap-4", loading && "disabled")} onSubmit={handleSubmit(onSubmit)}>
                <Col>
                  <Controller
                    name="studioName"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Campo obbligatorio"
                      }
                    }}
                    defaultValue=""
                    render={({ field: { onChange, value } }): ReactElement => {
                      return (
                        <Input
                          label="Nome Studio"
                          name="company"
                          value={value}
                          onChange={onChange}
                          error={formState.errors.studioName?.message}
                        />
                      )
                    }}
                  />
                </Col>

                <Col md={6}>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Campo obbligatorio"
                      }
                    }}
                    defaultValue=""
                    render={({ field: { onChange, value } }): ReactElement => {
                      return (
                        <Input
                          label="Cognome"
                          name="last-name"
                          value={value}
                          onChange={onChange}
                          error={formState.errors.lastName?.message}
                          autoComplete="family-name"
                        />
                      )
                    }}
                  />
                </Col>

                <Col md={6}>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Campo obbligatorio"
                      }
                    }}
                    defaultValue=""
                    render={({ field: { onChange, value } }): ReactElement => {
                      return (
                        <Input
                          label="Nome"
                          name="first-name"
                          value={value}
                          onChange={onChange}
                          error={formState.errors.firstName?.message}
                          autoComplete="given-name"
                        />
                      )
                    }}
                  />
                </Col>

                <Col>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Campo obbligatorio"
                      }
                    }}
                    defaultValue=""
                    render={({ field: { onChange, value } }): ReactElement => {
                      return (
                        <Input
                          label="Email"
                          type="email"
                          name="email"
                          value={value}
                          onChange={onChange}
                          error={formState.errors.email?.message}
                        />
                      )
                    }}
                  />
                </Col>

                <Col md={6}>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Campo obbligatorio"
                      },
                      validate: (value: string): string | boolean => {
                        return passwordRegex.test(value) || "La password non rispetta le condizioni sotto indicate"
                      }
                    }}
                    defaultValue=""
                    render={({ field: { onChange, value } }): ReactElement => {
                      return (
                        <InputPassword
                          label="Password"
                          name="password"
                          value={value}
                          onChange={onChange}
                          error={fieldErrors.password || formState.errors.password?.message}
                        />
                      )
                    }}
                  />
                </Col>

                <Col md={6}>
                  <Controller
                    name="matchPassword"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Campo obbligatorio"
                      },
                      validate: (value): string | boolean => {
                        const password = getValues().password
                        return value === password || "La password non corrisponde a quella scelta"
                      }
                    }}
                    defaultValue=""
                    render={({ field: { onChange, value } }): ReactElement => {
                      return (
                        <InputPassword
                          label="Conferma password"
                          name="password"
                          value={value}
                          onChange={onChange}
                          error={fieldErrors.matchPassword || formState.errors.matchPassword?.message}
                        />
                      )
                    }}
                  />
                </Col>

                <Col>
                  <Text size="xs">La password deve contenere almeno 8 caratteri, di cui almeno una lettera minuscola, una lettera maiuscola e un numero.</Text>
                </Col>

                <Col>
                  <Controller
                    name="privacy"
                    control={control}
                    defaultValue={false}
                    rules={{
                      required: {
                        value: true,
                        message: "Campo obbligatorio"
                      }
                    }}
                    render={({ field: { onChange, value } }): ReactElement => {
                      return (
                        <Checkbox
                          checked={!!value}
                          onChange={onChange}
                          label={
                            <Text size="sm" theme={formState.errors?.privacy ? "error" : "default"}>Accetto <Text size="sm" className="underline" theme={formState.errors?.privacy ? "error" : "info"} as="a" href="https://www.iubenda.com/termini-e-condizioni/85720515" target="_blank" rel="nofollow noreferrer">Termini e Condizioni</Text> e <Text size="sm" className="underline" theme={formState.errors?.privacy ? "error" : "info"} as="a" href="https://www.iubenda.com/privacy-policy/85720515" target="_blank" rel="nofollow noreferrer">Privacy Policy</Text> *</Text>
                          }
                        />
                      )
                    }}
                  />
                </Col>

                <Col>
                  <Row justify="center" className="pt-6">
                    <Button type="submit">Conferma</Button>
                  </Row>
                </Col>
              </form>
            </>
          }
        </Card>
      </Col>
    </div>
  )
}

export default Signup
