import { FC, useEffect, useState } from "react"
import { toast } from "react-toastify"
import useError from "../../hooks/useError"
import { PaymentMethodDetails } from "../../types/api"
import api from "../../util/api"
import AccountPaymentForm from "../accountComplete/AccountPaymentForm"
import Button from "../Button"
import LoaderInline from "../LoaderInline"
import PaymentMethod from "../PaymentMethod"
import Title from "../Title"

const AccountPayments: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodDetails[]>([])
  const [showStripeForm, setShowStripeForm] = useState<boolean>(false)
  const [updatingMethods, setUpdatingMethods] = useState<boolean>(false)

  const { onError } = useError()

  const getPaymentMethods = async(): Promise<void> => {
    if (!loading) {
      setUpdatingMethods(true)
    }

    try {
      const { data } = await api.payments.getPaymentMethods()

      setPaymentMethods(data.payload?.paymentMethods ?? [])
    } catch (err) {
      onError(err)
    }

    if (!loading) {
      setUpdatingMethods(false)
    }
  }

  useEffect(() => {
    void (async(): Promise<void> => {
      await getPaymentMethods()

      setLoading(false)
    })()
  }, [])

  return (
    <>
      <Title size="s" className='mb-3'>Metodi di pagamento</Title>

      {loading
        ? <LoaderInline />
        :
        <>
          {paymentMethods.length
            ?
            <ul>
              {paymentMethods.map((method: PaymentMethodDetails) => (
                <li key={method.id} className="mb-2">
                  <PaymentMethod
                    method={method}
                    deletable={paymentMethods.length > 1}
                    disabled={updatingMethods}
                    onDelete={(methods: PaymentMethodDetails[]): void => setPaymentMethods(methods)}
                  />
                </li>
              ))}
            </ul>
            : showStripeForm
              ? null
              : <div className="text-sm text-gray-400">Nessun metodo di pagamento presente</div>
          }

          <div className='mt-4'>
            {showStripeForm
              ?
              <AccountPaymentForm isAddingMethod onMethodAdded={(): void => {
                toast.success("Metodo di pagamento aggiunto con successo")
                void getPaymentMethods()
                setShowStripeForm(false)
              }} />
              :
              <Button theme="blue-outline" onClick={(): void => setShowStripeForm(true)}>Aggiungi</Button>
            }
          </div>
        </>
      }
    </>
  )
}

export default AccountPayments
