import { FC } from "react"
import { GetCouponDTO, PlanDetailDTO } from "../types/api"
import Text from "./Text"

type Props = {
  className?: string
  data: GetCouponDTO
  plans: PlanDetailDTO[]
}

const CouponInfo: FC<Props> = ({
  className,
  data,
  plans
}) => {
  const getPlanNames = (): string => {
    if (data.products) {
      return data.products.map(id => plans.find(p => p.id === id)?.name).join(" ")
    }

    return ""
  }

  return (
    <div className={className}>
      <Text size="sm" theme="success" className="font-semibold">{data.name}</Text>

      <Text size="sm">{
        `Sconto ${data.amountOff ? `di ${data.amountOff}` : `del ${data.percentOff}%`} ${data.duration === "forever" ? "per sempre" : data.duration === "once" ? "per un anno" : `per ${data.maxRedemptions} anni`} ${data.products ? `sui piani: ${getPlanNames()}` : "su tutti i piani"}.`
      }</Text>
    </div>
  )
}

export default CouponInfo
