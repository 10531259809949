import classNames from "classnames"
import { forwardRef, KeyboardEvent, MouseEvent, useEffect, useImperativeHandle, useRef, useState } from "react"
import { formatDate, validateDate, validatePartialDate } from "$util/date"
import Calendar from "./Calendar"
import Icon from "./Icon"
import Input, { InputProps } from "./Input"
import { removeCharAt } from "$util/string"

const getInputValue = (numbers: string): string => {
  const trimmed = numbers.substring(0, 8)
  const day = trimmed.slice(0, 2)
  const month = trimmed.slice(2, 4)
  const year = trimmed.slice(4, 8)

  return `${day}${day.length === 2 ? "/" : ""}${month || ""}${month.length === 2 ? "/" : ""}${year || ""}`
}

interface DatePickerRef {
  openCalendar(): void
  closeCalendar(): void
  getStringValue(): string
  setStringValue(date: string): void
  getDateValue(): Date | null
  setDateValue(date: Date | string): void
  isValid(): boolean
}

type Omitted = "type" | "value" | "inputRef"
interface Props extends Omit<InputProps, Omitted> {
  initialValue?: string
  onChange?(value: string): void
  fillPlaceholder?: boolean
  showCalendar?: boolean
  autoValidate?: boolean
}

const DatePicker = forwardRef<DatePickerRef, Props>(({
  className,
  initialValue: propsInitialValue,
  onChange: propsOnChange,
  error: propsError,
  fillPlaceholder,
  showCalendar = true,
  autoValidate = true,
  ...props
}, ref) => {
  const initialValue = useRef<string>(propsInitialValue || "").current
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const prevValue = useRef<string>(initialValue || "")
  const lastKey = useRef<string>()
  const cursorPosition = useRef<number>(0)
  const input = useRef<HTMLInputElement>(null)

  const isValid = (): boolean => {
    return input.current?.value?.length === 10 ? validateDate(input.current.value) as boolean : false
  }
  const getDateValue = (): Date | null => {
    if (isValid()) {
      const values = input.current!.value.split("/")
      return new Date(
        parseInt(values[2], 10),
        parseInt(values[1], 10) - 1,
        parseInt(values[0], 10)
      )
    }
    return null
  }
  const setDateValue = (date: string): void => {
    if (input.current && validateDate(date)) {
      input.current.value = date
      propsOnChange?.(date)
    }
  }

  useImperativeHandle(ref, () => ({
    openCalendar: (): void => {
      setCalendarOpen(true)
    },
    closeCalendar: (): void => {
      setCalendarOpen(false)
    },
    getStringValue: (): string => {
      return input.current?.value || ""
    },
    setStringValue: (date: string): void => {
      if (input.current && validateDate(date)) {
        input.current.value = date
        propsOnChange?.(date)
      }
    },
    getDateValue,
    setDateValue,
    isValid
  }))

  const onChange = (value: string): void => {
    const numbers = value.split("/").join("")
    let result = ""
    if (/^\d{1,}$/.test(numbers)) {
      result = getInputValue(numbers)

      if (input.current) {
        input.current.value = result
        prevValue.current = result

        const pos = cursorPosition.current
        cursor: {
          if (lastKey.current === "Delete") {
            if (pos === 2 || pos === 5) {
              result = getInputValue(removeCharAt(result, pos + 1).split("/").join("").substring(0, 8))
              input.current.value = result
              prevValue.current = result
              input.current.setSelectionRange(pos + 1, pos + 1)
            } else {
              input.current.setSelectionRange(pos, pos)
            }
            break cursor
          }

          if (lastKey.current === "Backspace") {
            if (pos === 3 || pos === 6) {
              result = getInputValue(removeCharAt(result, pos - 2).split("/").join("").substring(0, 8))
              input.current.value = result
              prevValue.current = result
              input.current.setSelectionRange(pos - 2, pos - 2)
            } else if (pos === 4 || pos === 7) {
              input.current.setSelectionRange(pos - 2, pos - 2)
            } else {
              input.current.setSelectionRange(pos - 1, pos - 1)
            }
            break cursor
          }

          if (lastKey.current === "/") {
            if (pos === 2 || pos === 5) {
              input.current.setSelectionRange(pos + 1, pos + 1)
            } else {
              input.current.setSelectionRange(pos, pos)
            }
            break cursor
          }

          if (pos === 1 || pos === 2 || pos === 4 || pos === 5) {
            input.current.setSelectionRange(pos + 2, pos + 2)
          } else {
            input.current.setSelectionRange(pos + 1, pos + 1)
          }
        }
      }
    } else {
      if (input.current) {
        result = value ? prevValue.current : ""
        input.current.value = result
        prevValue.current = result
        if (result) {
          input.current.setSelectionRange(cursorPosition.current, cursorPosition.current)
        }
      }
    }

    if (autoValidate) {
      setError(!validatePartialDate(...result.split("/")))
    }

    propsOnChange?.(result)
  }
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    cursorPosition.current = (e.target as HTMLInputElement).selectionStart || 0
    lastKey.current = e.key

    if (fillPlaceholder && props.placeholder && !cursorPosition.current && e.key === "ArrowRight") {
      if (input.current) {
        input.current.value = props.placeholder
        prevValue.current = props.placeholder
        propsOnChange?.(props.placeholder)
      }
    }
  }

  const onIconClick = (e: MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    setCalendarOpen(!calendarOpen)
  }

  useEffect(() => {
    if (calendarOpen) {
      const handler = (): void => {
        setCalendarOpen(false)
      }
      window.addEventListener("click", handler)

      return (): void => {
        window.removeEventListener("click", handler)
      }
    }
  }, [calendarOpen])

  useEffect(() => {
    if (input.current) {
      input.current.value = initialValue || ""
    }
  }, [input.current])

  return (
    <div className={classNames("date-picker", className)}>
      <Input
        onChange={onChange}
        onKeyDown={onKeyDown}
        inputRef={input}
        suffix={
          showCalendar
            ?
            <div className="date-picker__icon" onClick={onIconClick}>
              <Icon name="calendar" />
            </div>
            : null
        }
        error={propsError || error}
        {...props}
      />

      {showCalendar && calendarOpen &&
        <Calendar
          initialValue={getDateValue() || undefined}
          onSelect={(date: Date): void => {
            if (input.current) {
              const formatted = formatDate(date)
              input.current.value = formatted
              propsOnChange?.(formatted)
            }
            setCalendarOpen(false)
          }}
        />
      }
    </div>
  )
})

export default DatePicker
