import classNames from "classnames"
import { FC, ReactElement, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { sexOptions } from "$constants/patients"
import { phoneRegex } from "$constants/regex"
import useData from "$hooks/useData"
import useError from "$hooks/useError"
import { EditPatientDTO, GetPatientDTO, NewPatientDTO } from "$types/api"
import { NewPatientField } from "$types/data"
import api from "$util/api"
import { getObjDifference } from "$util/objects"
import Button from "../Button"
import Col from "../Col"
import Input from "../Input"
import Row from "../Row"
import Textarea from "../Textarea"
import DatePicker from "../DatePicker"
import { getFormattedToday } from "$util/format"
import { validateDate } from "$util/validation"
import { defaultContactMethod, defaultPhonePrefix, defaultSex } from "$constants/data"
import Select from "../Select"
import { SelectOption } from "$types/components"

type SelectsDefaultValues = {
  defaultContactMethod?: SelectOption<number>
  sex?: SelectOption<number>
}

type Props = {
  className?: string
  data?: GetPatientDTO
  onSuccess(data: GetPatientDTO): void
  showSubmit?: boolean
}

const PatientForm: FC<Props> = ({
  className,
  data,
  onSuccess,
  showSubmit = true
}) => {
  const { contactMethods, phonePrefixes } = useData()
  const initialData = useRef<{ [key: string]: string | number }>(data ? Object.keys(data).reduce((curr, key) => {
    curr[key] = data[key as keyof GetPatientDTO] ?? ""
    return curr
  }, {} as { [key: string]: string | number }) : {
    phonePrefix: phonePrefixes.options[0]?.value || defaultPhonePrefix,
    defaultContactMethod: contactMethods.options[0]?.value || defaultContactMethod,
    sex: defaultSex
  }).current
  const selectsDefaultValues = useRef<SelectsDefaultValues>({
    defaultContactMethod: initialData.defaultContactMethod ? contactMethods.options.find(o => o.value === initialData.defaultContactMethod) as SelectOption<number> : undefined,
    sex: initialData.sex ? sexOptions.find(o => o.value === initialData.sex) : undefined
  }).current

  const { clearErrors, fieldErrors, onError } = useError<NewPatientField>()
  const { control, formState, handleSubmit } = useForm<NewPatientDTO>({
    defaultValues: initialData
  })

  const [disabled, setDisabled] = useState<boolean>(false)

  const onSubmit = async(formData: NewPatientDTO): Promise<void> => {
    setDisabled(true)

    if (Object.keys(fieldErrors).length) {
      clearErrors()
    }

    try {
      if (data) {
        const diff = getObjDifference(
          // eslint-disable-next-line @typescript-eslint/ban-types
          initialData as unknown as Record<string, unknown>,
          // eslint-disable-next-line @typescript-eslint/ban-types
          formData as unknown as Record<string, unknown>,
          ["id", "activeMonitoringId"]
        )

        const sendRequest = Object.keys(diff).length
        let updatedData = data
        if (sendRequest) {
          const { data: { payload } } = await api.patients.edit(data.id, diff as EditPatientDTO)
          updatedData = payload
        }
        toast.success("Dati aggiornati con successo")
        onSuccess(updatedData)
      } else {
        const res = await api.patients.add({ ...formData })

        toast.success("Paziente aggiunto con successo")
        onSuccess(res.data.payload)
      }
    } catch (err) {
      onError(err)
      setDisabled(false)
    }
  }

  return (
    <form
      id="patient-form"
      className={classNames(disabled && "disabled", className)}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="grid grid-cols-12 gap-4">

        <Col md="6">
          <Controller
            name="lastName"
            control={control}
            rules={{ required: "Campo obbligatorio" }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Cognome *"
                  autoComplete="new-password"
                  value={value}
                  onChange={onChange}
                  error={fieldErrors.lastName || formState.errors.lastName?.message}
                  onFocus={(): void => {
                    if (fieldErrors.lastName) {
                      clearErrors("lastName")
                    }
                  }}
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="firstName"
            control={control}
            rules={{ required: "Campo obbligatorio" }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Nome *"
                  autoComplete="new-password"
                  value={value}
                  onChange={onChange}
                  error={fieldErrors.firstName || formState.errors.firstName?.message}
                  onFocus={(): void => {
                    if (fieldErrors.firstName) {
                      clearErrors("firstName")
                    }
                  }}
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="birthDay"
            control={control}
            rules={{
              validate: (value?: string): string | boolean => {
                if (!value?.length) {
                  return true
                }
                if (value.length === 10) {
                  const result = validateDate(value, { max: getFormattedToday() })
                  switch (result) {
                    case true:
                      return true
                    case "max":
                      return "La data non può essere maggiore di quella odierna"
                    default:
                      return "Data non valida"
                  }
                }
                return "Data non valida"
              }
            }}
            render={({ field: { value, onChange } }): ReactElement => {
              return (
                <DatePicker
                  label="Data di nascita"
                  placeholder="GG/MM/AAAA"
                  initialValue={value}
                  onChange={(v: string): void => {
                    onChange(v)
                  }}
                  error={fieldErrors.birthDay || formState.errors.birthDay?.message}
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="cf"
            control={control}
            rules={{
              minLength: {
                value: 16,
                message: "Lunghezza codice fiscale errata"
              },
              maxLength: {
                value: 16,
                message: "Lunghezza codice fiscale errata"
              }
            }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Codice fiscale"
                  maxLength={16}
                  value={value}
                  onChange={onChange}
                  error={fieldErrors.cf || formState.errors.cf?.message}
                  autoComplete="new-password"
                  onFocus={(): void => {
                    if (fieldErrors.cf) {
                      clearErrors("cf")
                    }
                  }}
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="phone"
            control={control}
            rules={{ pattern: {
              value: phoneRegex,
              message: "Campo non valido, inserire solo numeri senza spazi e senza prefisso"
            } }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  type="tel"
                  label="Telefono"
                  value={value}
                  onChange={onChange}
                  error={fieldErrors.phone || formState.errors.phone?.message}
                  autoComplete="new-password"
                  onFocus={(): void => {
                    if (fieldErrors.phone) {
                      clearErrors("phone")
                    }
                  }}
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Email"
                  type="email"
                  value={value}
                  onChange={onChange}
                  error={fieldErrors.email || formState.errors.email?.message}
                  autoComplete="new-password"
                  onFocus={(): void => {
                    if (fieldErrors.email) {
                      clearErrors("email")
                    }
                  }}
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="defaultContactMethod"
            control={control}
            render={({ field: { onChange } }): ReactElement => {
              return (
                <Select
                  label="Modalità di contatto preferita"
                  options={contactMethods.options}
                  defaultValue={selectsDefaultValues.defaultContactMethod}
                  onChange={(option): void => {
                    const { value } = option as SelectOption<number>
                    onChange(value)
                  }}
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="sex"
            control={control}
            render={({ field: { onChange } }): ReactElement => {
              return (
                <Select
                  label="Genere"
                  options={sexOptions}
                  defaultValue={selectsDefaultValues.sex}
                  onChange={(option): void => {
                    const { value } = option as SelectOption<number>
                    onChange(value)
                  }}
                />
              )
            }}
          />
        </Col>

        <Col>
          <Controller
            name="notes"
            control={control}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Textarea
                  label="Note"
                  placeholder="Scrivi delle note aggiuntive"
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          />
        </Col>

        {showSubmit &&
          <Col>
            <Row justify="end">
              <Button type="submit">Conferma</Button>
            </Row>
          </Col>
        }
      </div>
    </form>
  )
}

export default PatientForm
