import { useEffect, useRef, useState } from "react"
import { Column, TableColumnsConfig } from "../types/table"
import { getTableCols } from "../util/table"
import useViewport from "./useViewport"

type UseTableColumnsParams = {
  columns: Column[]
  config: TableColumnsConfig
}

const useTableColumns = (params: UseTableColumnsParams): Column[] => {
  const { device } = useViewport()
  const [columns, setColumns] = useState<Column[]>(getTableCols({ ...params, device }))
  const mounted = useRef<boolean>(false)

  useEffect(() => {
    if (mounted.current) {
      setColumns(getTableCols({ ...params, device }))
    }
  }, [device])

  return columns
}

export default useTableColumns
