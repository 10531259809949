import Icon from "$components/Icon"
import { IconName } from "$types/icon"
import classNames from "classnames"
import { FC } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { sidebar } from "../../pages/wrappers/Private"
import { SidebarLink as SidebarLinkType } from "../../types/routes"
import Row from "../Row"

type Props = {
  link: SidebarLinkType
  icon: IconName
}

const SidebarLink: FC<Props> = ({
  link,
  icon
}) => {
  const { pathname } = useLocation()

  const active = pathname === link.path

  return (
    <li className={classNames(
      "sidebar__item",
      active && "sidebar__item--active"
    )}>
      <NavLink
        className={classNames(
          "sidebar__item-wrapper",
          active && "sidebar__item-wrapper--active"
        )}
        to={link.path}
        onClick={(): void => {
          sidebar.current?.close()
        }}
      >
        <Row justify="between">
          <Row>
            <div className={classNames("flex items-start", active && "text-blue-dark")}>
              <Icon name={icon} size={24} />
            </div>

            <span className="sidebar__item-label">{link.label}</span>
          </Row>
        </Row>
      </NavLink>
    </li>
  )
}

export default SidebarLink
