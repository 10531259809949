export const weekDays = ["D", "L", "M", "M", "G", "V", "S"]
export const months = [
  { value: 1, label: "Gennaio", days: 31 },
  { value: 2, label: "Febbraio", days: 28 },
  { value: 3, label: "Marzo", days: 31 },
  { value: 4, label: "Aprile", days: 30 },
  { value: 5, label: "Maggio", days: 31 },
  { value: 6, label: "Giugno", days: 30 },
  { value: 7, label: "Luglio", days: 31 },
  { value: 8, label: "Agosto", days: 31 },
  { value: 9, label: "Settembre", days: 30 },
  { value: 10, label: "Ottobre", days: 31 },
  { value: 11, label: "Novembre", days: 30 },
  { value: 12, label: "Dicembre", days: 31 }
]