import Checkbox from "$components/Checkbox"
import Col from "$components/Col"
import DatePicker from "$components/DatePicker"
import Info from "$components/Info"
import Input from "$components/Input"
import Row from "$components/Row"
import Select from "$components/Select"
import TimePicker from "$components/TimePicker"
import Title from "$components/Title"
import { defaultContactTime } from "$constants/monitorings"
import { sexOptions } from "$constants/patients"
import { hourRegex, phoneRegex } from "$constants/regex"
import useData from "$hooks/useData"
import useError from "$hooks/useError"
import { EditPatientDTO, ImportMonitoringWrapper } from "$types/api"
import { SelectOption } from "$types/components"
import api from "$util/api"
import { validateDate } from "$util/date"
import { getFormattedToday } from "$util/format"
import { validateHour } from "$util/validation"
import { FC, ReactElement, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"

const formKeys = ["birthday", "contactHour", "doctor", "email", "name", "phoneNumber", "phonePrefixId", "protocol", "sexId", "surname", "taxCode", "farmPlus", "shortMonitoring"] as const
type Picked = typeof formKeys[number]
type FormOutput = Pick<ImportMonitoringWrapper, Picked>
type FormData = Omit<FormOutput, "sexId" | "protocol" | "phonePrefixId"> & {
  sexId?: SelectOption<number>
  protocol?: SelectOption<number>
  phonePrefixId?: SelectOption<number>
}

const getUpdatePatientFields = (formData: FormOutput): EditPatientDTO => {
  const data = {} as EditPatientDTO
  const { birthday, email, name, phoneNumber, phonePrefixId, sexId, surname, taxCode } = formData
  if (birthday) {
    data.birthDay = birthday
  }
  if (email) {
    data.email = email
  }
  if (name) {
    data.firstName = name
  }
  if (surname) {
    data.lastName = surname
  }
  if (phoneNumber) {
    data.phone = phoneNumber
  }
  if (phonePrefixId) {
    data.phonePrefix = phonePrefixId
  }
  if (sexId) {
    data.sex = sexId
  }
  if (taxCode) {
    data.cf = taxCode
  }
  return data
}

type Props = {
  row: ImportMonitoringWrapper
  onSuccess(data: ImportMonitoringWrapper): void
  loading?: boolean
  setLoading?(loading: boolean): void
}

const ImportForm: FC<Props> = ({
  row,
  onSuccess,
  loading,
  setLoading
}) => {
  const { phonePrefixes, protocols } = useData()
  const { onError } = useError()

  const { clearErrors, control, formState, handleSubmit, setError, trigger } = useForm<FormData>({
    defaultValues: formKeys.reduce((obj, key) => {
      switch (key) {
        case "phonePrefixId":
          obj.phonePrefixId = phonePrefixes.options.find(o => o.value === row.phonePrefixId) as SelectOption<number>
          break
        case "protocol":
          obj.protocol = protocols.options.find(o => o.value === row.protocol) as SelectOption<number>
          break
        case "sexId":
          obj.sexId = sexOptions.find(o => o.value === row[key])
          break
        default: {
          // eslint-disable-next-line @typescript-eslint/ban-types
          (obj as unknown as { [key: string]: string | number | boolean | undefined })[key] = row[key]
          break
        }
      }
      return obj
    }, {} as FormData),
    mode: "onChange"
  })

  const onSubmit = async(formData: FormData): Promise<void> => {
    if (!loading) {
      const output = Object.keys(formData).reduce((obj, key) => {
        switch (key) {
          case "phonePrefixId":
          case "protocol":
          case "sexId":
            (obj as { [key: string]: string | number | undefined })[key] = formData[key]?.value
            break
          default:
            (obj as { [key: string]: string | number | undefined })[key] = formData[key as keyof FormData] as string | number
            break
        }
        return obj
      }, {} as FormOutput)

      let result: ImportMonitoringWrapper
      setLoading?.(true)
      try {
        const { data: { payload: { row } } } = await api.monitorings.validateCSVRow({ row: output })

        result = row
      } catch (err) {
        onError(err)
        setLoading?.(false)
        return
      }

      if (row.idPatient) {
        try {
          // TODO: retrieve patient & check diff
          // const { data: { payload } } = await api.patients.getSingle(row.idPatient)
          await api.patients.edit(row.idPatient, getUpdatePatientFields(output))
        } catch (err) {
          onError(err)
          setLoading?.(false)
        }
      }

      onSuccess(result)
    }
  }

  useEffect(() => {
    void (async(): Promise<void> => {
      await trigger()
      if (row.fieldErrors?.sex || row.fieldErrors?.sexId) {
        setError("sexId", {})
      }
    })()
  }, [])

  return (
    <form id="import-form" className="grid grid-cols-12 gap-y-2" onSubmit={handleSubmit(onSubmit)}>
      <Col lg={8} className="grid grid-cols-12 gap-y-2 lg:gap-x-4 lg:pr-5 lg:border-r lg:border-grey-light">
        <Col>
          <Title size="xs">Dati paziente</Title>
        </Col>

        <Col md={6}>
          <Controller
            name="surname"
            control={control}
            rules={{ required: "Campo obbligatorio" }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Cognome *"
                  autoComplete="new-password"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.surname?.message}
                />
              )
            }}
          />
        </Col>

        <Col md={6}>
          <Controller
            name="name"
            control={control}
            rules={{ required: "Campo obbligatorio" }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Nome *"
                  autoComplete="new-password"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.name?.message}
                />
              )
            }}
          />
        </Col>

        <Col md={6}>
          <Controller
            name="birthday"
            control={control}
            rules={{
              validate: (value?: string): string | boolean => {
                if (!value?.length) {
                  return true
                }
                if (value.length === 10) {
                  const result = validateDate(value, { max: getFormattedToday() })
                  switch (result) {
                    case true:
                      return true
                    case "max":
                      return "La data non può essere maggiore di quella odierna"
                    default:
                      return "Data non valida"
                  }
                }
                return "Data non valida"
              }
            }}
            render={({ field: { value, onChange } }): ReactElement => {
              return (
                <DatePicker
                  label="Data di nascita"
                  placeholder="GG/MM/AAAA"
                  initialValue={value}
                  onChange={(v: string): void => {
                    onChange(v)
                  }}
                  error={formState.errors.birthday?.message}
                  showCalendar={false}
                />
              )
            }}
          />
        </Col>

        <Col md={6}>
          <Controller
            name="taxCode"
            control={control}
            rules={{
              minLength: {
                value: 16,
                message: "Lunghezza codice fiscale errata"
              },
              maxLength: {
                value: 16,
                message: "Lunghezza codice fiscale errata"
              }
            }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Codice fiscale"
                  maxLength={16}
                  value={value}
                  onChange={onChange}
                  error={formState.errors.taxCode?.message}
                  autoComplete="new-password"
                />
              )
            }}
          />
        </Col>

        <Col md={6} className="grid grid-cols-12 items-start gap-x-2">
          <Col xs={3}>
            <Controller
              name="phonePrefixId"
              control={control}
              rules={{ required: "Campo obbligatorio" }}
              render={({ field: { onChange, value } }): ReactElement => {
                return (
                  <Select
                    label="Telefono *"
                    placeholder="Prefisso"
                    options={phonePrefixes.options}
                    value={value}
                    onChange={(option): void => {
                      onChange(option as SelectOption<number>)
                    }}
                    components={{
                      IndicatorsContainer: (): ReactElement | null => null
                    }}
                    error={formState.errors.phonePrefixId?.message}
                  />
                )
              }}
            />
          </Col>

          <Col xs={9}>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                pattern: {
                  value: phoneRegex,
                  message: "Campo non valido, inserire solo numeri senza spazi e senza prefisso"
                },
                required: "Campo obbligatorio"
              }}
              render={({ field: { onChange, value } }): ReactElement => {
                return (
                  <Input
                    type="tel"
                    label={<>&nbsp;</>}
                    value={value}
                    onChange={onChange}
                    error={formState.errors.phoneNumber?.message}
                    autoComplete="new-password"
                  />
                )
              }}
            />
          </Col>
        </Col>

        <Col md={6}>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Email"
                  type="email"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.email?.message}
                  autoComplete="new-password"
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="sexId"
            control={control}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Select
                  label="Genere"
                  options={sexOptions}
                  value={value}
                  onChange={(option): void => {
                    onChange(option as SelectOption<number>)
                    if (formState.errors.sexId) {
                      clearErrors("sexId")
                    }
                  }}
                  menuPlacement="top"
                  error={!!formState.errors.sexId}
                />
              )
            }}
          />
        </Col>
      </Col>

      <Col lg={4} className="grid grid-cols-12 content-start gap-y-2 lg:pl-5">
        <Col>
          <Title size="xs">Dati Monitoraggio</Title>
        </Col>

        <Col>
          <Controller
            name="protocol"
            control={control}
            rules={{ required: "Campo obbligatorio" }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Select
                  label="Protocollo *"
                  options={protocols.options}
                  value={value}
                  onChange={(option): void => {
                    onChange(option as SelectOption<number>)
                  }}
                  maxMenuHeight={200}
                  error={formState.errors.protocol?.message}
                />
              )
            }}
          />
        </Col>

        <Col>
          <Controller
            name="contactHour"
            control={control}
            rules={{
              required: "Campo obbligatorio",
              pattern: {
                value: hourRegex,
                message: "Formato errato, usare HH:MM"
              },
              validate: (value?: string): string | boolean => {
                if (!value?.length) {
                  return true
                }
                if (value.length === 5) {
                  const result = validateHour(value)

                  return result === true || "Orario non valido"
                }
                return "Orario non valido"
              }
            }}
            defaultValue=""
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <TimePicker
                  label={
                    <Row>
                      Orario di invio questionario *

                      <Info className="ml-2" text="Si tratta dell'orario in cui verranno inviate le comunicazioni al paziente tramite il metodo di contatto scelto" />
                    </Row>
                  }
                  placeholder={defaultContactTime}
                  fillPlaceholder
                  value={value}
                  onChange={onChange}
                  error={formState.errors.contactHour?.message}
                />
              )
            }}
          />
        </Col>

        <Col>
          <Controller
            name="doctor"
            control={control}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Medico"
                  autoComplete="new-password"
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          />
        </Col>

        <Col>
          <Controller
            name="farmPlus"
            control={control}
            render={({ field: { value, onChange } }): ReactElement => {
              return (
                <>
                  <Row className="mt-1">
                    <Checkbox
                      checked={!!value}
                      onChange={onChange}
                      label="Med+"
                    />

                    <Info className="ml-2" text="Indica la presenza di una prescrizione farmacologica" />
                  </Row>
                </>
              )
            }}
          />
        </Col>

        <Col>
          <Controller
            name="shortMonitoring"
            control={control}
            render={({ field: { value, onChange } }): ReactElement => {
              return (
                <>
                  <Row className="mt-1">
                    <Checkbox
                      checked={!!value}
                      onChange={onChange}
                      label="Monitoraggio rapido"
                    />

                    <Info className="ml-2" text="Limita la durata del monitoraggio ad una singola giornata" />
                  </Row>
                </>
              )
            }}
          />
        </Col>
      </Col>
    </form>
  )
}

export default ImportForm
