import { FC, ReactNode, useState } from "react"
import { AnswerCheckDTO, QuestionDetail } from "../../types/api"
import Button from "../Button"
import BoolAnswer from "./BoolAnswer"
import RangeAnswer from "./RangeAnswer"
import TextAnswer from "./TextAnswer"
import Icon from "../Icon"
import Row from "../Row"
import Text from "../Text"
import Title from "../Title"

type Props = {
  data: QuestionDetail[]
  onComplete(answers: AnswerCheckDTO[]): void
}

type NumAns = {
  description: "integer"
  minValue: number
  maxValue: number
  minLabel: string
  maxLabel: string
  step: number
}

const CheckQuestions: FC<Props> = ({
  data,
  onComplete
}) => {
  const [current, setCurrent] = useState<number>(0)
  const [answers, setAnswers] = useState<AnswerCheckDTO[]>([])

  const goNext = (): void => {
    if (current < data.length - 1) {
      setCurrent(current + 1)
    }
  }

  return (
    <div className="check__questions">
      <div className="check__questions-progress" style={{ ["--progress" as string]: (current + 1) / data.length }}></div>

      <Row className="check__questions-logo" justify="center">
        <Icon name="logo" size={40} />
      </Row>

      {data.map((_, i) => (
        i === current
          ?
          <div className="check__questions-content" key={i}>
            <div>
              <Text className="mb-2 text-center" size="sm" theme="grey">Domanda {current + 1} di {data.length}</Text>

              <Row justify="center">
                <Title className="check__questions-title" weight={600} align="center">{data[current].question}</Title>
              </Row>
            </div>

            <div className="check__answers">
              {/* BOOLEAN */}
              {data[current].answerType.description === "boolean" &&
                <BoolAnswer
                  trueLabel={data[current].trueLabel}
                  falseLabel={data[current].falseLabel}
                  value={answers[current]?.boolAnswer}
                  goNext={goNext}
                  onSelect={(value): void => {
                    setAnswers(answers => {
                      const newAns = [...answers]
                      newAns[current] = {
                        id: data[current].id,
                        boolAnswer: value
                      }
                      return newAns
                    })
                  }}
                />
              }

              {/* RANGE */}
              {data[current].answerType.description === "integer" &&
                ((): ReactNode => {
                  const question = data[current].answerType as NumAns
                  return (
                    <RangeAnswer
                      value={answers[current]?.intAnswer}
                      min={question.minValue}
                      max={question.maxValue}
                      minLabel={question.minLabel}
                      maxLabel={question.maxLabel}
                      step={question.step}
                      goNext={goNext}
                      onSelect={(value): void => {
                        setAnswers(answers => {
                          const newAns = [...answers]
                          newAns[current] = {
                            id: data[current].id,
                            intAnswer: value
                          }
                          return newAns
                        })
                      }}
                    />
                  )
                })()
              }

              {/* TEXTAREA */}
              {data[current].answerType.description === "text" &&
                <TextAnswer
                  value={answers[current]?.texAnswer}
                  onSelect={(value): void => {
                    setAnswers(answers => {
                      const newAns = [...answers]
                      newAns[current] = {
                        id: data[current].id,
                        texAnswer: value
                      }
                      return newAns
                    })
                  }}
                />
              }
            </div>

            <Row className="check__buttons" justify="center">
              <Button
                className="button--fixed"
                theme="red"
                disabled={current === 0}
                onClick={(): void => {
                  setCurrent(current - 1)
                }}
              >Indietro</Button>

              <Button
                className="button--fixed"
                disabled={!answers[current]}
                onClick={(): void => {
                  if (current < data.length - 1) {
                    setCurrent(current + 1)
                  } else {
                    onComplete(answers)
                  }
                }}
              >{current < data.length - 1 ? "Avanti" : "Invia"}</Button>
            </Row>
          </div>
          : null
      ))}
    </div>
  )
}

export default CheckQuestions
