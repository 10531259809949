import classNames from "classnames"
import { FC, useEffect } from "react"
import useData from "$hooks/useData"
import { GetPatientDTO } from "$types/api"
import { SetState } from "$types/components"
import Radio from "./Radio"
import Row from "./Row"
import Text from "./Text"
import Icon from "./Icon"

export type ContactMethodPhoneData = {
  prefix: number
  phone: string
}
export type ContactMethodEmailData = {
  email: string
}

type Props = {
  className?: string
  patient: GetPatientDTO | null
  setPatient: SetState<GetPatientDTO | null>
  value: number
  onChange(value: number): void
  error?: string
  openPhone(data?: ContactMethodPhoneData): void
  openEmail(data?: ContactMethodEmailData): void
}

const ContactMethod: FC<Props> = ({
  className,
  patient,
  value,
  onChange,
  openPhone,
  openEmail,
  error
}) => {
  const { contactMethods } = useData()

  useEffect(() => {
    onChange(patient?.defaultContactMethod!)
  }, [patient])

  return (
    <div className={classNames("contact-method", { "contact-method--error": error }, className)}>
      <Text theme={error ? "error" : "default"} className="mb-2 font-semibold">Metodo di invio *</Text>

      <ul>
        {contactMethods.data.map((method) => (
          <Row
            key={method.id}
            as="li"
            className="contact-method__item"
          >
            <Radio
              key={method.id}
              checked={`${method.id}` === `${value}`}
              onChange={(checked): void => {
                if (patient) {
                  onChange(checked as number)
                }
              }}
              label={method.name}
              value={method.id}
              error={!!error}
              disabled={!patient || (patient && (method.id === 2 ? !patient.email : !patient.phone))}
            />

            {patient
              ?
              <>
                <span className="text-sm">-</span>

                <div className={"contact-method__item-label"}>
                  {patient[method.field as keyof GetPatientDTO]
                    ?
                    <Row>
                      <Text size="sm">{patient[method.field as keyof GetPatientDTO]}</Text>

                      <Row justify="center" className="contact-method__edit ml-2" onClick={(): void => {
                        if (method.id === 2) {
                          openEmail({ email: patient.email! })
                        } else {
                          openPhone({ prefix: patient.phonePrefix!, phone: patient.phone! })
                        }
                      }}>
                        <Icon name="edit" />
                      </Row>
                    </Row>
                    : <Text size="sm" className="contact-method__add uppercase" onClick={(): void => {
                      if (method.id === 2) {
                        openEmail()
                      } else {
                        openPhone()
                      }
                    }}>Aggiungi</Text>
                  }
                </div>
              </>
              : null
            }
          </Row>
        ))}
      </ul>

      {!!error &&
        <Text className="mt-2" size="sm" theme="error">{error}</Text>
      }
    </div>
  )
}

export default ContactMethod
