import Icon from "$components/Icon"
import Info from "$components/Info"
import useData from "$hooks/useData"
import { ImportMonitoringWrapper } from "$types/api"
import classNames from "classnames"
import { FC } from "react"
import { Link } from "react-router-dom"

const errorsMap = {
  PATIENT_DUPLICATE: "Il paziente è già presente in una delle righe precedenti",
  ALREADY_MONITORED: "Il paziente presenta già un monitoraggio attivo"
}
type ErrorCode = keyof typeof errorsMap

const hasError = (row: ImportMonitoringWrapper): boolean => {
  return row.isError || (!!row.fieldErrors && Object.keys(row.fieldErrors).length > 0)
}

export type PreviewTableRowRef = {
  getData(): ImportMonitoringWrapper
}

type Props = {
  editable?: boolean
  row: ImportMonitoringWrapper
  onEditClick(row: ImportMonitoringWrapper): void
  onDeleteClick(row: ImportMonitoringWrapper): void
}

const PreviewTableRow: FC<Props> = ({
  row,
  onEditClick,
  onDeleteClick
}) => {
  const { protocols } = useData()
  const isError = hasError(row)

  return (
    <>
      <tr className={classNames("csv__tr", isError && "csv__tr--error")}>
        <td>
          <div className={classNames(row.fieldErrors?.name && "text-red")}>
            {row.name || "-"}
          </div>
        </td>

        <td>
          <div className={classNames(row.fieldErrors?.surname && "text-red")}>
            {row.surname || "-"}
          </div>
        </td>

        <td>
          <div className={classNames(row.fieldErrors?.phoneNumber && "text-red")}>
            {row.phoneNumber || "-"}
          </div>
        </td>

        <td>
          <div className={classNames(row.fieldErrors?.protocol && "text-red")}>
            {protocols.map.get(row.protocol!)?.name || "-"}
          </div>
        </td>

        <td>
          <div className={classNames(row.fieldErrors?.name && "text-red")}>
            {row.contactHour || "-"}
          </div>
        </td>

        <td>
          {!row.idPatient
            ? <>Nuovo</>
            : <Link className="underline" to={`/patient/${row.idPatient}`}>Esistente</Link>
          }
        </td>

        <td>
          {row.error || Object.keys(row.fieldErrors || {}).length > 0
            ?
            <div className="csv__icon !cursor-help">
              <Info
                icon={row.error ? "alert-triangle" : "file-alert"}
                size="sm"
                text={row.error
                  ? errorsMap[row.error as ErrorCode] || "Errore sconosciuto"
                  : "Qualche campo della tabella è errato, modificate la riga per procedere"
                }
              />
            </div>
            : null
          }
        </td>

        <td>
          <div
            className={classNames("csv__icon text-blue")}
            onClick={(): void => {
              onEditClick(row)
            }}
          >
            <Icon name="pencil" />
          </div>
        </td>

        <td>
          <div
            className={classNames("csv__icon text-red")}
            onClick={(): void => {
              onDeleteClick(row)
            }}
          >
            <Icon name="trash" />
          </div>
        </td>
      </tr>
    </>
  )
}

export default PreviewTableRow
