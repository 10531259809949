import Card from "$components/Card"
import Message from "$components/Message"
import Select from "$components/Select"
import Title from "$components/Title"
import { SelectOption } from "$types/components"
import { FC, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Row from "../components/Row"
import Spinner from "../components/Spinner"

type Section = {
  label: string
  content: string
}

const Protocols: FC = () => {
  const [data, setData] = useState<Section[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [active, setActive] = useState<number>(0)
  const [options, setOptions] = useState<SelectOption<number>[]>([])
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    void (async(): Promise<void> => {
      try {
        const data = await import("../static/protocols")
        const sections = data.default as Section[]
        setOptions(sections.map(({ label }, i) => ({
          value: i,
          label
        })))
        setData(sections)
      } catch (err) {
        setError(true)
      }

      setLoading(false)
    })()
  }, [])

  return (
    <>
      {loading
        ? <Row justify="center"><Spinner /></Row>
        :
        <>
          {error
            ?
            <Message
              title="Si è verificato un errore"
              theme="danger"
            >
              Provate a ricaricare la pagina.<br/>
              Se il problema sussiste <Link className="text-blue underline" to="/feedback">lasciate una segnalazione</Link>
            </Message>
            : data && data.length > 0
              ?
              <div>
                <Title className="mb-6">Protocolli</Title>

                <div className="w-1/2 mb-8">
                  <Select
                    options={options}
                    defaultValue={options[0]}
                    onChange={(option): void => {
                      const o = option as SelectOption<number>

                      setActive(o.value)
                    }}
                  />
                </div>

                <Card>
                  <div dangerouslySetInnerHTML={{ __html: data[active].content }} />
                </Card>
              </div>
              :
              <Message
                title="Si è verificato un errore"
                theme="danger"
              >
                Provate a ricaricare la pagina.<br/>
                Se il problema sussiste <Link className="text-blue underline" to="/feedback">lasciate una segnalazione</Link>
              </Message>
          }
        </>
      }
    </>
  )
}

export default Protocols
