import classNames from "classnames"
import { FC, HTMLProps } from "react"

interface Props extends HTMLProps<HTMLDivElement> {
  flat?: boolean
}

const Box: FC<Props> = ({
  children,
  className,
  flat,
  ...props
}) => {
  return (
    <div className={classNames("box", className, {
      "box--flat": flat
    })} {...props}>{children}</div>
  )
}

export default Box
