import { GetMonitoringOverviewDTO } from "$types/api"
import classNames from "classnames"
import { FC } from "react"
import { Link } from "react-router-dom"
import Col from "../Col"
import PercentageWidget from "../PercentageWidget"
import Title from "../Title"

type Props = {
  className?: string
  data: GetMonitoringOverviewDTO
}

const StatusWidgets: FC<Props> = ({
  className,
  data
}) => {
  const { warning: urgent, delayedWithAPreviousResponse, ok, delayedWithNoResponse: delay, total } = data
  const regular = ok + delayedWithAPreviousResponse
  const urgentPercent = total ? Math.round(urgent / total * 100) : 0
  const delayPercent = total ? Math.round(delay / total * 100) : 0
  let regularPercent = total ? Math.round(regular / total * 100) : 0
  const sum = urgentPercent + delayPercent + regularPercent
  if (sum !== 0 && sum < 100) {
    regularPercent += 100 - (urgentPercent + delayPercent + regularPercent)
  }

  return (
    <div className={classNames("grid grid-cols-12 gap-5", className)}>
      <Col className="md:px-[8%] lg:px-0" lg={6} xl={4}>
        <Link to="/monitorings?monitoringStatus=2">
          <div className="flex flex-col items-center">
            <PercentageWidget size="s" progress={urgentPercent} status="error" />

            <div className="flex items-center gap-x-2 mt-2">
              <Title size="s" align="center" weight={700}>{urgent}</Title>
              <div className="text-lg font-bold whitespace-nowrap text-red">Urgenze</div>
            </div>
          </div>
        </Link>
      </Col>

      <Col className="md:px-[8%] lg:px-0" lg={6} xl={4}>
        <Link to="/monitorings?monitoringStatus=3">
          <div className="flex flex-col items-center">
            <PercentageWidget size="s" progress={delayPercent} status="warning" />

            <div className="flex items-center gap-x-2 mt-2">
              <Title size="s" align="center" weight={700}>{delay}</Title>
              <div className="text-lg font-bold whitespace-nowrap text-yellow">Risposte mancanti</div>
            </div>
          </div>
        </Link>
      </Col>

      <Col className="md:px-[8%] lg:px-0" lg={6} xl={4}>
        <Link to="/monitorings?monitoringStatus=1">
          <div className="flex flex-col items-center">
            <PercentageWidget size="s" progress={regularPercent} />

            <div className="flex items-center gap-x-2 mt-2">
              <Title size="s" align="center" weight={700}>{regular}</Title>
              <div className="text-lg font-bold whitespace-nowrap text-blue">Regolari</div>
            </div>
          </div>
        </Link>
      </Col>
    </div>
  )
}

export default StatusWidgets
