import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Loader from "../components/Loader"
import CheckIntro from "../components/check/CheckIntro"
import CheckQuestions from "../components/check/CheckQuestions"
import CheckEnd from "../components/check/CheckEnd"
import Text from "../components/Text"
import useError from "../hooks/useError"
import { AnswerCheckDTO, GetChecksDTO } from "../types/api"
import api from "../util/api"

type Params = {
  token: string
}

const Check: FC = () => {
  const { token } = useParams<Params>()
  const [started, setStarted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [sending, setSending] = useState<boolean>(false)
  const [sent, setSent] = useState<boolean>(false)
  const [data, setData] = useState<GetChecksDTO>()
  const { onError } = useError()

  const sendAnswers = async(answers: AnswerCheckDTO[]): Promise<void> => {
    setSending(true)

    try {
      await api.checks.send(token!, { answers })

      setSent(true)
    } catch (err) {
      onError(err)
    }

    setSending(false)
  }

  useEffect(() => {
    void (async(): Promise<void> => {
      try {
        const res = await api.checks.get(token!)

        setData(res.data.payload)
      } catch (err) {
        onError(err)
      }

      setLoading(false)
    })()
  }, [])

  return (
    <div className="page check">
      {loading
        ? <Loader />
        : data
          ? !started
            ? <CheckIntro data={data} onStart={(): void => setStarted(true)} />
            : !sent
              ?
              <>
                <CheckQuestions data={data.questions} onComplete={sendAnswers} />
                {sending && <Loader />}
              </>
              : <CheckEnd data={data} />
          : <Text className="py-8 font-semibold text-center" size="lg">Si è verificato un errore</Text>
      }
    </div>
  )
}

export default Check
