import { FC, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Button from "../components/Button"
import Pagination from "../components/Pagination"
import Row from "../components/Row"
import Select from "../components/Select"
import Table from "../components/Table"
import { PatientsSortable, patientsTableClickable, patientsTableColumns, patientTableConfig } from "../constants/tables/patients"
import useError from "../hooks/useError"
import usePageSize from "../hooks/usePageSize"
import { GetPatientsUsingGetParams, PatientDetail } from "../types/api"
import api from "../util/api"
import { getTableRows } from "../util/table"
import { OrderByStatus, Row as RowType, TableData } from "../types/table"
import { SelectOption } from "../types/components"
import Title from "../components/Title"
import Text from "../components/Text"
import Listing from "../components/Listing"
import PatientsFilters from "../components/PatientsFilters"
import useDebounce from "../hooks/useDebounce"
import useTableColumns from "../hooks/useTableColumns"
import Message from "$components/Message"

type RenderItems = {
  pageSizeOption: SelectOption<number>
  orderBy: OrderByStatus<PatientsSortable>
  activeMonitoring: boolean
}

const Patients: FC = () => {
  const [mounted, setMounted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [updating, setUpdating] = useState<boolean>(false)
  const [searching, setSearching] = useState<boolean>(false)
  // params
  const { pageSize, setPageSize, pageSizeOption, pageSizeOptions } = usePageSize()
  const [pagination, setPagination] = useState<{ current: number, total: number }>({ current: 1, total: 1 })
  const [orderBy, setOrderBy] = useState<OrderByStatus<PatientsSortable>>({ orderBy: "lastName", direction: "asc" })
  const [activeMonitoring, setActiveMonitoring] = useState<boolean>(false)
  const [search, setSearch] = useState<string>("")
  const debouncedSearch = useDebounce(search, 500)
  const [params, setParams] = useState<GetPatientsUsingGetParams>({ page: pagination.current, pageSize, ...orderBy })
  // data
  const [data, setData] = useState<TableData<PatientDetail>>({ raw: [], table: [] })
  const tableColumns = useTableColumns({ columns: patientsTableColumns, config: patientTableConfig })
  // render
  const [renderItems, setRenderItems] = useState<RenderItems>({ pageSizeOption, orderBy, activeMonitoring })
  // hooks
  const { onError } = useError()
  const navigate = useNavigate()

  const getPatients = async(): Promise<void> => {
    if (mounted) {
      setUpdating(true)
      setSearching(false)
    }

    try {
      const res = await api.patients.getMultiple(params)

      setData({
        raw: res.data.payload.patients,
        // eslint-disable-next-line @typescript-eslint/ban-types
        table: getTableRows(res.data.payload.patients as unknown as RowType[], patientsTableColumns)
      })
      setPagination({ current: res.data.payload.currentPage, total: res.data.payload.totalPages })
    } catch (err) {
      onError(err)
    } finally {
      if (mounted) {
        setUpdating(false)
        setRenderItems({ pageSizeOption, orderBy, activeMonitoring })
      } else {
        setLoading(false)
      }
    }
  }

  const searchPatients = async(): Promise<void> => {
    setUpdating(true)

    try {
      const res = await api.patients.search(debouncedSearch)

      setData({
        raw: res.data.payload.patients,
        // eslint-disable-next-line @typescript-eslint/ban-types
        table: getTableRows(res.data.payload.patients as unknown as RowType[], patientsTableColumns)
      })
      setPagination({ current: 1, total: res.data.payload.totalPages })
    } catch (err) {
      onError(err)
    } finally {
      setUpdating(false)
      setSearching(true)
    }
  }

  useEffect(() => {
    if (mounted) {
      setParams(p => {
        return { ...p, page: 1, activeMonitoring }
      })
    }
  }, [activeMonitoring])

  useEffect(() => {
    if (mounted) {
      setParams(p => {
        return { ...p, ...orderBy, activeMonitoring }
      })
    }
  }, [orderBy])

  useEffect(() => {
    if (mounted) {
      setParams({ ...params, page: 1, pageSize })
    }
  }, [pageSize])

  useEffect(() => {
    if (mounted) {
      void getPatients()
    }
  }, [params])

  useEffect(() => {
    if (mounted) {
      if (debouncedSearch) {
        void searchPatients()
      } else {
        void getPatients()
      }
    }
  }, [debouncedSearch])

  useEffect(() => {
    void (async(): Promise<void> => {
      await getPatients()
    })()

    setMounted(true)
  }, [])

  return (
    <>
      <Row className="heading mb-8" justify="between">
        <Title>Lista Pazienti</Title>

        <Link to="/add-patient">
          <Button>Aggiungi Paziente</Button>
        </Link>
      </Row>

      <Listing loading={loading} updating={updating}>
        <PatientsFilters
          activeMonitoring={renderItems.activeMonitoring}
          setActiveMonitoring={setActiveMonitoring}
          search={search}
          setSearch={setSearch}
          searching={searching}
        />

        {data.table.length > 0
          ?
          <>
            <Table
              className="my-8"
              columns={tableColumns}
              data={data.table}
              clickableColumns={patientsTableClickable}
              activeCol={{
                id: renderItems.orderBy.orderBy,
                value: renderItems.orderBy.direction
              }}
              cellData={data.raw}
              onColumnClick={(id): void => {
                setOrderBy({
                  orderBy: id as PatientsSortable,
                  direction: params.orderBy ===  id
                    ? params.direction === "desc" ? "asc" : "desc"
                    : "asc"
                })
              }}
              onRowClick={(i: number): void => {
                navigate(`/patient/${data.raw[i].id}`)
              }}
            />

            {!searching &&
              <Row className="table__pagination" justify="between">
                <Row>
                  <Select
                    options={pageSizeOptions}
                    value={renderItems.pageSizeOption}
                    onChange={(option): void => {
                      setPageSize((option as SelectOption<number>).value)
                    }}
                    menuPlacement="top"
                  />

                  <Text size="sm" className="ml-4">Risultati per pagina</Text>
                </Row>

                {pagination.total > 1 &&
                  <Pagination current={pagination.current} total={pagination.total} onChange={(page: number): void => setParams({ ...params, page })} />
                }
              </Row>
            }
          </>
          :
          <Message className="pl-4 mt-6" icon="user-off" title="Nessun paziente trovato" />
        }
      </Listing>

    </>
  )
}

export default Patients
