import Info from "$components/Info"
import PhoneVerificationModal from "$components/modals/PhoneVerificationModal"
import Row from "$components/Row"
import { FC, useState } from "react"
import useAuth from "../../hooks/useAuth"
import Title from "../Title"
import AccountRow from "./AccountRow"

const AccountGeneral: FC = () => {
  const { user } = useAuth()

  const [phoneOpen, setPhoneOpen] = useState<boolean>(false)

  if (user) {
    return (
      <>
        <Title size='s' className='mb-5'>Generale</Title>

        <div className="text-sm font-semibold text-gray-400 uppercase">Dati Studio</div>

        <AccountRow
          label="Nome Studio"
          value={user?.studioName}
        />

        <AccountRow
          label="Nome"
          value={`${user?.lastName} ${user?.firstName}`}
        />

        <div className="mt-6 text-sm font-semibold text-gray-400 uppercase">Contatti</div>

        <AccountRow
          label="Email"
          value={user?.email || "-"}
        />

        <AccountRow
          label={
            <Row>
              Telefono

              <Info className="ml-1" text="Questo è il numero di telefono al quale arriveranno le notifiche in caso di urgenza." />
            </Row>
          }
          value={user?.phone}
          editable
          onEdit={(): void => setPhoneOpen(true)}
        />

        <PhoneVerificationModal open={phoneOpen} close={(): void => setPhoneOpen(false)} />
      </>
    )
  }

  return null
}

export default AccountGeneral
