import Card from "$components/Card"
import Col from "$components/Col"
import Message from "$components/Message"
import Icon from "$components/Icon"
import { authRedirectPath } from "$constants/routes"
import { FC, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Button from "../components/Button"
import Loader from "../components/Loader"
import Row from "../components/Row"
import useAuth from "../hooks/useAuth"
import useError from "../hooks/useError"
import api from "../util/api"

type Params = {
  token: string
}

const SignupConfirmation: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)

  const { user, updateUser, updateCompany } = useAuth()
  const { onError } = useError()
  const navigate = useNavigate()
  const { token } = useParams<Params>()

  useEffect(() => {
    void (async(): Promise<void> => {
      try {
        await api.account.confirm({ token: token! })

        toast.success("Verifica avvenuta correttamente")
        if (user) {
          await updateUser()
          await updateCompany()
        } else {
          navigate(authRedirectPath)
        }
      } catch (err) {
        onError(err)
        setLoading(false)
      }
    })()
  }, [])

  return (
    <div className="page">
      {loading
        ? <Loader />
        :
        <div className="container h-full grid grid-cols-4 items-center">
          <Col lg={2} className="lg:col-start-2">
            <div className="flex justify-center items-center mb-8">
              <Icon name="logo" size={50} />
            </div>

            <Card>
              <Message title="Si è verificato un errore">
                La preghiamo di riprovare o di contattarci a <a href="mailto:support@tracymed.com" className="text-blue underline">support@tracymed.com</a>

                <Row justify="center" className="mt-6">
                  <Link to="/login">
                    <Button>Torna al login</Button>
                  </Link>
                </Row>
              </Message>
            </Card>
          </Col>
        </div>
      }
    </div>
  )
}

export default SignupConfirmation
