import { monitoringStatusOptions } from "$constants/monitorings"
import classNames from "classnames"
import { FC } from "react"
import useData from "../hooks/useData"
import { SelectOption, SetState } from "../types/components"
import Checkbox from "./Checkbox"
import Chip from "./Chip"
import Row from "./Row"
import Select from "./Select"
import Text from "./Text"
import Title from "./Title"

type Props = {
  className?: string
  protocol: SelectOption<number>[]
  setProtocol: SetState<SelectOption<number>[]>
  statuses: SelectOption<number>[]
  setStatuses: SetState<SelectOption<number>[]>
  showArchived: boolean
  setShowArchived: SetState<boolean>
}

const MonitoringsFilters: FC<Props> = ({
  className,
  protocol,
  setProtocol,
  statuses,
  setStatuses,
  showArchived,
  setShowArchived
}) => {
  const { protocols } = useData()

  return (
    <>
      <Row className={classNames("filters", className)}>
        <Title className="w-full md:width-auto" size="xs">Filtra per</Title>

        <Select
          className="filters__select"
          isMulti
          isFilter
          name="protocol"
          placeholder="Protocollo"
          value={protocol}
          options={protocols.options}
          onChange={(values): void => {
            setProtocol((values as SelectOption<number>[]))
          }}
        />

        <Select
          className="filters__select"
          isMulti
          isFilter
          name="statuses"
          placeholder="Stato"
          value={statuses}
          options={monitoringStatusOptions}
          onChange={(values): void => {
            setStatuses((values as SelectOption<number>[]))
          }}
        />

        <Checkbox
          className="mt-2 md:mt-0"
          label="Mostra archiviati"
          checked={showArchived}
          onChange={(value): void => setShowArchived(value)}
        />
      </Row>

      {(protocol.length > 0 || statuses.length > 0) &&
        <div className="flex flex-col gap-y-4 mt-4">
          {protocol.length > 0 &&
            <div className="filters__chips">
              <Text className="font-semibold" size="sm">Protocollo</Text>
              {protocol.map(item => (
                <Chip
                  key={item.value}
                  label={item.label}
                  onClose={(): void => {
                    setProtocol(protocol.filter(({ value }) => value !== item.value))
                  }}
                />
              ))}
            </div>
          }
          {statuses.length > 0 &&
            <div className="filters__chips">
              <Text className="font-semibold" size="sm">Stato</Text>
              {statuses.map(item => (
                <Chip
                  key={item.value}
                  label={item.label}
                  onClose={(): void => {
                    setStatuses(statuses.filter(({ value }) => value !== item.value))
                  }}
                />
              ))}
            </div>
          }
        </div>
      }
    </>
  )
}

export default MonitoringsFilters
