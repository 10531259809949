import Loader from "$components/Loader"
import { createContext, FC, useEffect, useRef, useState } from "react"
import useAuth from "../hooks/useAuth"
import { ContactMethodDetail, ContactMethodsResponse, GetPhonePrefixesResponse, GetProtocolsResponse, PhonePrefix, ProtocolDetails } from "../types/api"
import { ProviderProps, SelectOption } from "../types/components"
import api from "../util/api"

export type GlobalData<T, U = string | number> = {
  data: T[]
  map: Map<number, T>
  options: SelectOption<U>[]
}

export type DataContextType = {
  contactMethods: GlobalData<ContactMethodDetail>
  phonePrefixes: GlobalData<PhonePrefix, number>
  protocols: GlobalData<ProtocolDetails>
}

export const DataContext = createContext<DataContextType>({} as DataContextType)

const DataProvider: FC<ProviderProps> = ({ children }) => {
  // context
  const { user } = useAuth()
  // state
  const [loading, setLoading] = useState<boolean>(true)
  const globalData = useRef<DataContextType>({
    contactMethods: { data: [], map: new Map(), options: [] },
    phonePrefixes: { data: [], map: new Map(), options: [] },
    protocols: { data: [], map: new Map(), options: [] }
  })
  const dataLoaded = useRef<boolean>(false)

  useEffect(() => {
    if (user && !dataLoaded.current) {
      void(async(): Promise<void> => {
        setLoading(true)

        const res = await Promise.allSettled([
          api.data.getContactMethods(),
          api.data.getPhonePrefixes(),
          api.data.getProtocols()
        ])

        // CONTACT METHODS
        if (res[0].status === "fulfilled") {
          const data = (res[0].value.data as ContactMethodsResponse).payload.contactMethods
          if (data) {
            const filtered = data.filter(method => method.id === 3)
            globalData.current.contactMethods = {
              data: filtered,
              map: new Map(filtered.map((item) => [item.id, item])),
              options: filtered.map(({ id: value, name: label }) => ({ label, value }))
            }
          }
        }

        // PHONE PREFIXES
        if (res[1].status === "fulfilled") {
          const data = (res[1].value.data as GetPhonePrefixesResponse).payload.prefixes
          if (data) {
            globalData.current.phonePrefixes = {
              data,
              map: new Map(data.map((item) => [item.id, item])),
              options: data.map(({ id: value, prefix }) => ({ label: `${prefix}`, value }))
            }
          }
        }

        // PROTOCOLS
        if (res[2].status === "fulfilled") {
          const data = (res[2].value.data as GetProtocolsResponse).payload.protocols
          if (data) {
            globalData.current.protocols = {
              data,
              map: new Map(data.map((item) => [item.id, item])),
              options: data.map(({ id: value, name: label }) => ({ label, value }))
            }
          }
        }

        dataLoaded.current = true
        setLoading(false)
      })()
    } else {
      setLoading(false)
    }
  }, [user])

  return (
    <DataContext.Provider value={{
      contactMethods: globalData.current.contactMethods,
      phonePrefixes: globalData.current.phonePrefixes,
      protocols: globalData.current.protocols
    }}>
      {loading || (user && !dataLoaded.current)
        ? <Loader />
        : children
      }
    </DataContext.Provider>
  )
}

export default DataProvider
