import { useEffect, useRef, useState } from "react"
import { defaultPageSize, pageSizes } from "../constants/listing"
import { SelectOption, SetState } from "../types/components"
import cookies from "../util/cookies"

type UsePageSizeReturnType = {
  pageSize: number
  setPageSize: SetState<number>
  pageSizeOptions: SelectOption<number>[]
  pageSizeOption: SelectOption<number>
}

export default function usePageSize(): UsePageSizeReturnType {
  const cookiePageSize = useRef<string | null>(cookies.get("listingPageSize")).current
  const [pageSize, setPageSize] = useState<number>(cookiePageSize ? (!isNaN(parseInt(cookiePageSize)) ? parseInt(cookiePageSize) : defaultPageSize) : defaultPageSize)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [pageSizeOption, setPageSizeOption] = useState<SelectOption<number>>(pageSizes.find(p => p.value === pageSize)!)

  useEffect(() => {
    cookies.set("listingPageSize", pageSize)
    if (pageSizeOption.value !== pageSize) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setPageSizeOption(pageSizes.find(p => p.value === pageSize)!)
    }
  }, [pageSize])

  return {
    pageSize,
    setPageSize,
    pageSizeOption,
    pageSizeOptions: pageSizes
  }
}
