/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/explicit-module-boundary-types */
import classNames from "classnames"
import { HTMLProps, ReactElement } from "react"
import { Column, useTable } from "react-table"
import TableSort from "./TableSort"

export type Row = {
  [key: string]: string | number | undefined
}

interface Props<CellData extends unknown[], CellProps extends unknown> extends Omit<HTMLProps<HTMLTableElement>, "data"> {
  columns: Column[]
  data: Row[]
  cellData?: CellData
  cellProps?: CellProps | CellProps[]
  activeCol?: { id: string, value: "asc" | "desc" }
  clickableColumns?: string[]
  strictColumns?: string[]
  onColumnClick?(id: string): void
  onRowClick?(index: number): void
  rowClickable?(index: number): boolean
}

function Table<CellData extends unknown[], CellProps extends unknown>({
  className,
  columns,
  data,
  cellData,
  cellProps,
  activeCol,
  clickableColumns,
  strictColumns,
  onColumnClick,
  onRowClick,
  rowClickable,
  ...props
}: Props<CellData, CellProps>): ReactElement<Props<CellData, CellProps>> {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns: columns as Column<Row>[], data })

  return (
    <div className={classNames("table__wrapper", className)}>
      <table className="table" {...props} {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                const isClickable = clickableColumns?.includes(column.id)
                const isStrict = strictColumns?.includes(column.id)
                return (
                  <th className={classNames("table__th", { "table__th--clickable": isClickable, "table__th--strict": isStrict })} {...column.getHeaderProps()} onClick={(): void => {
                    if (isClickable && onColumnClick) {
                      onColumnClick(column.id)
                    }
                  }}>
                    <div className="table__th-content">
                      <div className="text-sm font-semibold">
                        {column.render("Header")}
                      </div>

                      {isClickable
                        ? <TableSort className="ml-2" active={activeCol?.id === column.id ? activeCol.value : undefined} />
                        : null
                      }
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                className={classNames("table__tr", {
                  "table__tr--disabled": rowClickable ? !rowClickable(i) : false
                })}
                onClick={onRowClick
                  ? rowClickable
                    ? rowClickable(i)
                      ? (): void => onRowClick(i)
                      : undefined
                    : (): void => onRowClick(i)
                  : undefined
                }
              >
                {row.cells.map(cell => {
                  return (
                    <td className={classNames(
                      "table__td",
                      strictColumns?.includes(cell.column.id) && "table__td--strict"
                    )} {...cell.getCellProps()}>
                      {cell.render("Cell", {
                        additionalProps: {
                          data: cellData?.[i],
                          props: Array.isArray(cellProps) ? cellProps[i] : cellProps
                        }
                      })}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Table
