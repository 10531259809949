import { FC } from "react"
import Icon from "./Icon"
import Input from "./Input"

type Props = {
  className?: string
  value: string
  setValue(value: string): void
}

const PatientsSearch: FC<Props> = ({
  className,
  value,
  setValue
}) => {
  return (
    <Input
      className={className}
      type="text"
      value={value}
      onChange={setValue}
      placeholder="Cerca"
      prefix={
        <div className="input__icon">
          <Icon name="search" />
        </div>
      }
      suffix={
        <div className="input__icon cursor-pointer" onClick={(): void => setValue("")}>
          {!!value &&
            <Icon name="x" />
          }
        </div>
      }
    />
  )
}

export default PatientsSearch
