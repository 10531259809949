import classNames from "classnames"
import { FC, useEffect, useState } from "react"
import { toast } from "react-toastify"
import useAuth from "../../hooks/useAuth"
import useConfirmModal from "../../hooks/useConfirmModal"
import useError from "../../hooks/useError"
import { GetSubscriptionInfoDTO, PlanDetailDTO } from "../../types/api"
import api from "../../util/api"
import Banner from "../Banner"
import Button from "../Button"
import Col from "../Col"
import LoaderInline from "../LoaderInline"
import Row from "../Row"
import Text from "../Text"
import Title from "../Title"
import AccountPlan from "./AccountPlan"

const AccountPlans: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [subscription, setSubscription] = useState<GetSubscriptionInfoDTO>()
  const [plans, setPlans] = useState<PlanDetailDTO[]>([])
  const [updatingSubscription, setUpdatingSubscription] = useState<boolean>(false)
  const [endingTrial, setEndingTrial] = useState<boolean>(false)

  const { updateCompany, updateUser } = useAuth()
  const { openModal } = useConfirmModal()
  const { onError } = useError()

  const getSubscription = async(): Promise<void> => {
    try {
      const { data } = await api.payments.getSubscription()

      setSubscription(data.payload)
    } catch (err) {
      onError(err)
    }
  }

  const endTrial = async(): Promise<void> => {
    setEndingTrial(true)

    try {
      await api.payments.endTrial()
      await getSubscription()
      await updateUser()
      await updateCompany()

      toast.success("Pagamento avvenuto con successo")
    } catch (err) {
      onError(err)
    }

    setEndingTrial(false)
  }

  const cancelSubscription = async(): Promise<void> => {
    setUpdatingSubscription(true)

    try {
      await api.payments.deleteSubscription()
      await getSubscription()
      await updateUser()
      await updateCompany()

      toast.success("Abbonamento cancellato con successo")
    } catch (err) {
      onError(err)
    }

    setUpdatingSubscription(false)
  }

  const undoCancelSubscription = async(): Promise<void> => {
    setUpdatingSubscription(true)

    try {
      await api.payments.undoDeleteSubscription()
      await getSubscription()
      await updateUser()
      await updateCompany()

      toast.success("Abbonamento ripristinato con successo")
    } catch (err) {
      onError(err)
    }

    setUpdatingSubscription(false)
  }

  useEffect(() => {
    void (async(): Promise<void> => {
      try {
        const { data } = await api.payments.getPlans()

        setPlans(data.payload?.plans?.reverse().map((plan: PlanDetailDTO) => ({ ...plan, name: parsePlanName(plan.name) })) || [])
      } catch (err) {
        onError(err)
      }

      await getSubscription()

      setLoading(false)
    })()
  }, [])

  return (
    <div>
      <Title size="s" className='mb-4'>Piani</Title>

      {loading
        ? <LoaderInline />
        :
        <>
          {subscription
            ?
            <Text size='sm'>Il piano scelto è <Text as="span" size='sm' className="font-semibold">{subscription.productName}</Text> e verrà rinnovato il <Text as="span" size='sm' className="font-semibold">{subscription.dateNextRenewal}</Text>.</Text>
            : null
          }

          <Text size='sm' className='mb-8'>La fatturazione è su base annuale.</Text>

          <div className="grid grid-cols-12 gap-x-2 gap-y-5 mb-8">
            {plans.map((plan: PlanDetailDTO) => (
              <Col key={plan.id} lg={4}>
                <AccountPlan
                  plan={plan}
                />
              </Col>
            ))}
          </div>

          <Banner className='mb-8'>
            <Row justify='between'>
              <Text>Vuoi cambiare piano?</Text>

              <a href="mailto:support@tracymed.com" target="_blank" rel="noreferrer">
                <Button>Contatta l'assistenza</Button>
              </a>
            </Row>
          </Banner>

          {subscription?.isTrial && !subscription.willBeCancelled &&
            <div className={classNames((endingTrial || updatingSubscription) && "disabled")}>
              <Title size="xs" className="mb-2">Periodo di prova</Title>

              <Text size="xs" className="mb-4">State utilizzando il periodo di prova, che terminerà il {subscription.trialEnd}. Se avete finito i crediti, potete acquistare l'abbonamento anche prima del rinnovo automatico</Text>

              <Button className="mb-8" onClick={endTrial}>Acquista abbonamento</Button>
            </div>
          }

          <div className={classNames((endingTrial || updatingSubscription) && "disabled")}>
            <Title size="xs" className="mb-2">Cancellazione</Title>

            <Text size="xs" className="mb-4">{subscription?.willBeCancelled
              ? <>L'abbonamento verrà disdetto il <Text size="xs" as="span">{subscription.cancellationDate}</Text>, potete ancora annullare la cancellazione.</>
              : <>Annullando l'abbonamento non verrà addebitato il pagamento al prossimo rinnovo. Potrete comunque continuare ad utilizzare la piattaforma fino alla data di scadenza. <br/>Se la cancellazione dell'abbonamento viene fatta entro due settimane dal suo acquisto verrete rimborsati dell'intera quota.</>
            }</Text>

            <Button theme={subscription?.willBeCancelled ? "blue" : "red"} onClick={(): void => {
              if (subscription?.willBeCancelled) {
                void undoCancelSubscription()
              } else {
                openModal({
                  description: "Confermate la cancellazione dell'abbonamento a Tracymed?",
                  onConfirm: () => {
                    void cancelSubscription()
                  }
                })
              }
            }}>{subscription?.willBeCancelled ? "Ripristina iscrizione" : "Annulla iscrizione"}</Button>
          </div>
        </>
      }
    </div>
  )
}

export default AccountPlans

const parsePlanName = (name: string): string => {
  return name.toLowerCase().replace("tracymed", "")
}
