import classNames from "classnames"
import { FC, ReactElement, useState } from "react"
import { Link } from "react-router-dom"
import Input from "../components/Input"
import Switch from "../components/Switch"
import Button from "../components/Button"
import { Controller, useForm } from "react-hook-form"
import { LoginParams } from "../types/data"
import api from "../util/api"
import Icon from "../components/Icon"
import Row from "../components/Row"
import useError from "../hooks/useError"
import useAuth from "../hooks/useAuth"
import { toast } from "react-toastify"
import Col from "../components/Col"
import Card from "../components/Card"
import InputPassword from "$components/InputPassword"

const Login: FC = () => {
  const { updateUser, updateCompany } = useAuth()
  const { onError } = useError()
  const { control, formState, handleSubmit } = useForm<LoginParams>()
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async(data: LoginParams): Promise<void> => {
    setLoading(true)

    try {
      await api.auth.login(data)
      await updateUser({ onError })
      await updateCompany({ onError })
    } catch (err) {
      onError(err, {
        onResponse: (res) => {
          if (res.status === 401) {
            toast.error("Credenziali errate")
          } else {
            toast.error("Si è verificato un errore inaspettato")
          }
        }
      })
      setLoading(false)
    }
  }

  return (
    <div className="container h-full py-20 grid grid-cols-11 items-center">
      <Col lg={5} className="lg:col-start-4">
        <h1 className="flex justify-center mb-8" aria-label="Tracymed">
          <Icon name="logo" size={50} />
        </h1>

        <Card>
          <div className="text-xl font-bold text-center">Accedi</div>

          <p className="text-xs text-gray-400 text-center mb-4">Inserisci le credenziali per accedere alla dashboard</p>

          <form onSubmit={handleSubmit(onSubmit)} className={classNames(loading && "disabled")}>
            <Controller
              name="username"
              control={control}
              rules={{ required: {
                value: true,
                message: "Campo obbligatorio"
              } }}
              defaultValue=""
              render={({ field: { onChange, value } }): ReactElement => {
                return (
                  <Input
                    className="mb-2"
                    label="Email"
                    type="email"
                    name="email"
                    value={value}
                    onChange={onChange}
                    error={formState.errors.username?.message}
                  />
                )
              }}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: {
                value: true,
                message: "Campo obbligatorio"
              } }}
              defaultValue=""
              render={({ field: { onChange, value } }): ReactElement => {
                return (
                  <InputPassword
                    className="mb-2"
                    label="Password"
                    name="password"
                    value={value}
                    onChange={onChange}
                    error={formState.errors.password?.message}
                  />
                )
              }}
            />

            <div className="flex flex-wrap justify-between gap-3 mt-4 mb-4">
              <div className="w-full md:w-auto flex justify-center">
                <Controller
                  name="remember-me"
                  control={control}
                  defaultValue={true}
                  render={({ field: { onChange, value } }): ReactElement => {
                    return (
                      <Switch
                        label="Resta connesso"
                        checked={value}
                        onChange={onChange}
                      />
                    )
                  }}
                />
              </div>

              <Link className="block w-full md:w-auto text-center text-sm underline" to="/reset-password">Hai dimenticato la password?</Link>
            </div>

            <Row justify="center" className="mb-2">
              <Button type="submit" onClick={handleSubmit(onSubmit)}>Accedi</Button>
            </Row>

            <div className="text-sm text-center">Non hai un account? <Link className="text-blue underline" to="/signup">Registrati</Link></div>
          </form>
        </Card>
      </Col>
    </div>
  )
}

export default Login
