import classNames from "classnames"
import { FC, useEffect, useRef } from "react"

type PickerItem = {
  label: number | string
  value: number
}
type Props = {
  items: PickerItem[]
  selected?: number
  onSelect(value: number): void
}
const CalendarPicker: FC<Props> = ({
  items,
  selected,
  onSelect
}) => {
  const wrapper = useRef<HTMLUListElement>(null)

  useEffect(() => {
    if (wrapper.current) {
      const child = wrapper.current?.querySelector(`[data-id="${selected}"]`)

      if (child) {
        wrapper.current.scrollTop = (child as HTMLLIElement).offsetTop
      }
    }
  }, [])

  return (
    <div className="calendar__picker">
      <ul className="calendar__picker-list" ref={wrapper}>
        {items.map((item, i) => (
          <li
            key={i}
            data-id={item.value}
            className={classNames("calendar__picker-item", selected === item.value && "calendar__picker-item--selected")}
            onClick={(): void => onSelect(item.value)}
          >{item.label}</li>
        ))}
      </ul>
    </div>
  )
}

export default CalendarPicker

// type MonthPickerProps = {
//   selected: number
//   onSelect(month: number): void
// }
// const MonthPicker: FC<MonthPickerProps> = ({
//   selected,
//   onSelect
// }) => {
//   const wrapper = useRef<HTMLUListElement>(null)

//   useEffect(() => {
//     if (wrapper.current) {
//       const child = wrapper.current?.querySelector(`[data-id="${selected}"]`)

//       if (child) {
//         wrapper.current.scrollTop = (child as HTMLLIElement).offsetTop
//       }
//     }
//   }, [])

//   return (
//     <div className="calendar__picker">
//       <ul className="calendar__picker-list" ref={wrapper}>
//         {months.map((month, i) => (
//           <li
//             key={i}
//             data-id={month.value}
//             className={classNames("calendar__picker-item", selected === month.value && "calendar__picker-item--selected")}
//             onClick={(): void => onSelect(month.value)}
//           >{month.label}</li>
//         ))}
//       </ul>
//     </div>
//   )
// }

// type YearPickerProps = {
//   selected: number
//   onSelect(year: number): void
//   min?: number
//   max?: number
// }
// const YearPicker: FC<YearPickerProps> = ({
//   selected,
//   onSelect,
//   min = 1900,
//   max = 2030
// }) => {
//   const years = useRef<number[]>(((): number[] => {
//     return Array.from({ length: max - min }).map((_, i) => min + i)
//   })()).current
//   const wrapper = useRef<HTMLUListElement>(null)

//   useEffect(() => {
//     if (wrapper.current) {
//       const child = wrapper.current?.querySelector(`[data-id="${selected}"]`)

//       if (child) {
//         wrapper.current.scrollTop = (child as HTMLLIElement).offsetTop
//       }
//     }
//   }, [])

//   return (
//     <div className="calendar__picker">
//       <ul className="calendar__picker-list" ref={wrapper}>
//         {years.map((year, i) => (
//           <li
//             key={i}
//             data-id={year}
//             className={classNames("calendar__picker-item", selected === year && "calendar__picker-item--selected")}
//             onClick={(): void => onSelect(year)}
//           >{year}</li>
//         ))}
//       </ul>
//     </div>
//   )
// }