import Card from "$components/Card"
import Col from "$components/Col"
import Header from "$components/header/Header"
import { FC, useState } from "react"
import { toast } from "react-toastify"
import useAuth from "../hooks/useAuth"
import useError from "../hooks/useError"
import api from "../util/api"

const EmailConfirmation: FC = () => {
  const { user } = useAuth()

  const [loading, setLoading] = useState<boolean>(false)

  const { onError } = useError()

  const requestEmail = async(): Promise<void> => {
    if (!loading) {
      setLoading(true)

      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await api.account.sendConfirmation({ email: user!.email })

        toast.success("Richiesta andata a buon fine. A breve riceverete la mail nella casella da voi indicata")
      } catch (err) {
        onError(err)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <div className="page flex flex-col">
      <Header logoutOnly />

      <div className="container flex-1 pt-24 pb-16 grid grid-cols-11 items-center">
        <Col lg={5} className="lg:col-start-4">
          <Card className="text-center">
            <div className="text-2xl font-bold mb-4">Verifica l&apos;email</div>

            <div className="mb-4">Prima di procedere con la registrazione è necessario verificare l&apos;indirizzo email cliccando sul link che avete ricevuto nella casella di posta da voi indicata.</div>

            <div className="text-sm">
              Non avete ricevuto la mail?&nbsp;
              <span className="text-blue-dark underline cursor-pointer" onClick={requestEmail}>Richiedete un nuovo invio</span>
            </div>
          </Card>
        </Col>
      </div>
    </div>
  )
}

export default EmailConfirmation
