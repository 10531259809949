import classNames from "classnames"
import { FC, ReactNode, RefObject, useEffect, useState } from "react"
import useError from "../hooks/useError"
import { GetReportBasicDTO, GetReportRowDTO } from "../types/api"
import api from "../util/api"
import ReportAnswer from "./ReportAnswer"
import Text from "./Text"
import { formatDateHour } from "../util/format"
import { ReportStatus, reportStatusLabels, reportStatusTypes } from "../constants/monitorings"
import Row from "./Row"
import Spinner from "./Spinner"
import Message from "./Message"
import Col from "./Col"

type Props = {
  report: GetReportBasicDTO
  cache: RefObject<Map<number, GetReportRowDTO[]>>
  onRead(report: GetReportBasicDTO): void
}

const ReportDetail: FC<Props> = ({
  report,
  cache,
  onRead
}) => {
  const [rows, setRows] = useState<GetReportRowDTO[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [updating,  setUpdating] = useState<boolean>(false)
  const { onError } = useError()

  const renderStatusMessage = (): ReactNode => {
    switch (report.status) {
      // Ritardo
      case 3:
        return <Message icon="clock" theme="warning" title="Il paziente è in ritardo con la risposta" />
      // Nullo
      case 4:
        return <Message icon="calendar-off" theme="warning" title="Il paziente non ha risposto in tempo, questa iterazione è stata annullata" />
      // Inviato
      case 5:
        return <Message icon="send" title="Il questionario è stato inviato" />
      // Invio programmato
      case 6:
        return <Message icon="mail-forward" title="Il questionario deve ancora essere inviato" />
      // Annullato
      case 7:
        return <Message icon="message-circle-off" title="Invio annullato perché il monitoraggio è concluso" />
      default:
        return null
    }
  }

  useEffect(() => {
    if (report.messageNotRead && rows?.length) {
      const textAnswer = rows.find((row) => row.textAnswer)

      if (textAnswer) {
        void (async(): Promise<void> => {
          try {
            const { data: { payload } } = await api.reports.markAsRead([report.id])
            onRead(payload.reports[0])
          } catch {}
        })()
      }
    }
  }, [rows])

  useEffect(() => {
    // Regolare, Urgente
    if ([1, 2].includes(report.status!)) {
      if (!cache.current?.has(report.id)) {
        void (async(): Promise<void> => {
          if (!loading) {
            setUpdating(true)
          }

          try {
            const { data: { payload } } = await api.reports.getSingle(report.id)

            cache.current?.set(report.id, payload.reportRows)
            setRows(payload.reportRows)
          } catch (err) {
            onError(err)
          }

          setLoading(false)
          setUpdating(false)
        })()
      } else {
        setRows(cache.current.get(report.id)!)
        setLoading(false)
        setUpdating(false)
      }
    } else {
      if (rows.length) {
        setRows([])
      }
    }
  }, [report])

  return (
    <>
      <ul>
        <li className="report-detail__info">
          <div className="report-detail__info-title">Data invio</div>
          <div className="report-detail__info-value">{report.date && report.hour ? formatDateHour(`${report.date} ${report.hour}`) : "-"}</div>
        </li>

        <li className="report-detail__info">
          <div className="report-detail__info-title">Data ricezione</div>
          <div className="report-detail__info-value">{report.dateReceived ? formatDateHour(report.dateReceived) : "-"}</div>
        </li>

        <li className="report-detail__info">
          <div className="report-detail__info-title">Stato</div>
          <div className="report-detail__info-value"></div>
          <Text size="sm" className="font-semibold" theme={reportStatusTypes[report.status as ReportStatus]}>{reportStatusLabels[report.status as ReportStatus]}</Text>
        </li>
      </ul>

      {loading
        ?
        <Row justify="center">
          <Spinner />
        </Row>
        : rows.length
          ?
          <ul className={classNames("mt-4 grid grid-cols-12 gap-5", updating && "disabled")}>
            {rows.map((ans, i) => (
              <Col
                key={ans.id}
                as="li"
                lg={6}
                className={classNames(
                  "border-b border-gray-300",
                  (i === rows.length - 1 || (rows.length % 2 === 0 && i === rows.length - 2)) && "border-b-0"
                )}
              >
                <ReportAnswer key={ans.id} data={ans} />
              </Col>
            ))}
          </ul>
          : <div className="reports-modal__message mt-4">{renderStatusMessage()}</div>
      }
    </>
  )
}

export default ReportDetail
