import classNames from "classnames"
import { FC, HTMLProps } from "react"

interface Props extends HTMLProps<HTMLLabelElement> {
}

const Label: FC<Props> = ({
  children,
  className,
  ...props
}) => {
  return (
    <label className={classNames("label", className)} {...props}>{children}</label>
  )
}

export default Label
