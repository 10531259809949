import Message from "$components/Message"
import useData from "$hooks/useData"
import { GetForecastDTO, ProtocolDetails } from "$types/api"
import { prefixNumber } from "$util/format"
import classNames from "classnames"
import { FC, useMemo, useRef } from "react"
import { Link } from "react-router-dom"
import { Bar, BarChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, XAxisProps } from "recharts"
import Button from "../Button"

const defaultColors = [
  "#73ACF9", // blue
  "#FDA90D", // yellow
  "#EF6457", // red
  "#62BC77", // green
  "#702963" // purple
]
const generateColor = (index: number, hexVariation = 20): string => {
  return "#" + defaultColors[index % defaultColors.length].substring(1).match(/.{2}/g)!
    .map(hex => Math.min(255, Math.max(0, parseInt(hex, 16) + hexVariation)).toString(16))
    .join("")
}

type Props = {
  className?: string
  data: GetForecastDTO["forecast"]
}

// const renderLabel: BarProps["label"] = ({ x, y, width, height, value, ...props }) => {
//   return (
//     <text
//       className="text-sm font-semibold"
//       x={x + width / 2}
//       y={y + height / 2}
//       fill={value > 0 ? "#fff" : "#666"}
//       textAnchor="middle"
//       dy={value > 0 ? 0 : -6}
//     >{value}</text>
//   )
// }
const renderTick: XAxisProps["tick"] = ({ x, y, ...props }) => {
  const { payload } = props
  const splitted = payload?.value?.split(" - ")
  const weekday = splitted[0]
  const date = splitted[1]

  return (
    <text
      className="text-xs md:text-sm"
      x={x}
      y={y + 10}
      fill="currentColor"
      textAnchor="middle"
    >
      <tspan x={x} textAnchor="middle">{weekday}</tspan>
      <tspan x={x} textAnchor="middle" dy={16}>{date}</tspan>
    </text>
  )
}

type CustomTooltipProps = TooltipProps<number, string> & {
  protocols: Map<number, ProtocolDetails>
}
const CustomTooltip: FC<CustomTooltipProps> = ({
  active,
  payload,
  protocols
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white bg-opacity-90 shadow p-2">
        {payload.map((data) => (
          <div key={data.dataKey} className="flex items-center gap-x-2">
            <div className="w-2 h-2 rounded-full" style={{ backgroundColor: data.color }} />

            <div className="text-sm">
              <span className="font-semibold">{data.value}</span> {protocols.get(+(data.dataKey || 0))?.name}</div>
          </div>
        ))}
      </div>
    )
  }

  return null
}

const weekdays = {
  0: "Dom",
  1: "Lun",
  2: "Mar",
  3: "Mer",
  4: "Gio",
  5: "Ven",
  6: "Sab"
}

type ChartData =  {
  label: string
  [key: number]: {
    [protocolId: string]: number
  }
}

const ForecastChart: FC<Props> = ({
  className,
  data: propsData
}) => {
  const { protocols } = useData()

  // TODO: improve
  const colors = useRef<Map<number, string>>(((): Map<number, string> => {
    const variations = [30, 60, 90, -30, -60, -90]
    const map = new Map<number, string>()
    for (let i = 0; i < protocols.data.length; i++) {
      map.set(protocols.data[i].id, i / (defaultColors.length - 1) <= 1 ? defaultColors[i] : generateColor(i, variations[Math.floor(i / variations.length)]))
    }
    return map
  })()).current

  const data: ChartData[] = useMemo(() => {
    if (propsData) {
      return Object.entries(propsData).sort((a, b) => a[0] > b[0] ? 1 : -1).map(([key, value]) => {
        const [year, month, day] = key.split("-").map(v => +v)
        const date = new Date(year, month - 1, day)

        return {
          label: `${weekdays[date.getDay() as keyof typeof weekdays]} - ${prefixNumber(day)} ${date.toLocaleDateString("it", { month: "short" })}`,
          ...value
        }
      })
    }
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth()
    const day = today.getDate()
    return Array.from({ length: 7 }, (_, i) => {
      const date: Date = new Date(year, month, day + i)
      return {
        label: `${weekdays[date.getDay() as keyof typeof weekdays]} - ${prefixNumber(date.getDate())} ${date.toLocaleDateString("it", { month: "short" })}`,
        value: {}
      }
    })
  }, [propsData])
  const ids = useMemo(() => {
    return Object.keys(data.reduce((obj, day) => {
      Object.keys(day).forEach(key => {
        if (key !== "label") {
          obj[key as unknown as number] = null // eslint-disable-line @typescript-eslint/ban-types
        }
      })
      return obj
    }, {} as { [key: number]: null }))
  }, [data])
  const valid = useMemo(() => {
    return !!propsData && Object.values(propsData).some((obj) => Object.keys(obj).length > 0)
  }, [data])

  return (
    <div className={classNames("max-w-[600px] mx-auto relative", className)}>
      <div className={classNames(!valid && "hidden md:block")}>
        <ResponsiveContainer aspect={2} debounce={200}>
          <BarChart data={data}>
            <XAxis
              dataKey={"label"}
              tick={renderTick}
              interval={0}
              tickLine={false}
              tickMargin={6}
              height={40}
              axisLine={{ stroke: "#ddd" }}
              allowDataOverflow
            />

            <Tooltip content={<CustomTooltip protocols={protocols.map} />} />

            {ids.map((id) => (
              <Bar
                key={id}
                barSize={30}
                dataKey={id}
                stackId="0"
                fill={colors.get(+id)}
                animationDuration={1000}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>

      {!valid &&
        <div className="w-full h-full lg:absolute top-0 left-0 backdrop-blur-sm flex justify-center items-center">
          <Message title={<>Nessun monitoraggio in attesa di risposta<br/>nei prossimi 7 giorni</>}>
            <div className="flex justify-center">
              <Link to="/monitorings">
                <Button>Monitoraggi</Button>
              </Link>
            </div>
          </Message>
        </div>
      }
    </div>
  )
}

export default ForecastChart
