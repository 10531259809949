import { GetReportBasicDTO } from "$types/api"
import { FC } from "react"
import Icon from "./Icon"
import Row from "./Row"

type Props = {
  report: GetReportBasicDTO
}

const ReportTableMessage: FC<Props> = ({
  report
}) => {
  return (
    report.hasMessage
      ?
      <Row className="dot-menu" justify="center">
        <Icon name="mail" />

        {report.messageNotRead &&
          <div
            className="w-2 h-2 rounded-full bg-red absolute top-0 right-0"
          />
        }
      </Row>
      : null
  )
}

export default ReportTableMessage
