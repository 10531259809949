import { FC, useEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Button from "../components/Button"
import Loader from "../components/Loader"
import MonitoringContent from "../components/MonitoringContent"
import Row from "../components/Row"
import Spinner from "../components/Spinner"
import Text from "../components/Text"
import Title from "../components/Title"
import useError from "../hooks/useError"
import { GetMonitoringDTO, GetPatientDTO } from "../types/api"
import api from "../util/api"

type Params = {
  id?: string
}

const Monitoring: FC = () => {
  const { id } = useParams<Params>()
  const { onError } = useError()

  // monitoring
  const monitoringId = useRef<number | null>(+id!).current
  const [monitoringLoading, setMonitoringLoading] = useState<boolean>(!!monitoringId)
  const [monitoringData, setMonitoringData] = useState<GetMonitoringDTO | null>(null)

  // patient
  const [patientLoading, setPatientLoading] = useState<boolean>(!!monitoringId)
  const [patientData, setPatientData] = useState<GetPatientDTO | null>(null)

  const getPatient = async(id: number): Promise<void> => {
    try {
      const res = await api.patients.getSingle(id)

      setPatientData(res.data.payload)
    } catch (err) {
      onError(err)
    } finally {
      setPatientLoading(false)
    }
  }

  const getMonitoring = async(): Promise<void> => {
    if (monitoringId) {
      try {
        const res = await api.monitorings.getSingle(monitoringId)

        setMonitoringData(res.data.payload)

        await getPatient(res.data.payload.patient)
      } catch (err) {
        onError(err)
      } finally {
        setMonitoringLoading(false)
      }
    }
  }

  useEffect(() => {
    void getMonitoring()
  }, [])

  return (monitoringLoading
    ?
    <Row justify="center">
      <Spinner />
    </Row>
    :
    <>
      <div className="heading mb-10">
        <Title>{patientLoading
          ? <Loader />
          : patientData
            ? `${patientData.firstName} ${patientData.lastName}`
            : "Si è verificato un errore"
        }</Title>

        {!!monitoringId && !!patientData &&
          <Link to={`/patient/${patientData.id}`}><Button>Visualizza paziente</Button></Link>
        }
      </div>

      {monitoringData
        ? <MonitoringContent data={monitoringData} updateMonitoring={getMonitoring} />
        : <Text size="lg" className="font-semibold text-center">Si è verificato un errore</Text>
      }
    </>
  )
}

export default Monitoring
