import { FC } from "react"
import classNames from "classnames"
import SVG, { Props as SvgProps } from "react-inlinesvg"
import { IconName } from "../types/icon"

interface Props extends Omit<SvgProps, "src"> {
  name: IconName
  size?: number
}

const Icon: FC<Props> = ({ name, className, size = 20 }) => {
  return <SVG
    className={classNames("icon", `icon--${name}`, className)}
    src={`${process.env.PUBLIC_URL}/icons/${name}.svg`}
    cacheRequests
    width={size}
    height={size}
    loader={
      <div className="inline-block" style={{ width: size, height: size }} />
    }
  />
}

export default Icon
