import Button from "$components/Button"
import Message from "$components/Message"
import { FC, useState } from "react"
import { Link } from "react-router-dom"
import FileUploader from "./FileUploader"
import PreviewTable from "./PreviewTable"

const SectionUpload: FC = () => {
  const [success, setSuccess] = useState<boolean>(false)

  return (
    success
      ?
      <Message className="max-w-[600px] mx-auto p-8 border border-grey-light text-blue-dark rounded-2xl" title="Caricamento completato" icon="check" theme="info">
        <p className="mb-6 text-grey-dark">Tutti i monitoraggi indicati sono stati avviati con successo</p>

        <div className="flex justify-center gap-x-4">
          <div className="w-[180px]">
            <Button theme="blue-outline" className="w-full" onClick={(): void => setSuccess(false)}>Carica altro</Button>
          </div>

          <Link className="w-[180px]" to="/monitorings">
            <Button theme="blue" className="w-full">Monitoraggi</Button>
          </Link>
        </div>
      </Message>
      :
      <div>
        <FileUploader className="mb-8" />

        <PreviewTable onSuccess={(): void => setSuccess(true)} />
      </div>
  )
}

export default SectionUpload
