// eslint-disable-next-line @typescript-eslint/ban-types
type Obj = Record<string, unknown>

export function getObjDifference(base: Obj, edited: Obj, excludeKeys: string[] = []): Obj {
  return Object.keys(edited).reduce((obj, key) => {
    if (!excludeKeys.includes(key) && edited[key] !== base[key]) {
      obj[key] = edited[key]
    }
    return obj
  }, {} as Obj)
}
