import Button from "$components/Button"
import Input from "$components/Input"
import Row from "$components/Row"
import useAuth from "$hooks/useAuth"
import useError from "$hooks/useError"
import { PhoneConfirmationDTO } from "$types/api"
import api from "$util/api"
import classNames from "classnames"
import { FC, ReactElement, useState } from "react"
import { Controller, useForm } from "react-hook-form"

type Props = {
  className?: string
  phone: string
  onSuccess?(): void
  onCancel?(): void
  showSubmit?: boolean
}

const PhoneConfirmationForm: FC<Props> = ({
  className,
  phone,
  onCancel,
  onSuccess,
  showSubmit = true
}) => {
  const { setUser } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)

  const { control, formState, handleSubmit } = useForm<PhoneConfirmationDTO>()
  const { onError } = useError()

  const onSubmit = async({ token }: { token: string }): Promise<void> => {
    if (!loading) {
      setLoading(true)

      try {
        const { data: { payload } } = await api.account.phoneConfirmation({ token, phone })

        setUser(payload)

        if (onSuccess) {
          onSuccess()
        }
      } catch (err) {
        onError(err)
        setLoading(false)
      }
    }
  }

  return (
    <form
      id="phone-confirmation-form"
      className={classNames(loading && "disabled", className)}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <Controller
        name="token"
        control={control}
        rules={{
          required: "Il codice è obbligatorio"
        }}
        render={({ field: { onChange, value } }): ReactElement => {
          return (
            <Input
              value={value}
              onChange={onChange}
              error={formState.errors.token?.message}
              autoComplete="new-password"
            />
          )
        }}
      />

      {showSubmit &&
        <Row className="mt-8 gap-x-2" justify="center">
          {onCancel &&
            <Button theme="red-outline" onClick={onCancel}>Indietro</Button>
          }

          <Button type="submit">Conferma</Button>
        </Row>
      }
    </form>
  )
}

export default PhoneConfirmationForm
