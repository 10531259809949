import { FunctionComponent, SVGProps } from "react"
import * as Icons from "./paymentIcons"

const iconsMap = {
  amex: Icons.Amex,
  diners: Icons.Diners,
  discover: Icons.Discover,
  jcb: Icons.Jcb,
  mastercard: Icons.Mastercard,
  unionpay: Icons.Unionpay,
  visa: Icons.Visa
}

export type PaymentIconName = keyof typeof iconsMap

interface Props extends SVGProps<SVGSVGElement> {
  name: PaymentIconName
}

const PaymentIcon: FunctionComponent<Props> = ({ className, name, ...props }) => {
  const Component = iconsMap[name]

  return Component ? <Component className={className} {...props} /> : null
}

export default PaymentIcon
