import classNames from "classnames"
import { FC, ReactElement, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Box from "../components/Box"
import Button from "../components/Button"
import Checkbox from "../components/Checkbox"
import Col from "../components/Col"
import Icon from "../components/Icon"
import Input from "../components/Input"
import Row from "../components/Row"
import Text from "../components/Text"
import Title from "../components/Title"
import { phoneRegex } from "../constants/regex"
import useError from "../hooks/useError"
import { AddToMailingListWithDemoDTO } from "../types/api"
import api from "../util/api"

const DemoForm: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)

  const { control, formState, handleSubmit } = useForm<AddToMailingListWithDemoDTO & { privacy?: boolean }>()
  const { onError } = useError()

  const onSubmit = async(data: AddToMailingListWithDemoDTO & { privacy?: boolean }): Promise<void> => {
    if (!loading) {
      setLoading(true)

      try {
        delete data.privacy
        await api.account.mailingListDemo(data)

        setSubmitted(true)
      } catch (err) {
        onError(err)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <div className="login pv-xl">
      <Icon name="logo" className="mb-xl" />

      <Box className="login__box pv-l ph-xl">
        <Title className="mb-m" uppercase align="center">{submitted ? "Grazie!" : "Benvenuti"}</Title>

        {submitted
          ?
          <>
            <Text size="sm" className="mb-l text-center">
              A breve riceverà un SMS sul numero indicato e potrà provare il funzionamento di un monitoraggio
            </Text>
          </>
          :
          <>
            <Text size="sm" className="mb-l text-center">
              Compilando questo form potrà provare il funzionamento di un monitoraggio Tracymed dal punto di vista del Paziente. <br/><br/>Dopo aver completato il questionario vedrà anche il riassunto delle risposte dal punto di vista del Dottore.
            </Text>

            <form className={classNames(loading && "disabled")} onSubmit={handleSubmit(onSubmit)}>

              <Row  align="start">
                <Col md={6}>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Campo obbligatorio"
                      }
                    }}
                    defaultValue=""
                    render={({ field: { onChange, value } }): ReactElement => {
                      return (
                        <Input
                          label="Cognome *"
                          value={value}
                          onChange={onChange}
                          error={formState.errors.lastName?.message}
                          className="mb-m"
                          autoComplete="family-name"
                        />
                      )
                    }}
                  />
                </Col>

                <Col md={6}>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Campo obbligatorio"
                      }
                    }}
                    defaultValue=""
                    render={({ field: { onChange, value } }): ReactElement => {
                      return (
                        <Input
                          label="Nome *"
                          value={value}
                          onChange={onChange}
                          error={formState.errors.firstName?.message}
                          className="mb-m"
                          autoComplete="given-name"
                        />
                      )
                    }}
                  />
                </Col>
              </Row>

              <Row  align="start">
                <Col md={6}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Campo obbligatorio"
                      }
                    }}
                    defaultValue=""
                    render={({ field: { onChange, value } }): ReactElement => {
                      return (
                        <Input
                          label="Email *"
                          type="email"
                          name="email"
                          value={value}
                          onChange={onChange}
                          error={formState.errors.email?.message}
                          className="mb-m"
                        />
                      )
                    }}
                  />
                </Col>

                <Col md={6}>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: "Campo obbligatorio"
                      },
                      pattern: {
                        value: phoneRegex,
                        message: "Campo non valido, inserire solo numeri senza spazi"
                      }
                    }}
                    render={({ field: { onChange, value } }): ReactElement => {
                      return (
                        <Input
                          label="Telefono *"
                          type="tel"
                          name="phone"
                          value={value}
                          onChange={onChange}
                          error={formState.errors.email?.message}
                          className="mb-m"
                        />
                      )
                    }}
                  />
                </Col>
              </Row>

              <Controller
                name="privacy"
                control={control}
                defaultValue={false}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obbligatorio"
                  }
                }}
                render={({ field: { onChange, value } }): ReactElement => {
                  return (
                    <Checkbox
                      checked={!!value}
                      onChange={onChange}
                      label={
                        <Text size="sm" theme={formState.errors?.privacy ? "error" : "default"}>Accetto <Text size="sm" className="underline" theme={formState.errors?.privacy ? "error" : "info"} as="a" href="https://www.iubenda.com/termini-e-condizioni/85720515" target="_blank" rel="nofollow noreferrer">Termini e Condizioni</Text> e <Text size="sm" className="underline" theme={formState.errors?.privacy ? "error" : "info"} as="a" href="https://www.iubenda.com/privacy-policy/85720515" target="_blank" rel="nofollow noreferrer">Privacy Policy</Text> *</Text>
                      }
                    />
                  )
                }}
              />

              <Row justify="center" className="pt-l">
                <Button type="submit">Conferma</Button>
              </Row>
            </form>
          </>
        }
      </Box>

    </div>
  )
}

export default DemoForm
