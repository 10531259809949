import { removeCharAt } from "$util/string"
import { forwardRef, KeyboardEvent, useEffect, useImperativeHandle, useRef } from "react"
import Input, { InputProps } from "./Input"

const getInputValue = (numbers: string): string => {
  const trimmed = numbers.substring(0, 4)
  const hour = trimmed.slice(0, 2)
  const minutes = trimmed.slice(2, 4)

  return `${hour}${hour.length === 2 ? ":" : ""}${minutes || ""}`
}

type TimePickerRef = {
  getValue(): string
}

type Omitted = "type" | "inputRef"
interface Props extends Omit<InputProps, Omitted> {
  value?: string
  onChange(value: string): void
  fillPlaceholder?: boolean
}

const TimePicker = forwardRef<TimePickerRef, Props>(({
  value: propsValue,
  onChange: propsOnChange,
  fillPlaceholder,
  ...props
}, ref) => {
  const prevValue = useRef<string>(propsValue || "")
  const lastKey = useRef<string>()
  const cursorPosition = useRef<number>(0)
  const input = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => ({
    getValue: (): string => {
      return input.current?.value || ""
    }
  }))

  const onChange = (value: string): void => {
    const values = value.split(":")

    const numbers = values.join("")
    let result = ""
    if (/^\d{1,}$/.test(numbers)) {
      result = getInputValue(numbers)

      if (input.current) {
        input.current.value = result
        prevValue.current = result

        const pos = cursorPosition.current
        cursor: {
          if (lastKey.current === "Delete") {
            if (pos === 2) {
              result = getInputValue(removeCharAt(result, pos + 1).split(":").join("").substring(0, 4))
              input.current.value = result
              prevValue.current = result
              input.current.setSelectionRange(pos + 1, pos + 1)
            } else {
              input.current.setSelectionRange(pos, pos)
            }
            break cursor
          }

          if (lastKey.current === "Backspace") {
            if (pos === 3 || pos === 6) {
              result = getInputValue(removeCharAt(result, pos - 2).split(":").join("").substring(0, 4))
              input.current.value = result
              prevValue.current = result
              input.current.setSelectionRange(pos - 2, pos - 2)
            } else if (pos === 4) {
              input.current.setSelectionRange(pos - 2, pos - 2)
            } else {
              input.current.setSelectionRange(pos - 1, pos - 1)
            }
            break cursor
          }
          if (lastKey.current === "Backspace") {
            input.current.setSelectionRange(pos - 1, pos - 1)
            break cursor
          }

          if (lastKey.current === ":") {
            if (pos === 2) {
              input.current.setSelectionRange(pos + 1, pos + 1)
            } else {
              input.current.setSelectionRange(pos, pos)
            }
            break cursor
          }

          if (pos === 1 || pos === 2) {
            input.current.setSelectionRange(pos + 2, pos + 2)
          } else {
            input.current.setSelectionRange(pos + 1, pos + 1)
          }
        }
      }
    } else {
      if (input.current) {
        result = value ? prevValue.current : ""
        input.current.value = result
        prevValue.current = result
        if (result) {
          input.current.setSelectionRange(cursorPosition.current, cursorPosition.current)
        }
      }
    }
    propsOnChange(result)
  }

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    cursorPosition.current = (e.target as HTMLInputElement).selectionStart || 0
    lastKey.current = e.key

    if (fillPlaceholder && props.placeholder && !cursorPosition.current && e.key === "ArrowRight") {
      if (input.current) {
        input.current.value = props.placeholder
        prevValue.current = props.placeholder
        propsOnChange(props.placeholder)
      }
    }
  }

  useEffect(() => {
    if (input.current) {
      input.current.value = propsValue || ""
    }
  }, [input.current])

  return (
    <Input
      onChange={onChange}
      onKeyDown={onKeyDown}
      inputRef={input}
      {...props}
    />
  )
})

export default TimePicker
