import { FC, ReactNode, useRef } from "react"
import { CSSTransition as ReactCSSTransition } from "react-transition-group"
import { BaseTransitionProps } from "../../types/transition"

type Props = Omit<BaseTransitionProps, "in" | "nodeRef">

const CSSTransition: FC<Props> = ({
  show,
  enter = "",
  enterStart = "",
  enterEnd = "",
  leave = "",
  leaveStart = "",
  leaveEnd = "",
  appear,
  mountOnEnter,
  unmountOnExit,
  children,
  onEnter,
  onEntering,
  onEntered,
  onExit,
  onExiting,
  onExited,
  ...rest
}) => {
  const enterClasses = (enter as string).split(" ").filter((s: string) => s.length)
  const enterStartClasses = (enterStart as string).split(" ").filter((s: string) => s.length)
  const enterEndClasses = (enterEnd as string).split(" ").filter((s: string) => s.length)
  const leaveClasses = (leave as string).split(" ").filter((s: string) => s.length)
  const leaveStartClasses = (leaveStart as string).split(" ").filter((s: string) => s.length)
  const leaveEndClasses = (leaveEnd as string).split(" ").filter((s: string) => s.length)
  const removeFromDom = unmountOnExit

  function addClasses(node: HTMLElement | null, classes: string[]): void {
    if (node && classes.length) {
      node.classList.add(...classes)
    }
  }

  function removeClasses(node: HTMLElement | null, classes: string[]): void {
    if (node && classes.length) {
      node.classList.remove(...classes)
    }
  }

  const nodeRef = useRef<HTMLDivElement>(null)

  return (
    <ReactCSSTransition
      appear={appear}
      nodeRef={nodeRef}
      mountOnEnter={mountOnEnter as boolean}
      unmountOnExit={removeFromDom as boolean}
      in={show}
      addEndListener={(done: () => void): void => {
        nodeRef.current?.addEventListener("transitionend", done, false)
      }}
      onEnter={(e): void => {
        if (nodeRef.current && onEnter) {
          onEnter(nodeRef.current, e)
        }
        if (!removeFromDom && nodeRef.current) {
          nodeRef.current.style.display = ""
        }
        addClasses(nodeRef.current, [...enterClasses, ...enterStartClasses])
      }}
      onEntering={(e): void => {
        if (nodeRef.current && onEntering) {
          onEntering(nodeRef.current, e)
        }
        removeClasses(nodeRef.current, enterStartClasses)
        addClasses(nodeRef.current, enterEndClasses)
      }}
      onEntered={(e): void => {
        if (nodeRef.current && onEntered) {
          onEntered(nodeRef.current, e)
        }
        removeClasses(nodeRef.current, [...enterEndClasses, ...enterClasses])
      }}
      onExit={(): void => {
        addClasses(nodeRef.current, [...leaveClasses, ...leaveStartClasses])
        if (nodeRef.current && onExit) {
          onExit(nodeRef.current)
        }
      }}
      onExiting={(): void => {
        removeClasses(nodeRef.current, leaveStartClasses)
        addClasses(nodeRef.current, leaveEndClasses)
        if (nodeRef.current && onExiting) {
          onExiting(nodeRef.current)
        }
      }}
      onExited={(): void => {
        removeClasses(nodeRef.current, [...leaveEndClasses, ...leaveClasses])
        if (!removeFromDom && nodeRef.current) {
          nodeRef.current.style.display = "none"
        }
        if (nodeRef.current && onExited) {
          onExited(nodeRef.current)
        }
      }}
    >
      <div
        className={rest.className as string}
        style={!removeFromDom ? { display: "none" } : undefined}
        ref={nodeRef}
      >
        {children as ReactNode}
      </div>
    </ReactCSSTransition>
  )
}

export default CSSTransition
