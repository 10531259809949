import { FC, ReactNode } from "react"
import Row from "../Row"
import Text from "../Text"

type Props = {
  label: ReactNode
  value?: ReactNode
  editable?: boolean
  onEdit?(): void
}

const AccountRow: FC<Props> = ({
  label,
  value,
  editable,
  onEdit
}) => {
  return (
    <Row justify='between' className='account-row'>
      <Text size="sm" className="font-semibold">{label}</Text>

      <Row className="gap-x-1">
        {editable && !!onEdit &&
          <>
            <Text className='underline cursor-pointer' size="sm" theme='info' onClick={onEdit}>Modifica</Text>

            <Text size="sm">-</Text>
          </>
        }

        <Text size="sm">{value}</Text>
      </Row>
    </Row>
  )
}

export default AccountRow
