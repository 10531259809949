import SectionInfo from "$components/csv/SectionInfo"
import SectionUpload from "$components/csv/SectionUpload"
import Title from "$components/Title"
import { usePartialStore } from "$hooks/usePartialStore"
import { IconName } from "$types/icon"
import classNames from "classnames"
import { FC } from "react"
import { CSVSection, useCsvStore } from "stores/csv"

type Section = {
  id: CSVSection
  label: string
  icon?: IconName
}
const sections: Section[] = [
  {
    id: "upload",
    label: "Caricamento"
  },
  {
    id: "info",
    label: "Informazioni"
  }
]

const CSVLoad: FC = () => {
  const { activeSection, setActiveSection } = usePartialStore(useCsvStore, ["activeSection", "setActiveSection"])

  return (
    <div className="select-none">
      <Title className="mb-8">Caricamento CSV</Title>

      <div className="csv__tabs">
        {sections.map(({ id, label }) => (
          <div
            key={id}
            className={classNames("csv__tabs-item", activeSection === id && "active")}
            onClick={(): void => setActiveSection(id)}
          >
            {label}
          </div>
        ))}
      </div>

      <div className="mt-8">
        {activeSection === "upload" &&
          <SectionUpload />
        }

        {activeSection === "info" &&
          <SectionInfo />
        }
      </div>
    </div>
  )
}

export default CSVLoad
