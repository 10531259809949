import classNames from "classnames"
import { FC, HTMLProps } from "react"
import { ButtonSize, ButtonTheme } from "../types/components"
import Spinner from "./Spinner"

interface Props extends Omit<HTMLProps<HTMLButtonElement>, "size"> {
  size?: ButtonSize | "full"
  theme?: ButtonTheme
  type?: "button" | "submit" | "reset"
  disabled?: boolean
  loading?: boolean
}

const Button: FC<Props> = ({
  children,
  size = "md",
  theme = "blue",
  disabled = false,
  loading = false,
  className,
  type = "button",
  ...props
}: Props) => {
  return (
    <button
      className={classNames("button", `button--${theme}`, `button--${size}`, className)}
      disabled={loading || disabled}
      type={type}
      {...props}
    >
      {loading ? <Spinner className="mr-2" /> : <></>}
      {children}
    </button>
  )
}

export default Button
