import { ColorTheme } from "$types/components"
import { IconName } from "$types/icon"
import classNames from "classnames"
import { FC, ReactNode } from "react"
import Icon from "./Icon"

type Props = {
  className?: string
  children: ReactNode
  icon?: IconName
  iconSize?: number
  theme?: ColorTheme
}

const Alert: FC<Props> = ({
  children,
  className,
  icon,
  iconSize = 20,
  theme = "info"
}) => {
  return (
    <div className={classNames("alert", `alert--${theme}`, className)}>
      {!!icon &&
        <div className="alert__icon" style={{ minWidth: iconSize }}>
          <Icon name={icon} size={iconSize} />
        </div>
      }

      <div className="alert__content">
        {children}
      </div>
    </div>
  )
}

export default Alert
