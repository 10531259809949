import classNames from "classnames"
import { ReactElement, ReactNode } from "react"
import InfoNav from "../../components/InfoNav"
import MobileBanner from "../../components/MobileBanner"

type Props = {
  children: ReactNode
}

export default function InfoWrapper({ children }: Props): ReactElement {
  return (
    <>
      <div className="page">
        <InfoNav />

        <div className={classNames("info-page page__container page__container--info")}>
          <div className="info-page__content">
            {children}
          </div>
        </div>

        <MobileBanner />
      </div>
    </>
  )
}
