import { ReactElement, ReactNode } from "react"

type Props = {
  children: ReactNode
}

export default function PublicWrapper({ children }: Props): ReactElement {
  return (
    <>
      {children}
    </>
  )
}
