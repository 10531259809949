import { GetMonitoringDTO, MonitoringDetails } from "../types/api"
import api from "../util/api"
import useError from "./useError"
import Text from "../components/Text"
import useConfirmModal from "./useConfirmModal"
import { toast } from "react-toastify"

type Monitoring = GetMonitoringDTO | MonitoringDetails

type UseMonitoringReturnType<T extends Monitoring> = {
  requestEnd(data: T, onComplete?: (data: GetMonitoringDTO) => void): void
  endMonitoring(id: number, onComplete?: (data: GetMonitoringDTO) => void): void
  archiveMonitoring(id: number, onComplete?: (data: GetMonitoringDTO) => void): void
  restoreMonitoring(id: number, onComplete?: (data: GetMonitoringDTO) => void): void
  resolveWarning(id: number, onComplete?: (data: GetMonitoringDTO) => void): void
}

function useMonitoring<T extends Monitoring>(): UseMonitoringReturnType<T> {
  const { onError } = useError()
  const { openModal } = useConfirmModal()

  const requestEnd = (data: T, onComplete?: (data: GetMonitoringDTO) => void): void => {
    if (data) {
      openModal({
        description: data.status === 2
          ? <>Il monitoraggio necessita di un intervento, si desidera concluderlo ugualmente?<br/><Text as="span" size="sm" className="font-semibold">NON</Text> sarà possibile ripristinarlo in seguito.</>
          : <>Il monitoraggio non è stato completato, si desidera concluderlo ugualmente?<br/><Text as="span" size="sm" className="font-semibold">NON</Text> sarà possibile ripristinarlo in seguito.</>
        ,
        onConfirm: () => {
          void endMonitoring(data.id, data => {
            if (onComplete) {
              onComplete(data)
            }
          })
        }
      })
    }
  }

  const endMonitoring = async(id: number, onComplete?: (data: GetMonitoringDTO) => void): Promise<void> => {
    try {
      const res = await api.monitorings.edit(id, { active: false })

      if (onComplete) {
        onComplete(res.data.payload)
      }
      toast.success("Monitoraggio concluso con successo")
    } catch (err) {
      onError(err)
    }
  }

  const archiveMonitoring = async(id: number, onComplete?: (data: GetMonitoringDTO) => void): Promise<void> => {
    try {
      const res = await api.monitorings.edit(id, { archived: true })

      if (onComplete) {
        onComplete(res.data.payload)
      }
      toast.success("Monitoraggio archiviato con successo")
    } catch (err) {
      onError(err)
    }
  }

  const restoreMonitoring = async(id: number, onComplete?: (data: GetMonitoringDTO) => void): Promise<void> => {
    try {
      const res = await api.monitorings.edit(id, { archived: false })

      if (onComplete) {
        onComplete(res.data.payload)
      }
      toast.success("Operazione effettuata con successo")
    } catch (err) {
      onError(err)
    }
  }

  const resolveWarning = async(id: number, onComplete?: (data: GetMonitoringDTO) => void): Promise<void> => {
    try {
      const { data } = await api.monitorings.resolveWarning(id)

      if (onComplete) {
        onComplete(data.payload)
      }
      toast.success("Operazione effettuata con successo")
    } catch (err) {
      onError(err)
    }
  }

  return {
    requestEnd,
    endMonitoring,
    archiveMonitoring,
    restoreMonitoring,
    resolveWarning
  }
}

export default useMonitoring
