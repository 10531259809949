import { FC } from "react"
import usePagination from "../hooks/usePagination"
import classNames from "classnames"
import Icon from "./Icon"
import { IconName } from "../types/icon"

type Props = {
  current: number
  total: number
  onChange(page: number): void
}

const Pagination: FC<Props> = ({ current, total, onChange }) => {
  const { items } = usePagination({
    boundaryCount: 1,
    count: total,
    page: current,
    onChange
  })

  const icons: { [key: string]: IconName } = {
    previous: "chevron-left",
    next: "chevron-right",
    first: "chevron-left",
    last: "chevron-right"
  }

  return (
    <ul className="pagination">
      {items.map(({ page, type, selected, disabled, ...item }, index) =>
        <li
          className={classNames(
            "pagination__item", {
              active: selected,
              disabled: disabled || type === "end-ellipsis" || type === "start-ellipsis",
              round: type === "previous" || type === "next",
              "ml-1": index > 0 && total > 1
            }
          )}
          key={index}
          {...item}
        >
          {(type === "start-ellipsis" || type === "end-ellipsis")
            ? "..."
            : type === "page"
              ? page
              : <Icon name={icons[type]}/>
          }
        </li>
      )}
    </ul>
  )
}

export default Pagination
