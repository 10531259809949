import classNames from "classnames"
import { FC, ReactNode } from "react"
import Row from "./Row"

type Props = {
  checked: boolean
  onChange(value: string | number): void
  value: string | number
  className?: string
  name?: string
  label?: ReactNode
  error?: boolean
  disabled?: boolean
}

const Radio: FC<Props> = ({
  checked,
  onChange,
  value,
  className,
  name,
  label,
  error,
  disabled
}: Props) => {
  return (
    <Row as="label" className={classNames("radio", { "radio--error": error, "radio--disabled": disabled }, className)}>
      <input type="radio" name={name} checked={checked} onChange={(): void => onChange(value)} value={value} />

      <div className="radio__mark"></div>

      {!!label &&
        <span className="ml-2">{label}</span>
      }
    </Row>
  )
}

export default Radio
