import classNames from "classnames"
import { FC, HTMLProps } from "react"
import Spinner from "./Spinner"

const Loader: FC<HTMLProps<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  return (
    <div className={classNames("loader", className)} {...props}>
      <Spinner />
    </div>
  )
}

export default Loader
