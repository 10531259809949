import classNames from "classnames"
import { FC, ReactElement, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Button from "../components/Button"
import Card from "../components/Card"
import Col from "../components/Col"
import Icon from "../components/Icon"
import Input from "../components/Input"
import Row from "../components/Row"
import { passwordRegex } from "../constants/regex"
import useError from "../hooks/useError"
import { NewPasswordDTO } from "../types/api"
import api from "../util/api"

type Params = {
  token: string
}

const ResetPassword: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const { control, formState, getValues, handleSubmit } = useForm<NewPasswordDTO>()
  const { fieldErrors, onError } = useError()
  const navigate = useNavigate()
  const { token } = useParams<Params>()

  const onSubmit = async(data: NewPasswordDTO): Promise<void> => {
    setLoading(true)

    try {
      await api.account.resetPassword({ ...data, token })

      toast.success("Password impostata correttamente, potete effettuare l'accesso")

      navigate("/login")
    } catch (err) {
      onError(err)
    }

    setLoading(false)
  }

  return (
    <div className="container h-full grid grid-cols-12 items-center">
      <Col lg={4} className="lg:col-start-5">
        <h1 className="flex justify-center mb-4" aria-label="Tracymed">
          <Icon name="logo" size={50} />
        </h1>

        <Card>
          <div className="text-xl font-bold text-center mb-2">Imposta password</div>

          <form
            className={classNames(loading && "disabled")}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <Controller
              name="password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Campo obbligatorio"
                },
                validate: (value?: string): string | boolean => {
                  return passwordRegex.test(value as string) || "La password non rispetta le condizioni sotto indicate"
                }
              }}
              defaultValue=""
              render={({ field: { onChange, value } }): ReactElement => {
                return (
                  <Input
                    className="mb-2"
                    label="Password"
                    type="password"
                    name="password"
                    value={value}
                    onChange={onChange}
                    error={fieldErrors.password || formState.errors.password?.message}
                  />
                )
              }}
            />
            <Controller
              name="matchPassword"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Campo obbligatorio"
                },
                validate: (value): string | boolean => {
                  const password = getValues().password
                  return value === password || "La password non corrisponde a quella scelta"
                }
              }}
              defaultValue=""
              render={({ field: { onChange, value } }): ReactElement => {
                return (
                  <Input
                    className="mb-2"
                    label="Conferma password"
                    type="password"
                    name="password"
                    value={value}
                    onChange={onChange}
                    error={fieldErrors.matchPassword || formState.errors.matchPassword?.message}
                  />
                )
              }}
            />

            <div className="mb-4 text-xs">La password deve contenere almeno 8 caratteri, di cui almeno una lettera minuscola, una lettera maiuscola e un numero.</div>

            <Row justify="center">
              <Button type="submit">Invia</Button>
            </Row>
          </form>
        </Card>
      </Col>
    </div>
  )
}

export default ResetPassword
