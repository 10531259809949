import classNames from "classnames"
import React, { FC } from "react"

type Props = {
  className?: string
  color?: "black" | "white" | "blue" | "red"
  size?: number
}

const Spinner: FC<Props> = ({
  className,
  color = "blue",
  size = 50
}) => (
  <div className={classNames("spinner", { [`spinner--${color}`]: true }, className)}>
    <svg viewBox="0 0 50 50" width={size} height={size}><circle cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle></svg>
  </div>
)

export default Spinner
