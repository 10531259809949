import { FC, useEffect, useRef } from "react"
import Row from "../Row"

type Props = {
  onSelect(text: string): void
  value?: string
  required?: boolean
}

const TextAnswer: FC<Props> = ({
  onSelect,
  value,
  required
}) => {
  const ref = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (value === undefined) {
      if (!required) {
        onSelect("")
      }
    } else if (ref.current) {
      ref.current.value = value
    }
  }, [])

  return (
    <Row justify="center">
      <textarea
        className="check__textarea"
        onBlur={(): void => {
          if (ref.current) {
            if (!required) {
              onSelect(ref.current.value)
            } else {
              if (ref.current.value) {
                onSelect(ref.current.value)
              }
            }
          }
        }}
        ref={ref}
      />
    </Row>
  )
}

export default TextAnswer
