import classNames from "classnames"
import { FC, ReactElement, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import useError from "../hooks/useError"
import { UserReportDTO } from "../types/api"
import api from "../util/api"
import Button from "./Button"
import FeedbackSection from "./FeedbackSection"
import Radio from "./Radio"
import Row from "./Row"
import Textarea from "./Textarea"

type SectionType = "feedback" | "report"
type Section = {
  id: SectionType
  label: string
}
const sections: Section[] = [
  { id: "feedback", label: "Lasciare un feedback" },
  { id: "report", label: "Segnalare un problema" }
]

type Area = {
  value: string
  label: string
}
const areas: Area[] = [
  { value: "login", label: "Area login" },
  { value: "monitoraggio", label: "Area monitoraggio" },
  { value: "pazienti", label: "Area pazienti" },
  { value: "altro", label: "Altro" }
]

type Props = {
  onSuccess(): void
}

const FeedbackSteps: FC<Props> = ({
  onSuccess
}) => {
  const [active, setActive] = useState<number>(0)
  const [selectedSection, setSelectedSection] = useState<Section>()
  const [selectedArea, setSelectedArea] = useState<Area>()
  const [loading, setLoading] = useState<boolean>(false)
  const [type, setType] = useState<SectionType>()

  const { onError } = useError()
  const { control, formState, handleSubmit } = useForm<UserReportDTO>()

  const onSubmit = async(data: UserReportDTO): Promise<void> => {
    setLoading(true)

    try {
      await api.account.feedback({ isReport: type === "report", message: type === "report" ? `Report\nArea problematica: ${selectedArea?.label}\n${data.message}` : data.message })

      onSuccess()
    } catch (err) {
      onError(err)
      setLoading(false)
    }
  }

  return (
    <div className={classNames(loading && "disabled")}>
      <FeedbackSection
        title="1. Cosa desiderate fare?"
        className="mb-4"
        active={active === 0}
        setActive={(): void => setActive(0)}
        value={selectedSection?.label}
      >
        {sections.map(section => (
          <Radio
            key={section.id}
            className="mb-4"
            label={section.label}
            value={section.id}
            checked={type === section.id}
            onChange={(v): void => {
              setSelectedSection(section)
              setActive(1)
              setType(v as SectionType)
            }}
          />
        ))}
      </FeedbackSection>

      {selectedSection
        ? selectedSection.id === "feedback"
          ?
          <FeedbackSection
            title="2. Lasciate un messaggio"
            active={active === 1}
          >
            <Controller
              name="message"
              control={control}
              rules={{ required: "Campo obbligatorio" }}
              defaultValue=""
              render={({ field: { onChange, value } }): ReactElement => {
                return (
                  <Textarea
                    className="mb-4"
                    placeholder="Scrivete cosa avete apprezzato o cosa non vi è piaciuto della nostra piattaforma"
                    value={value}
                    onChange={onChange}
                    error={formState.errors.message?.message}
                  />
                )
              }}
            />

            <Row justify="end">
              <Button onClick={handleSubmit(onSubmit)}>Invia feedback</Button>
            </Row>
          </FeedbackSection>
          :
          <>
            <FeedbackSection
              title="2. In quale area del sito avete rilevato il problema?"
              className="mb-4"
              active={active === 1}
              setActive={(): void => setActive(1)}
              value={selectedArea?.label}
            >
              {areas.map(area => (
                <Radio
                  key={area.value}
                  className="mb-4"
                  label={area.label}
                  value={area.value}
                  checked={selectedArea?.value === area.value}
                  onChange={(): void => {
                    setActive(2)
                    setSelectedArea(area)
                  }}
                />
              ))}
            </FeedbackSection>

            <FeedbackSection
              title="3. Lasciate un messaggio"
              active={active === 2}
            >
              <Controller
                name="message"
                control={control}
                rules={{ required: "Campo obbligatorio" }}
                defaultValue=""
                render={({ field: { onChange, value } }): ReactElement => {
                  return (
                    <Textarea
                      className="mb-4"
                      placeholder="Scriveteci quali problemi avete rilevato e possibilmente le azioni che avete fatto prima che si verificassero"
                      value={value}
                      onChange={onChange}
                      error={formState.errors.message?.message}
                    />
                  )
                }}
              />

              <Row justify="end">
                <Button onClick={handleSubmit(onSubmit)}>Invia segnalazione</Button>
              </Row>
            </FeedbackSection>
          </>
        : null
      }
    </div>
  )
}

export default FeedbackSteps
