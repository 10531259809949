import Button from "$components/Button"
import Modal from "$components/Modal"
import ReportDetail from "$components/ReportDetail"
import Row from "$components/Row"
import { GetReportBasicDTO, GetReportRowDTO } from "$types/api"
import { FC, useEffect, useMemo, useRef, useState } from "react"

type Props = {
  reports: GetReportBasicDTO[]
  initialReport: number | null
  close(): void
  onRead(report: GetReportBasicDTO): void
}

const ReportsModal: FC<Props> = ({
  reports: propsReports,
  initialReport,
  close,
  onRead
}) => {
  const reports = useMemo<GetReportBasicDTO[]>(() => {
    return [...propsReports].reverse()
  }, [propsReports])
  const [current, setCurrent] = useState<number>(reports.length - 1 - (initialReport || 0))
  const detailsCache = useRef<Map<number, GetReportRowDTO[]>>(new Map())

  const goPrev = (): void => {
    setCurrent(Math.max(current - 1, 0))
  }
  const goNext = (): void => {
    setCurrent(Math.min(current + 1, reports.length - 1))
  }

  useEffect(() => {
    setCurrent(reports.length - 1 - (initialReport || 0))
  }, [initialReport])

  return (
    <Modal
      title={`Report ${current + 1}/${reports.length}`}
      footer={
        <Row justify="center" className="gap-x-2">
          <Button disabled={current === 0} onClick={goPrev}>Precedente</Button>

          <Button disabled={current === reports.length - 1} onClick={goNext}>Successivo</Button>
        </Row>
      }
      open={initialReport !== null}
      close={close}
      className="reports-modal"
      contentClassName="reports-modal__content"
    >
      <ReportDetail
        report={reports[current]}
        cache={detailsCache}
        onRead={onRead}
      />
    </Modal>
  )
}

export default ReportsModal
