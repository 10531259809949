import { confirmModal } from "$util/refs"
import { ConfirmModalOptions } from "../types/components"

type UseConfirmModal = {
  openModal(modalOptions: ConfirmModalOptions): void
}

const useConfirmModal = (): UseConfirmModal => {
  return {
    ...confirmModal.current!
  }
}

export default useConfirmModal
