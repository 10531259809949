import classNames from "classnames"
import { FC, ReactElement, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import useError from "../../hooks/useError"
import { GetBillingDataDTO, PaymentInfoDTO } from "../../types/api"
import api from "../../util/api"
import Button from "../Button"
import Col from "../Col"
import Input from "../Input"
import Row from "../Row"

const excludeKeys = [
  "verified",
  "verifiedAddress",
  "verifiedName"
]

type Props = {
  onSuccess(): void
  onCancel?(): void
  data?: GetBillingDataDTO
  buttonJustify?: "start" | "between" | "center" | "end" | "around"
  className?: string
}

const AccountBilling: FC<Props> = ({
  onSuccess,
  onCancel,
  data,
  buttonJustify = "center",
  className
}) => {
  const [loading, setLoading] = useState<boolean>(true)

  const { control, formState, handleSubmit, setError, setValue, clearErrors } = useForm<PaymentInfoDTO>()
  const { onError } = useError()

  const onSubmit = async(data: PaymentInfoDTO): Promise<void> => {
    if (data.pecAddress || data.sdiCode) {

      if (data.sdiCode === "") {
        delete data.sdiCode
      }
      if (data.pecAddress === "") {
        delete data.pecAddress
      }

      setLoading(true)

      try {
        await api.payments.updateBillingData({
          ...data,
          vatCode: `IT${data.vatCode}`
        })

        onSuccess()
      } catch (err) {
        onError(err)
        setLoading(false)
      }
    } else {
      setError("pecAddress", { message: "PEC o SDI richiesto", type: "validate" })
      setError("sdiCode", { message: "PEC o SDI richiesto", type: "validate" })
    }
  }

  const fillForm = (data?: GetBillingDataDTO): void => {
    if (data) {
      Object.keys(data).forEach(key => {
        if (data[key as keyof GetBillingDataDTO]) {
          if (!excludeKeys.includes(key)) {
            const value = key !== "vatCode"
              ? data[key as keyof GetBillingDataDTO]
              : data[key as keyof GetBillingDataDTO]?.replace(/it/ig, "")
            setValue(key as keyof PaymentInfoDTO, value)
          }
        }
      })
    }
  }

  useEffect(() => {
    if (data) {
      fillForm(data)
      setLoading(false)
    } else {
      void (async(): Promise<void> => {
        try {
          const res = await api.payments.getBillingData()

          const data = res.data.payload
          fillForm(data)
        } catch (err) {
          onError(err)
        }

        setLoading(false)
      })()
    }
  }, [])

  return (
    <form
      className={classNames(loading && "disabled", className)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="grid grid-cols-12 gap-4">
        <Col md="6">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: "Campo obbligatorio"
              }
            }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Nome azienda *"
                  name="company"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.name?.message}
                  autoComplete="organization"
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="vatCode"
            control={control}
            defaultValue=""
            rules={{
              required: "Campo obbligatorio",
              pattern: {
                value: /\d{11}/,
                message: "Il codice deve avere 11 cifre senza spazi"
              }
            }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Partita IVA *"
                  placeholder="00000000000"
                  name="vat"
                  prefix="IT"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.vatCode?.message}
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="sdiCode"
            control={control}
            defaultValue=""
            rules={{
              minLength: {
                value: 7,
                message: "Il codice deve avere 7 cifre senza spazi"
              },
              maxLength: {
                value: 7,
                message: "Il codice deve avere 7 cifre senza spazi"
              }
            }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Codice SDI"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.sdiCode?.message}
                  onFocus={(): void => {
                    if (formState.errors.pecAddress?.type === "validate") {
                      clearErrors("pecAddress")
                    }
                  }}
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="pecAddress"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="PEC"
                  type="email"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.pecAddress?.message}
                  onFocus={(): void => {
                    if (formState.errors.sdiCode?.type === "validate") {
                      clearErrors("sdiCode")
                    }
                  }}
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="line1"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: "Campo obbligatorio"
              }
            }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Indirizzo *"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.line1?.message}
                  autoComplete="address-line1"
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="line2"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Informazioni indirizzo"
                  placeholder="Es. piano, scala, interno"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.line2?.message}
                  autoComplete="address-line2"
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="city"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: "Campo obbligatorio"
              }
            }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Città *"
                  name="city"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.city?.message}
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="postCode"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: "Campo obbligatorio"
              },
              minLength: {
                value: 5,
                message: "Il codice deve avere 5 cifre"
              },
              maxLength: {
                value: 5,
                message: "Il codice deve avere 5 cifre"
              }
            }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="CAP *"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.postCode?.message}
                  name="postal-code"
                  autoComplete="postal-code"
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="country"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: "Campo obbligatorio"
              }
            }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Nazione *"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.country?.message}
                  name="country-name"
                  autoComplete="country-name"
                />
              )
            }}
          />
        </Col>

        <Col md="6">
          <Controller
            name="state"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: "Campo obbligatorio"
              }
            }}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <Input
                  label="Provincia *"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.state?.message}
                  name="state"
                  autoComplete="address-level1"
                />
              )
            }}
          />
        </Col>

        <Col className="mt-4">
          <Row justify={buttonJustify} className="gap-x-4">
            {!!onCancel &&
              <Button theme="red-outline" onClick={onCancel}>Annulla</Button>
            }

            <Button type="submit">Conferma</Button>
          </Row>
        </Col>
      </div>

    </form>
  )
}

export default AccountBilling
