import { FC, HTMLProps, useState } from "react"
import Col from "./Col"
import { useNavigate } from "react-router-dom"
import useError from "../hooks/useError"
import { GetPatientDTO } from "../types/api"
import { SetState } from "../types/components"
import api from "../util/api"
import CopyToClipboard from "./CopyToClipboard"
import DotMenu from "./DotMenu"
import PatientModal from "./modals/PatientModal"
import MonitoringPreview from "./MonitoringPreview"
import Row from "./Row"
import Text from "./Text"
import Title from "./Title"
import { formatDate } from "../util/format"
import useConfirmModal from "../hooks/useConfirmModal"
import { toast } from "react-toastify"
import Card from "./Card"
import PatientMonitorings from "./PatientMonitorings"

interface Props extends Omit<HTMLProps<HTMLDivElement>, "data"> {
  data: GetPatientDTO
  setData: SetState<GetPatientDTO | undefined>
}

const PatientContent: FC<Props> = ({
  data,
  setData,
  ...props
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { openModal } = useConfirmModal()

  const { onError } = useError()
  const navigate = useNavigate()

  const onDeleteClick = (): void => {
    openModal({
      title: "Elimina paziente",
      description: <>Si desidera procedere all'eliminazione di questo paziente?<br/>Tutti i dati associati saranno cancellati definitivamente.</>,
      confirmButtonTheme: "red",
      cancelButtonTheme: "blue-outline",
      onConfirm: () => deletePatient()
    })
  }

  const deletePatient = async(): Promise<void> => {
    try {
      await api.patients.delete(data.id)

      toast.success("Paziente eliminato con successo")

      navigate("/patients")
    } catch (err) {
      onError(err)
    }
  }

  return (
    <>
      <div className="patient" {...props}>
        <div className="grid grid-cols-12 gap-y-8 lg:gap-x-8 xl:gap-x-0">
          <Col lg="7" xl="7">
            <Card
              heading={
                <Row justify="between">
                  <div>Cartella paziente</div>

                  <DotMenu
                    options={[
                      { label: "Modifica", action: (): void => setModalOpen(true) },
                      { label: "Elimina", action: (): void => onDeleteClick() }
                    ]}
                  />
                </Row>
              }
            >
              <div className="title title--xs mb-4">Contatti</div>
              <div className="grid grid-cols-12 gap-y-2">
                {data.phone || data.email
                  ?
                  <>
                    {data.phone
                      ?
                      <Col md="12">
                        <Text className="font-semibold uppercase" size="sm">Telefono</Text>
                        <Row >
                          <Col md="6">
                            <CopyToClipboard label={data.phone} value={`${data.phone}`} textProps={{ size: "sm" }} />
                          </Col>
                        </Row>
                      </Col>
                      : null
                    }
                    {data.email
                      ?
                      <Col md="12">
                        <Text className="font-semibold uppercase" size="sm">Email</Text>
                        <Row >
                          <Col md="6">
                            <CopyToClipboard value={data.email} textProps={{ size: "sm" }} />
                          </Col>
                        </Row>
                      </Col>
                      : null
                    }
                  </>
                  :
                  <Col>
                    <Text size="sm">Nessun metodo di contatto</Text>
                  </Col>
                }
              </div>

              <div className="title title--xs my-4">Dati anagrafici</div>
              <div className="grid grid-cols-12 gap-y-4">
                <Col md="6">
                  <Text className="font-semibold uppercase" size="sm">Cognome</Text>
                  <Text size="sm">{data.lastName}</Text>
                </Col>
                <Col md="6">
                  <Text className="font-semibold uppercase" size="sm">Nome</Text>
                  <Text size="sm">{data.firstName}</Text>
                </Col>
                {data.birthDay
                  ?
                  <Col md="6">
                    <Text className="font-semibold uppercase" size="sm">Data di nascita</Text>
                    <Text size="sm">{formatDate(data.birthDay)}</Text>
                  </Col>
                  : null
                }
                {data.cf
                  ?
                  <Col md="6">
                    <Text className="font-semibold uppercase" size="sm">Codice fiscale</Text>
                    <Text size="sm" className="uppercase">{data.cf}</Text>
                  </Col>
                  : null
                }
              </div>

              {data.notes
                ?
                <div className="mt-8">
                  <Title className="mb-4" size="xs">Note</Title>
                  <Text size="sm">{data.notes}</Text>
                </div>
                : null
              }
            </Card>
          </Col>

          <Col lg="5" xl="4" className="xl:col-start-9">
            <MonitoringPreview monitoringId={data?.activeMonitoringId} patientId={data.id} />
          </Col>
        </div>

        <PatientMonitorings
          className="mt-16"
          patient={data}
        />
      </div>

      <PatientModal
        open={modalOpen}
        data={data}
        onSuccess={(data): void => {
          setData(data)
          setModalOpen(false)
        }}
        close={(): void => setModalOpen(false)}
      />
    </>
  )
}

export default PatientContent
