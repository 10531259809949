import classNames from "classnames"
import { ChangeEvent, FC, HTMLProps, ReactNode, RefObject } from "react"
import Label from "./Label"
import Row from "./Row"

export interface InputProps extends Omit<HTMLProps<HTMLInputElement>, "label" | "onChange" | "prefix"> {
  type?: "text" | "email" | "password" | "tel" | "date" | "number"
  onChange?(value: string, event?: ChangeEvent<HTMLInputElement>): void
  prefix?: ReactNode
  prefixProps?: HTMLProps<HTMLSpanElement>
  suffix?: ReactNode
  suffixProps?: HTMLProps<HTMLSpanElement>
  label?: ReactNode
  labelProps?: HTMLProps<HTMLLabelElement>
  error?: ReactNode | boolean
  errorProps?: HTMLProps<HTMLDivElement>
  inputRef?: RefObject<HTMLInputElement>
}

const Input: FC<InputProps> = ({
  className,
  type = "text",
  suffix,
  suffixProps,
  prefix,
  prefixProps,
  label,
  labelProps,
  error,
  errorProps,
  onChange,
  inputRef,
  ...props
}) => {
  return (
    <div className={className}>
      {label
        ? <Label {...labelProps}>{label}</Label>
        : null
      }

      <Row className="input__wrapper">
        <input
          className={classNames("input", {
            ["input--suffix"]: !!suffix,
            ["input--prefix"]: !!prefix,
            ["input--error"]: !!error
          })}
          type={type}
          onChange={(e): void => onChange?.(e.target.value, e)}
          ref={inputRef}
          {...props}
        />

        {prefix
          ?
          <span
            {...prefixProps}
            className={classNames("input__prefix", prefixProps?.className)}
          >{prefix}</span>
          : null
        }

        {suffix
          ?
          <span
            {...suffixProps}
            className={classNames("input__suffix", suffixProps?.className)}
          >{suffix}</span>
          : null
        }
      </Row>

      {error && typeof error !== "boolean"
        ?
        <div
          {...errorProps}
          className={classNames("input__error", errorProps?.className)}
        >
          {error}
        </div>
        : null
      }
    </div>
  )
}

export default Input
