import Col from "$components/Col"
import Message from "$components/Message"
import PasswordEditForm from "$components/form/PasswordEditForm"
import { FC } from "react"
import AccountTabs from "../components/account/AccountTabs"
import AccountPayment from "../components/accountComplete/AccountPayment"
import Title from "../components/Title"
import useAuth from "../hooks/useAuth"

const Account: FC = () => {
  const { user, company, updateUser } = useAuth()

  return (
    user && company
      ?
      <>
        {(company.status === 3 || company.status === 4 || company.status === 6) &&
          <div className='account-banner__container'>
            <div className="account-banner">
              {company.status === 3
                ? "Il rinnovo dell'abbonamento non è andato a buon fine, per continuare ad usare la piattaforma inserite un metodo di pagamento valido."
                : company.status === 4
                  ? "Per utilizzare la piattaforma si prega di scegliere un piano ed inserire un metodo di pagamento valido."
                  : "Il periodo di prova è scaduto ma il pagamento non è andato a buon fine, per continuare ad usare la piattaforma inserite un metodo di pagamento valido."
              }
            </div>
          </div>
        }
        <Title className="mb-8">Account</Title>

        {(company.status === 3 || company.status === 4 || company.status === 6)
          ?
          <div className='p-8'>
            <AccountPayment trialEnabled={false} />
          </div>
          : user.newPwdRequested
            ?
            <div className="grid grid-cols-4">
              <Col lg={2} className="lg:col-start-1">
                <div className="text-sm text-gray-500 mb-8">Vi è stata fornita una password temporanea, si prega di cambiarla per iniziare ad utilizzare la piattaforma</div>
                <PasswordEditForm
                  onSuccess={(): void => {
                    void updateUser()
                  }}
                />
              </Col>
            </div>
            : <AccountTabs />
        }
      </>
      : <Message theme="danger" title="Si è verificato un errore" />
  )
}

export default Account
