import classNames from "classnames"
import { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  className?: string
}

const SidebarLabel: FC<Props> = ({
  children,
  className
}) => {
  return (
    <h3 className={classNames("sidebar__label", className)}>
      <span className="sidebar__label-dots" aria-hidden="true">•••</span>

      <span className="sidebar__label-text">{children}</span>
    </h3>
  )
}

export default SidebarLabel
