import EmailForm from "$components/form/EmailForm"
import Modal from "$components/Modal"
import { FC, useRef } from "react"
import { GetPatientDTO } from "../../types/api"
import Button from "../Button"
import Row from "../Row"

type Data = {
  email: string
}

type Props = {
  id: number
  data?: Data
  onSuccess(data: GetPatientDTO): void
  close(): void
  open: boolean
}

const EmailModal: FC<Props> = ({
  id,
  data,
  onSuccess,
  close,
  open
}) => {
  const title = useRef<string>(data ? "Modifica email" : "Aggiungi email").current

  return (
    <Modal
      title={title}
      footer={
        <Row justify="end" className="gap-x-2">
          <Button theme="red-outline" onClick={close}>Annulla</Button>

          <Button type="submit" form="email-form">{data ? "Modifica" : "Aggiungi"}</Button>
        </Row>
      }
      open={open}
      close={close}
      className="email-modal"
    >
      <EmailForm
        id={id}
        data={data}
        onSuccess={onSuccess}
      />
    </Modal>
  )
}

export default EmailModal
