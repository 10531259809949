import Button from "$components/Button"
import Title from "$components/Title"
import { FC } from "react"
import FieldsTable from "./FieldsTable"
import ProtocolsTable from "./ProtocolsTable"

const SectionInfo: FC = () => {
  return (
    <>
      <div className="max-w-[900px] mb-4 text-sm">
        In questa schermata è possibile caricare un file CSV per avviare in maniera automatizzata dei monitoraggi.<br/>
        Scorrendo la pagina è possibile trovare tutte le informazioni per compilare correttamente tutti i dati.<br/>
        Una volta caricato il CSV verrà visualizzata un'anteprima che segnalerà eventuali errori, vi permetterà di controllare la correttezza dei dati inseriti e di modificarli o rimuoverli.<br/>
        È disponibile un template già pronto, per scaricarlo cliccate sul bottone qua sotto.<br/>
        Se utilizzate software come Excel o Numbers assicuratevi di esportare in formato CSV e che il separatore sia il punto e virgola.
      </div>

      <div className="mb-8">
        <a href={process.env.PUBLIC_URL + "/data/template.csv"} download="tracymed-template.csv">
          <Button>Scarica template</Button>
        </a>
      </div>

      <Title size="s" className="mb-4">Campi CSV</Title>

      <div className="text-sm mb-4">
        La seguente tabella mostra quali sono i campi disponibili, in che formato devono essere compilati e se sono obbligatori o facoltativi.
      </div>

      <FieldsTable className="mb-8" />

      <Title size="s" className="mb-4">Codici protocolli</Title>

      <div className="text-sm mb-4">
        La seguente tabella mostra quali sono i protocolli disponibili e i relativi codici da inserire nel CSV.
      </div>

      <ProtocolsTable />
    </>
  )
}

export default SectionInfo
