import classNames from "classnames"
import { FC } from "react"
import { SetState } from "../types/components"
import Checkbox from "./Checkbox"
import PatientsSearch from "./PatientsSearch"
import Row from "./Row"
import Title from "./Title"

type Props = {
  className?: string
  activeMonitoring: boolean
  setActiveMonitoring: SetState<boolean>
  search: string
  setSearch: SetState<string>
  searching: boolean
}

const PatientsFilters: FC<Props> = ({
  className,
  activeMonitoring,
  setActiveMonitoring,
  search,
  setSearch,
  searching
}) => {
  return (
    <Row className={classNames("filters", className)} justify="between">
      <div className={classNames(
        "flex flex-wrap items-center gap-x-4 gap-y-2",
        searching && "opacity-50 pointer-events-none"
      )}>
        <Title className="w-full md:w-auto" size="xs">Filtra per</Title>

        <Checkbox
          className="my-2 md:my-0"
          label="Monitoraggio attivo"
          checked={activeMonitoring}
          onChange={(value): void => setActiveMonitoring(value)}
        />
      </div>

      <PatientsSearch value={search} setValue={setSearch} />
    </Row>
  )
}

export default PatientsFilters
