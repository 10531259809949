import Button from "$components/Button"
import Input from "$components/Input"
import Row from "$components/Row"
import { defaultPhonePrefix } from "$constants/data"
import { phoneRegex } from "$constants/regex"
import useAuth from "$hooks/useAuth"
import useData from "$hooks/useData"
import useError from "$hooks/useError"
import { PhoneNumberDTO } from "$types/api"
import api from "$util/api"
import classNames from "classnames"
import { FC, ReactElement, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"

type Props = {
  className?: string
  onSuccess(phone: string): void
  phone?: string
  showSubmit?: boolean
}

const PhoneVerificationForm: FC<Props> = ({
  className,
  onSuccess,
  phone,
  showSubmit = true
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  const { user } = useAuth()
  const { phonePrefixes } = useData()
  const { control, formState, handleSubmit, setValue } = useForm<PhoneNumberDTO>({ defaultValues: {
    phoneNumber: "",
    phonePrefix: phonePrefixes.options[0]?.value || defaultPhonePrefix
  } })
  const { onError } = useError()

  const onSubmit = async(data: PhoneNumberDTO): Promise<void> => {
    if (!loading) {
      setLoading(true)

      try {
        await api.account.phone(data)

        onSuccess(data.phoneNumber)
      } catch (err) {
        onError(err)
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (phone) {
      setValue("phoneNumber", phone)
    }
  }, [])

  return (
    <form
      id="phone-verification-form"
      className={classNames(loading && "disabled", className)}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <Controller
        name="phoneNumber"
        control={control}
        rules={{
          required: "Campo obbligatorio",
          pattern: {
            value: phoneRegex,
            message: "Campo non valido, inserire solo numeri senza spazi e prefisso"
          },
          validate: (value: string): string | boolean => {
            return value === user?.phone
              ? "Il numero di telefono non può essere lo stesso"
              : true
          }
        }}
        defaultValue=""
        render={({ field: { onChange, value } }): ReactElement => {
          return (
            <Input
              type="tel"
              value={value}
              onChange={onChange}
              error={formState.errors.phoneNumber?.message}
              autoComplete="new-password"
            />
          )
        }}
      />

      {showSubmit &&
        <Row justify="center" className="mt-8">
          <Button type="submit">Conferma</Button>
        </Row>
      }
    </form>
  )
}

export default PhoneVerificationForm
