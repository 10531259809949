import classNames from "classnames"
import { FC, ReactNode } from "react"
import Row from "./Row"
import Text from "./Text"

type Props = {
  className?: string
  checked: boolean
  onChange(checked: boolean, value?: string): void
  name?: string
  value?: string
  label?: ReactNode
}

const Checkbox: FC<Props> = ({
  checked,
  onChange,
  name,
  label,
  value,
  className
}: Props) => {
  return (
    <Row className={classNames("checkbox", className)} onClick={(): void => onChange(!checked, value)}>
      <input type="checkbox" name={name} value={value} checked={checked} readOnly />

      <div className="checkbox__mark"></div>

      {!!label &&
        (typeof label === "string"
          ? <Text className="ml-2" size="sm">{label}</Text>
          : <div className="ml-2">{label}</div>
        )
      }
    </Row>
  )
}

export default Checkbox
