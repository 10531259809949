import classNames from "classnames"
import { FC, ReactNode } from "react"

type Row = {
  name: string
  description: ReactNode
  format?: ReactNode
  required?: boolean
}
const rows: Row[] = [
  {
    name: "NAME",
    description: "Nome del paziente",
    required: true
  },
  {
    name: "SURNAME",
    description: "Cognome del paziente",
    required: true
  },
  {
    name: "PHONE_NUMBER",
    description: "Numero di telefono del paziente",
    format: "Non deve contenere spazi né il prefisso",
    required: true
  },
  {
    name: "PROTOCOL",
    description: "Protocollo per il quale seguire il monitoraggio",
    format: "È un codice numerico. Consultare la tabella sottostante",
    required: true
  },
  {
    name: "PHONE_PREFIX",
    description: "Prefisso telefonico del paziente",
    format: <><b>+39</b> oppure <b>39</b></>
  },
  {
    name: "BIRTHDAY",
    description: "Data di nascita del paziente",
    format: <>GG/MM/AAAA, per esempio <b>20/05/2022</b></>
  },
  {
    name: "TAX_CODE",
    description: "Codice fiscale"
  },
  {
    name: "EMAIL",
    description: "Email del paziente"
  },
  {
    name: "SEX",
    description: "Sesso del paziente",
    format: <><b>M</b> / <b>F</b> / <b>NS</b> (non specificato)</>
  },
  {
    name: "DOCTOR",
    description: "Nome del medico che ha effettuato l'intervento"
  },
  {
    name: "CONTACT_HOUR",
    description: "Orario di invio dei questionari",
    format: <>HH:MM in formato 24 ore, per esempio <b>14:30</b></>
  },
  {
    name: "FARMPLUS",
    description: "Indica la presenza di una prescrione farmacologica",
    format: <><b>SI</b> / <b>NO</b></>
  },
  {
    name: "SHORTMONITORING",
    description: "Limita la durata del monitoraggio ad una giornata",
    format: <><b>SI</b> / <b>NO</b></>
  }
]

type Props = {
  className?: string
}

const FieldsTable: FC<Props> = ({
  className
}) => {
  return (
    <div className={className}>
      <table className="csv-table">
        <thead>
          <tr>
            <th>Campo</th>
            <th>Descrizione</th>
            <th>Formato</th>
            <th>Tipologia</th>
          </tr>
        </thead>

        <tbody>
          {rows.map(({ name, description, format, required }, i) => (
            <tr key={i}>
              <td>
                <div className="font-semibold">{name}</div>
              </td>
              <td>{description}</td>
              <td>{format || "-"}</td>
              <td>
                <div className={classNames(required && "font-semibold")}>
                  {required ? "Obbligatorio" : "Facoltativo"}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default FieldsTable
