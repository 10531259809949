import classNames from "classnames"
import { FC, ReactElement, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import Col from "../Col"
import { Controller, useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import useData from "$hooks/useData"
import useError from "$hooks/useError"
import { GetPatientDTO, NewMonitoringDTO } from "$types/api"
import { SelectOption, SetState } from "$types/components"
import api from "$util/api"
import Button from "../Button"
import ContactMethod, { ContactMethodEmailData, ContactMethodPhoneData } from "../ContactMethod"
import CopyToClipboard from "../CopyToClipboard"
import Input from "../Input"
import EmailModal from "../modals/EmailModal"
import PatientModal from "../modals/PatientModal"
import PhoneModal from "../modals/PhoneModal"
import PatientSelect, { PatientSelectRef } from "../PatientSelect"
import Row from "../Row"
import Select from "../Select"
import Spinner from "../Spinner"
import Text from "../Text"
import Textarea from "../Textarea"
import TimePicker from "../TimePicker"
import { formatDate } from "$util/format"
import Checkbox from "../Checkbox"
import Info from "../Info"
import { hourRegex } from "$constants/regex"
import Message from "../Message"
import Card from "../Card"
import { validateHour } from "$util/validation"
import Icon from "$components/Icon"
import { defaultContactTime } from "$constants/monitorings"

type ModalState<Data> = {
  open: boolean
  data?: Data
}

type Props = {
  className?: string
  isPreselected: boolean
  patientData: GetPatientDTO | null
  setPatientData: SetState<GetPatientDTO | null>
  patientLoading: boolean
  setPatientLoading: SetState<boolean>
}

const MonitoringAddForm: FC<Props> = ({
  className,
  isPreselected,
  patientData,
  setPatientData,
  patientLoading,
  setPatientLoading
}) => {
  const [selectedPatientId, setSelectedPatientId] = useState<number | null>(null)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [patientModalOpen, setPatientModalOpen] = useState<boolean>(false)
  const [phoneModal, setPhoneModal] = useState<ModalState<ContactMethodPhoneData>>({ open: false })
  const [emailModal, setEmailModal] = useState<ModalState<ContactMethodEmailData>>({ open: false })

  const { contactMethods, protocols } = useData()
  const { clearErrors, fieldErrors, onError } = useError()
  const { control, formState, handleSubmit, setError } = useForm<NewMonitoringDTO>({ defaultValues: { contactTime: defaultContactTime } })
  const navigate = useNavigate()

  const patientSelectRef = useRef<PatientSelectRef>(null)

  const onSubmit = async(data: NewMonitoringDTO): Promise<void> => {
    if (patientData) {
      const method = parseInt(`${data.contactMethod}`)
      const field = contactMethods.data.find(m => m.id === method)

      if (field && patientData[field.field as keyof GetPatientDTO]) {
        setDisabled(true)

        if (Object.keys(fieldErrors).length) {
          clearErrors()
        }

        try {
          const res = await api.monitorings.add({ ...data, patient: patientData.id })

          navigate(`/monitoring/${res.data.payload.id}`)
        } catch (err) {
          onError(err)
          setDisabled(false)
        }
      } else {
        toast.error("Il paziente non possiede il metodo di contatto selezionato")
        setError("contactMethod", { message: "Inserire il metodo di contatto" })
      }
    } else {
      toast.error("Nessun paziente selezionato")
    }
  }

  const getPatient = async(id: number, onSuccess: (data: GetPatientDTO) => void): Promise<void> => {
    try {
      const res = await api.patients.getSingle(id)

      onSuccess(res.data.payload)
    } catch (err) {
      onError(err)
    } finally {
      setPatientLoading(false)
    }
  }

  useEffect(() => {
    if (selectedPatientId) {
      void getPatient(selectedPatientId, data => {
        setPatientData(data)
      })
    }
  }, [selectedPatientId])

  return (
    <>
      <div className="grid grid-cols-12 gap-y-8 xl:gap-x-10">
        <Col className="order-2 xl:order-none" xl={8}>
          <Card>
            <form
              className={classNames(disabled && "disabled", className)}
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <Controller
                name="patient"
                control={control}
                rules={{ required: "Campo obbligatorio" }}
                render={({ field: { onChange } }): ReactElement => {
                  return (
                    <PatientSelect
                      isDisabled={patientLoading}
                      isPreselected={isPreselected}
                      patientData={patientData}
                      onChange={(id): void => {
                        if (id) {
                          setSelectedPatientId(id)
                        } else {
                          setSelectedPatientId(null)
                          setPatientData(null)
                        }
                        onChange(id)
                      }}
                      onAddClick={(): void => setPatientModalOpen(true)}
                      error={fieldErrors.patient || formState.errors.patient?.message}
                      ref={patientSelectRef}
                    />
                  )
                }}
              />

              {!!patientData?.activeMonitoringId &&
                <div className="text-sm text-red flex items-center gap-1 mt-2">
                  <Icon name="alert-triangle" />
                  Il paziente selezionato è già in fase di monitoraggio
                </div>
              }

              <Controller
                name="title"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }): ReactElement => {
                  return (
                    <Input
                      className="mt-4"
                      label="Titolo"
                      placeholder="Inserire un titolo breve e descrittivo"
                      value={value}
                      onChange={onChange}
                      error={fieldErrors.title || formState.errors.title?.message}
                      onFocus={(): void => {
                        if (fieldErrors.title) {
                          clearErrors("title")
                        }
                      }}
                    />
                  )
                }}
              />

              <Controller
                name="protocol"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obbligatorio"
                  }
                }}
                render={({ field: { onChange } }): ReactElement => {
                  return (
                    <Select
                      className="mt-4"
                      label="Protocollo *"
                      options={protocols.options}
                      placeholder="Seleziona protocollo"
                      onChange={(option): void => {
                        onChange((option as SelectOption<number>).value)
                      }}
                      error={fieldErrors.protocol || formState.errors.protocol?.message}
                    />
                  )
                }}
              />

              <Text className="mt-4 font-semibold">Opzioni aggiuntive</Text>

              <Controller
                name="farmPlus"
                control={control}
                render={({ field: { value, onChange } }): ReactElement => {
                  return (
                    <>
                      <Row className="mt-4">
                        <Checkbox
                          checked={!!value}
                          onChange={onChange}
                          label="Med+"
                        />

                        <Info className="ml-2" text="Indica la presenza di una prescrizione farmacologica" />
                      </Row>
                    </>
                  )
                }}
              />

              <Controller
                name="shortMonitoring"
                control={control}
                render={({ field: { value, onChange } }): ReactElement => {
                  return (
                    <>
                      <Row className="mt-4">
                        <Checkbox
                          checked={!!value}
                          onChange={onChange}
                          label="Monitoraggio rapido"
                        />

                        <Info className="ml-2" text="Limita la durata del monitoraggio ad una singola giornata" />
                      </Row>
                    </>
                  )
                }}
              />

              <Row>
                <Controller
                  name="contactTime"
                  control={control}
                  rules={{
                    required: "Campo obbligatorio",
                    pattern: {
                      value: hourRegex,
                      message: "Formato errato, usare HH:MM"
                    },
                    validate: (value?: string): string | boolean => {
                      if (!value?.length) {
                        return true
                      }
                      if (value.length === 5) {
                        const result = validateHour(value)

                        return result === true || "Orario non valido"
                      }
                      return "Orario non valido"
                    }
                  }}
                  defaultValue=""
                  render={({ field: { onChange, value } }): ReactElement => {
                    return (
                      <TimePicker
                        className="mt-4"
                        label={
                          <Row>
                            Orario di invio questionario *

                            <Info className="ml-2" text="Si tratta dell'orario in cui verranno inviate le comunicazioni al paziente tramite il metodo di contatto scelto" />
                          </Row>
                        }
                        placeholder={defaultContactTime}
                        fillPlaceholder
                        value={value}
                        onChange={onChange}
                        error={fieldErrors.contactTime || formState.errors.contactTime?.message}
                      />
                    )
                  }}
                />
              </Row>

              <Controller
                name="contactMethod"
                control={control}
                rules={{ required: "Campo obbligatorio" }}
                render={({ field: { onChange, value } }): ReactElement => {
                  return (
                    <ContactMethod
                      className="mt-4"
                      patient={patientData}
                      setPatient={setPatientData}
                      value={value}
                      onChange={onChange}
                      openPhone={(data): void => setPhoneModal({ open: true, data })}
                      openEmail={(data): void => setEmailModal({ open: true, data })}
                      error={fieldErrors.contactMethod || formState.errors.contactMethod?.message}
                    />
                  )
                }}
              />

              <Controller
                name="notes"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }): ReactElement => {
                  return (
                    <Textarea
                      className="mt-4"
                      label={
                        <Row>
                          Note

                          <Info className="ml-2" text="Le note non saranno visualizzate dal paziente, ma servono come annotazioni interne allo studio" />
                        </Row>
                      }
                      placeholder="Scrivi delle note aggiuntive"
                      value={value}
                      onChange={onChange}
                    />
                  )
                }}
              />

              <Row className="mt-8" justify="end">
                <Button type="submit">Conferma</Button>
              </Row>
            </form>
          </Card>
        </Col>

        <Col xl={4}>
          <Card>
            {patientLoading
              ?
              <Row justify="center">
                <Spinner />
              </Row>
              :
              <>
                {patientData
                  ?
                  <>
                    <div className="title title--s">Dati paziente</div>

                    <div className="grid grid-cols-12 gap-y-4 mt-6">
                      <Col md="6">
                        <Text className="font-semibold uppercase" size="sm">Cognome</Text>
                        <Text size="sm">{patientData.lastName}</Text>
                      </Col>
                      <Col md="6">
                        <Text className="font-semibold uppercase" size="sm">Nome</Text>
                        <Text size="sm">{patientData.firstName}</Text>
                      </Col>
                      {patientData.birthDay
                        ?
                        <Col md="6">
                          <Text className="font-semibold uppercase" size="sm">Data di nascita</Text>
                          <Text size="sm">{formatDate(patientData.birthDay)}</Text>
                        </Col>
                        : null
                      }
                      {patientData.cf
                        ?
                        <Col md="6">
                          <Text className="font-semibold uppercase" size="sm">Codice fiscale</Text>
                          <Text className="uppercase" size="sm">{patientData.cf}</Text>
                        </Col>
                        : null
                      }
                      {patientData.phone
                        ?
                        <Col md="12">
                          <Text className="font-semibold uppercase" size="sm">Telefono</Text>
                          <CopyToClipboard value={`${patientData.phone}`} label={patientData.phone} textProps={{ size: "sm" }} />
                        </Col>
                        : null
                      }
                      {patientData.email
                        ?
                        <Col md="12">
                          <Text className="font-semibold uppercase" size="sm">Email</Text>
                          <CopyToClipboard value={patientData.email} textProps={{ size: "sm" }} />
                        </Col>
                        : null
                      }
                      {patientData.notes
                        ?
                        <Col md="12">
                          <Text className="font-semibold uppercase" size="sm">Note</Text>
                          <Text size="sm">{patientData.notes}</Text>
                        </Col>
                        : null
                      }

                      <Col>
                        <Row className="mt-2" justify="center">
                          <Link to={`/patient/${patientData.id}`}>
                            <Button>Visualizza</Button>
                          </Link>
                        </Row>
                      </Col>
                    </div>
                  </>
                  :
                  <Message icon="user-off" title="Nessun paziente selezionato" />
                }
              </>
            }
          </Card>
        </Col>
      </div>

      {!!patientData &&
        <PhoneModal
          open={phoneModal.open}
          id={patientData.id}
          data={phoneModal.data}
          close={(): void => setPhoneModal({ open: false })}
          onSuccess={(data): void => {
            toast.success(`Numero di telefono ${phoneModal.data ? "modificato" : "aggiunto"} con successo`)
            setPatientData(data)
            setPhoneModal({ open: false })
          }}
        />
      }

      {!!patientData &&
        <EmailModal
          id={patientData.id}
          open={emailModal.open}
          close={(): void => setEmailModal({ open: false })}
          onSuccess={(data): void => {
            toast.success(`Email ${emailModal.data ? "modificata" : "aggiunta"} con successo`)
            setPatientData(data)
            setEmailModal({ open: false })
          }}
        />
      }

      <PatientModal
        open={patientModalOpen}
        close={(): void => setPatientModalOpen(false)}
        onSuccess={(data): void => {
          setPatientData(data)
          patientSelectRef.current?.setSelected(data)
          setPatientModalOpen(false)
        }}
      />
    </>
  )
}

export default MonitoringAddForm
