import Loader from "$components/Loader"
import api from "$util/api"
import { createContext, ReactElement, SetStateAction, useEffect, useRef, useState } from "react"
import { GetCompanyDTO, GetUserDTO } from "../types/api"
import { ProviderProps } from "../types/components"

export type AuthContextType = {
  user?: GetUserDTO | null
  company?: GetCompanyDTO | null
  loading: boolean
  setUser(user: SetStateAction<GetUserDTO | null | undefined>): void
  setCompany(user: SetStateAction<GetCompanyDTO | null | undefined>): void
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export default function AuthProvider({ children }: ProviderProps): ReactElement {
  const [user, setUser] = useState<GetUserDTO | null>()
  const [company, setCompany] = useState<GetCompanyDTO | null>()
  const prevUser = useRef<GetUserDTO | null>()

  useEffect(() => {
    if (user !== undefined) {
      if (user && !prevUser.current) {
        void (async(): Promise<void> => {
          try {
            const { data: { payload: company } } = await api.account.getCompany()
            setCompany(company)
          } catch (err) {
            setCompany(null)
          }
        })()
      }

      if (!user) {
        setCompany(null)
      }

      prevUser.current = user
    }
  }, [user])

  useEffect(() => {
    void (async(): Promise<void> => {
      try {
        const { data: { payload: user } } = await api.account.getUser()

        setUser(user)
      } catch (err) {
        setUser(null)
      }
    })()
  }, [])

  return (
    <AuthContext.Provider value={{
      user,
      company,
      loading: user === undefined || company === undefined,
      setCompany,
      setUser
    }}>
      {user === undefined
        ? <Loader />
        : children
      }
    </AuthContext.Provider>
  )
}