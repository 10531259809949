import classNames from "classnames"
import { FC, ReactNode } from "react"
import Row from "./Row"
import Spinner from "./Spinner"

type Props = {
  className?: string
  children: ReactNode
  loading: boolean
  updating: boolean
}

const Listing: FC<Props> = ({
  className,
  children,
  loading,
  updating
}) => {
  return (
    <div className={classNames("listing", { "listing--loading": updating }, className)}>
      {loading
        ? <Row justify="center"><Spinner /></Row>
        : children
      }
    </div>
  )
}

export default Listing
