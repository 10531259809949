import { FC, useState } from "react"
import Icon from "./Icon"
import Input, { InputProps } from "./Input"

const InputPassword: FC<Omit<InputProps, "suffix" | "type">> = (props) => {
  const [show, setShow] = useState<boolean>(false)

  return (
    <Input
      type={show ? "text" : "password"}
      suffix={
        <div className="input__icon cursor-pointer" onClick={(): void => setShow(!show)}>
          <Icon name={show ? "eye-off" : "eye"} />
        </div>
      }
      {...props}
    />
  )
}

export default InputPassword
