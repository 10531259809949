import { FC } from "react"
import { Link } from "react-router-dom"
import Icon from "./Icon"

const InfoNav: FC = () => {
  return (
    <nav className="info-nav">
      <Link className="info-nav__logo" to="/">
        <Icon name="logo" />
      </Link>
    </nav>
  )
}

export default InfoNav
