import { ReportStatus } from "../constants/monitorings"

export const reportClickable = (status: ReportStatus): boolean => {
  return status === 1 || status === 2
}

export const getReportModalErrorMessage = (status: ReportStatus): string => {
  switch (status) {
    case 3:
      return "Il questionario non è stato completato in tempo"
    case 4:
    case 5:
      return "Il questionario è stato inviato ma non è ancora stato completato"
    case 6:
      return "Il questionario non è ancora stato inviato"
    default:
      return ""
  }
}
