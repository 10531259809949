import Button from "$components/Button"
import ImportModal from "$components/modals/ImportModal"
import Spinner from "$components/Spinner"
import useConfirmModal from "$hooks/useConfirmModal"
import useError from "$hooks/useError"
import { usePartialStore } from "$hooks/usePartialStore"
import { ImportMonitoringWrapper } from "$types/api"
import api from "$util/api"
import { FC, Fragment, useState } from "react"
import { toast } from "react-toastify"
import { useCsvStore } from "stores/csv"
import PreviewTableRow from "./PreviewTableRow"

type Props = {
  className?: string
  onSuccess(rows: ImportMonitoringWrapper[]): void
}

const PreviewTable: FC<Props> = ({
  className,
  onSuccess
}) => {
  const { rows, updateRow, deleteRow, reset } = usePartialStore(useCsvStore, ["rows", "updateRow", "deleteRow", "reset"])
  const { openModal } = useConfirmModal()
  const { onError } = useError()

  const [loading, setLoading] = useState<boolean>(false)
  const [edit, setEdit] = useState<{ row: ImportMonitoringWrapper, index: number } | null>(null)

  const startMonitorings = async(): Promise<void> => {
    if (rows.some(r => r.isError || (!!r.fieldErrors && Object.keys(r.fieldErrors).length > 0))) {
      toast.warning("È necessario correggere gli errori o eliminare le righe problematiche prima di procedere")
    } else if (!loading) {
      setLoading(true)

      try {
        const { data: { payload } } = await api.monitorings.sendCSVRows({ rows })
        onSuccess(payload.rows)
        reset()
      } catch (err) {
        onError(err)
      }

      setLoading(false)
    }
  }

  return (
    rows.length
      ?
      <div className={className}>
        <table className="csv__table">
          <thead>
            <tr>
              <th>Cognome</th>
              <th>Nome</th>
              <th>Telefono</th>
              <th>Protocollo</th>
              <th>Ora di invio</th>
              <th>Paziente</th>
              <th className="w-px"></th>
              <th className="w-px"></th>
              <th className="w-px"></th>
            </tr>
          </thead>

          <tbody>
            {rows.map((row, index) => (
              <Fragment key={`${row.surname}-${row.name}-${row.phoneNumber}-${row.protocol}-${index}`}>
                <PreviewTableRow
                  row={row}
                  editable={!loading}
                  onEditClick={(): void => {
                    setEdit({ row, index })
                  }}
                  onDeleteClick={(): void => {
                    openModal({
                      title: "Eliminazione riga",
                      description: "Siete sicuri di eliminare la riga? Per recuperarla dovrete caricare nuovamente il file",
                      onConfirm: () => {
                        deleteRow(index)
                      }
                    })
                  }}
                />
              </Fragment>
            ))}
          </tbody>
        </table>

        <div className="flex flex-col gap-y-2 items-center mt-8">
          <div className="w-60">
            <Button className="button--full" theme="red-outline" onClick={(): void => {
              openModal({
                title: "Eliminazione tabella",
                description: "Siete sicuri di eliminare la tabella? Per recuperarla dovrete caricare nuovamente il file",
                onConfirm: () => {
                  reset()
                }
              })
            }} disabled={loading}>
              Elimina tutti
            </Button>
          </div>

          <div className="w-60">
            <Button className="button--full" onClick={startMonitorings} disabled={loading}>
              {loading
                ? <Spinner color="white" size={24} />
                : "Conferma e invia"
              }
            </Button>
          </div>
        </div>

        {!!edit &&
          <ImportModal
            open
            close={(): void => setEdit(null)}
            row={edit.row}
            onSuccess={(data): void => {
              if (edit) {
                const row = { ...edit.row, ...data }
                updateRow({
                  row,
                  index: edit.index
                })
                setEdit(null)
              }
            }}
          />
        }
      </div>
      : null
  )
}

export default PreviewTable
