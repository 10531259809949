import { FC, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Button from "../components/Button"
import Loader from "../components/Loader"
import PatientContent from "../components/PatientContent"
import Row from "../components/Row"
import Text from "../components/Text"
import Title from "../components/Title"
import useError from "../hooks/useError"
import { GetPatientDTO } from "../types/api"
import api from "../util/api"

type Params = {
  id?: string
}

const Patient: FC = () => {
  const { id } = useParams<Params>()

  const [data, setData] = useState<GetPatientDTO>()
  const [loading, setLoading] = useState<boolean>(!!id)

  const { onError } = useError()

  useEffect(() => {
    if (id) {
      void (async(): Promise<void> => {
        try {
          const res = await api.patients.getSingle(id)

          if (res.data.payload) {
            setData(res.data.payload)
          }
        } catch (err) {
          onError(err)
        } finally {
          setLoading(false)
        }
      })()
    }
  }, [])

  return (
    <>
      {loading
        ? <Loader />
        : data
          ?
          <>
            <Row className="heading mb-10" justify="between">
              <Title>{data.lastName} {data.firstName}</Title>

              <Link to={`/add-monitoring?id=${id}`}><Button>Nuovo monitoraggio</Button></Link>
            </Row>

            <PatientContent data={data} setData={setData} />
          </>
          : <Text>Si è verificato un errore</Text>
      }
    </>
  )
}

export default Patient
