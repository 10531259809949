import { FC, useRef, useState } from "react"
import Radio from "../Radio"
import Row from "../Row"
import Text from "../Text"

type Props = {
  min: number
  max: number
  step: number
  onSelect(value: number): void
  goNext(): void
  value?: number
  minLabel?: string
  maxLabel?: string
}

const RangeAnswer: FC<Props> = ({
  min,
  max,
  step,
  onSelect,
  goNext,
  value,
  minLabel,
  maxLabel
}) => {
  const arr = useRef<number[]>(new Array(max / step).fill(null).map((_, i) => (i + 1) * min * step)).current
  const [selected, setSelected] = useState<number | undefined>(value)

  return (
    <Row className="check__radios" justify="center" >
      {arr.map((value, i) => (
        <div className="check__radio" key={i}>
          <Text className="check__radio-label mb-2 text-center" theme="grey" size="sm">{value}</Text>

          <Radio className="mx-8" value={value} checked={selected === value} onChange={(): void => {
            setSelected(value)
            onSelect(value)
            goNext()
          }} />

          {i === 0 &&
            <Text className="check__radio-descriptor" size="sm" theme="grey">{minLabel ?? "Poco"}</Text>
          }

          {value === arr[arr.length - 1] &&
            <Text className="check__radio-descriptor" size="sm" theme="grey">{maxLabel ?? "Tanto"}</Text>
          }
        </div>
      ))}
    </Row>
  )
}

export default RangeAnswer
