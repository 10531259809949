import classNames from "classnames"
import { FC } from "react"
import Icon from "./Icon"

type Props = {
  active?: "asc" | "desc"
  className?: string
}

const TableSort: FC<Props> = ({
  active,
  className
}) => {
  return (
    <div className={classNames("table__sort", className)}>
      <div className={classNames("table__sort-icon", active === "desc" ? "text-blue-dark" : "text-gray-300")}>
        <Icon name="chevron-up" />
      </div>

      <div className={classNames("table__sort-icon", active === "asc" ? "text-blue-dark" : "text-gray-300")}>
        <Icon name="chevron-down" />
      </div>
    </div>
  )
}

export default TableSort
