import classNames from "classnames"
import { FC, useState } from "react"
import { IconName } from "../types/icon"
import Icon from "./Icon"
import Text from "./Text"

type Props = {
  className?: string
  size?: "xs" | "sm" | "md" | "lg"
  text: string
  icon?: IconName
  iconSize?: number
}

const Info: FC<Props> = ({
  className,
  size = "md",
  text,
  icon = "info",
  iconSize
}) => {
  const [visible, setVisible] = useState<boolean>(false)

  return (
    <div className={classNames("info", className)} onMouseEnter={(): void => setVisible(true)} onMouseLeave={(): void => setVisible(false)}>
      <Icon name={icon} size={iconSize} />

      {visible &&
        <div className={classNames("info__text p-2", size)}>
          <Text size="xs">{text}</Text>
        </div>
      }
    </div>
  )
}

export default Info
