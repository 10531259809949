import classNames from "classnames"
import { createPortal } from "react-dom"
import { HTMLProps, FC, useRef, useState, useEffect } from "react"
import useAutoClose from "../hooks/useAutoClose"
import Transition from "./transition/Transition"
import { ReactNode } from "react"
import Icon from "./Icon"

interface Props extends Omit<HTMLProps<HTMLDivElement>, "title"> {
  title?: ReactNode
  headerClassName?: string
  contentClassName?: string
  footer?: ReactNode
  footerClassName?: string
  open: boolean
  close: () => void
  onClose?(): void
  closeOnOutsideClick?: boolean
}

const Modal: FC<Props> = ({
  children,
  title,
  headerClassName,
  contentClassName,
  footer,
  footerClassName,
  open,
  close,
  onClose,
  className,
  closeOnOutsideClick = true,
  ...props
}: Props) => {
  const modalRef = useRef(null)
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    if (!open) {
      setVisible(false)
    }
  }, [open])

  useAutoClose({
    condition: closeOnOutsideClick ? visible : false,
    action: () => close(),
    excludeTargets: [modalRef.current]
  })

  return createPortal(
    <>
      <Transition
        className="modal__backdrop"
        show={open}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
        onEntered={(): void => setVisible(true)}
        unmountOnExit
      />

      <Transition
        className="modal__dialog"
        role="dialog"
        aria-modal="true"
        show={open}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
        onEntered={(): void => setVisible(true)}
        onExited={onClose}
        unmountOnExit
      >
        <div
          ref={modalRef}
          className={classNames("modal", className)}
          {...props}
        >
          <div className={classNames("modal__header", headerClassName)}>
            <div className="modal__header-title">{title}</div>

            <button className="modal__header-button" onClick={close}>
              <div className="sr-only">Chiudi</div>

              <Icon name="x" size={24} />
            </button>
          </div>

          <div className={classNames("modal__content", contentClassName)}>
            {children}
          </div>

          {!!footer &&
            <div className={classNames("modal__footer", footerClassName)}>
              {footer}
            </div>
          }
        </div>
      </Transition>
    </>, document.getElementById("root")!
  )
}

export default Modal
