const colors = {
  azure: "#5ED0E0",
  blueDark: "#091692",
  blue: "#73ACF9",
  blueLight: "#BCD2F0",
  blueInput: "#E7EAEE",
  blueWhite: "#ECF0F3",
  red: "#F27D73",
  redDark: "#A14C4C",
  grey: "#C2C5C7",
  greyLight: "#DDE2E5",
  greyBorder: "#BEC2C6",
  greyText: "#868A8E",
  greyDark: "#616365",
  green: "#4BAF62",
  yellow: "#FDC55A",
  white: "#FFFFFF"
}

export default colors

export type ColorName = keyof typeof colors
