import { FC, FormEvent, useEffect, useRef, useState } from "react"
import signup from "../../constants/signup"
import { toast } from "react-toastify"
import useError from "../../hooks/useError"
import { CreateSubscriptionDTO, GetCouponDTO, PlanDetailDTO } from "../../types/api"
import api from "../../util/api"
import Button from "../Button"
import CouponInfo from "../CouponInfo"
import Input from "../Input"
import PaymentPlan from "../PaymentPlan"
import Row from "../Row"
import Spinner from "../Spinner"
import Text from "../Text"
import Title from "../Title"
import Checkbox from "../Checkbox"

type Props = {
  onComplete(options: CreateSubscriptionDTO): void
  trialEnabled?: boolean
}

const AccountPaymentPlans: FC<Props> = ({
  onComplete,
  trialEnabled = true
}) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [plans, setPlans] = useState<PlanDetailDTO[]>([])
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null)
  const [coupon, setCoupon] = useState<GetCouponDTO>()
  const [couponPlans, setCouponPlans] = useState<boolean[]>([])
  const [trial, setTrial] = useState<boolean>(false)

  const input = useRef<HTMLInputElement>(null)

  const { onError } = useError()

  const checkCoupon = async(e: FormEvent): Promise<void> => {
    e.preventDefault()

    if (input.current) {
      const value = input.current.value

      if (value.length) {
        try {
          const { data } = await api.payments.checkCoupon(value)

          setCoupon(data.payload)

          toast.success("Il coupon inserito è stato verificato con successo")
        } catch (err) {
          onError(err)
        }
      }
    }
  }

  const onConfirm = (): void => {
    if (selectedPlan) {
      onComplete({
        planId: selectedPlan,
        coupon: coupon?.id,
        trial
      })
    } else {
      toast.warning("È obbligatorio selezionare un piano")
    }
  }

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log("plans", plans)
  }, [plans])

  useEffect(() => {
    if (coupon) {
      setCouponPlans(coupon?.products?.map(priceId => plans.findIndex(p => p.priceId === priceId) > -1) ?? new Array(plans.length).fill(true))
    }
  }, [coupon])

  useEffect(() => {
    void (async(): Promise<void> => {
      try {
        const res = await api.payments.getPlans()

        setPlans(res.data.payload.plans ?? plans)
      } catch (err) {
        onError(err)
      }

      setLoading(false)
    })()
  }, [])

  return (
    <>
      {loading
        ?
        <>
          <Text className="mb-4 text-center" size="sm">Recupero dati in corso...</Text>

          <Row justify="center">
            <Spinner />
          </Row>
        </>
        : plans.length
          ?
          <>
            {signup.trialEnabled && trialEnabled &&
              <>
                <Title className='mb-4' size='s' uppercase align='center'>Prova Tracymed gratis</Title>

                <div className='mb-4 text-sm text-gray-500 text-center'>È disponibile un <span className="font-semibold">periodo di prova di 15 giorni con 10 monitoraggi gratuiti</span>.<br/>Se deciderete di attivarlo, il pagamento verrà effettuato automaticamente alla fine dei 15 giorni, oppure potrà essere anticipato manualmente da voi in qualsiasi momento.<br/>Riceverete una mail 3 giorni prima della scadenza e una in caso di pagamento riuscito o fallito.</div>

                <Row className='mb-4 pb-4 border-b border-gray-300' justify="center">
                  <Checkbox
                    checked={trial}
                    onChange={(checked: boolean): void => setTrial(checked)}
                    label="Attiva il periodo di prova"
                  />
                </Row>
              </>
            }

            <Title className='mb-4' size="s" uppercase align='center'>Seleziona un piano</Title>

            <ul>
              {plans.map((plan, i) => (
                <li key={plan.id} className="mb-2 last:mb-0">
                  <PaymentPlan
                    data={plan}
                    selected={selectedPlan === plan.priceId}
                    coupon={couponPlans[i] ? coupon : undefined}
                    onSelect={(priceId: string): void => setSelectedPlan(priceId)}
                  />
                </li>
              ))}
            </ul>

            <form onSubmit={checkCoupon} autoComplete="off">
              <Row align="end" className="mt-4">
                <Input
                  label="Coupon"
                  inputRef={input}
                  autoComplete="new-password"
                />

                <Button type="submit" className="ml-4">Verifica</Button>
              </Row>
            </form>

            {!!coupon &&
              <CouponInfo className="mt-2" data={coupon} plans={plans} />
            }

            <Row className="mt-8" justify="center">
              <Button disabled={!selectedPlan} onClick={onConfirm}>Conferma</Button>
            </Row>
          </>
          : <Text>Si è verificato un errore, le chiediamo cortesemente di contattarci alla mail <Text as="a" href="mailto:support@tracymed.com" className="underline">support@tracymed.com</Text></Text>
      }
    </>
  )
}

export default AccountPaymentPlans
