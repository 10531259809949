import { FC } from "react"
import { useNavigate } from "react-router-dom"
import PatientForm from "$components/form/PatientForm"
import Row from "$components/Row"
import Title from "$components/Title"
import Card from "$components/Card"
import Col from "$components/Col"

const PatientAdd: FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <Row className="heading mb-10" justify="between">
        <Title>Nuovo Paziente</Title>
      </Row>

      <div className="grid grid-cols-12">
        <Col xl={8}>
          <Card>
            <PatientForm onSuccess={(data): void => {
              navigate(`/patient/${data.id}`)
            }} />
          </Card>
        </Col>
      </div>
    </>
  )
}

export default PatientAdd
