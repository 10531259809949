import { FC, useEffect, useState } from "react"
import useError from "$hooks/useError"
import { GetBillingDataDTO } from "$types/api"
import api from "$util/api"
import Button from "$components/Button"
import LoaderInline from "$components/LoaderInline"
import Title from "$components/Title"
// import AccountInvoices from "$components/account/AccountInvoices"
import AccountRow from "$components/account/AccountRow"
import BillingForm from "$components/form/BillingForm"

const AccountBilling: FC = () => {
  const [loadingBilling, setLoadingBilling] = useState<boolean>(true)
  // const [loadingInvoices, setLoadingInvoices] = useState<boolean>(true)
  const [billingData, setBillingData] = useState<GetBillingDataDTO>()
  // const [invoices, setInvoices] = useState<InvoiceDetails[]>([])
  const [formOpen, setFormOpen] = useState<boolean>(false)

  const { onError } = useError()

  const getBillingData = async(): Promise<void> => {
    setLoadingBilling(true)

    try {
      const { data } = await api.payments.getBillingData()

      setBillingData(data.payload)
    } catch (err) {
      onError(err)
    }

    setLoadingBilling(false)
  }

  // const getInvoices = async(): Promise<void> => {
  //   setLoadingInvoices(true)

  //   try {
  //     const { data } = await api.payments.getInvoices()

  //     setInvoices(data.payload?.invoices || [])
  //   } catch (err) {
  //     onError(err)
  //   }

  //   setLoadingInvoices(false)
  // }

  useEffect(() => {
    void getBillingData()
    // void getInvoices()
  }, [])

  return (
    <>
      <Title size='s' className='mb-3'>Dati di fatturazione</Title>

      {loadingBilling
        ? <LoaderInline />
        : formOpen
          ?
          <BillingForm
            data={billingData}
            onSuccess={(): void => {
              setFormOpen(false)
              void getBillingData()
            }}
            onCancel={(): void => {
              setFormOpen(false)
            }}
            className="mt-6"
            buttonJustify="end"
          />
          :
          <>
            {billingData
              ?
              <div className='mb-8'>
                <AccountRow
                  label='Nome Azienda'
                  value={billingData.name}
                />

                <AccountRow
                  label="Partita IVA"
                  value={billingData.vatCode}
                />

                {!!billingData.pecAddress &&
                  <AccountRow
                    label="Casella PEC"
                    value={billingData.pecAddress}
                  />
                }

                {!!billingData.sdiCode &&
                  <AccountRow
                    label="Codice SDI"
                    value={billingData.sdiCode}
                  />
                }

                <AccountRow
                  label="Indirizzo"
                  value={`${billingData.line1}, ${billingData.postCode} - ${billingData.city} (${billingData.state}), ${billingData.country}`}
                />

                <Button className='mt-4' onClick={(): void => setFormOpen(true)}>Modifica</Button>
              </div>
              :
              <div>
                <div className="text-sm text-gray-400">Dati di fatturazione non presenti</div>

                <Button theme="blue-outline" className='mt-4' onClick={(): void => setFormOpen(true)}>Aggiungi</Button>
              </div>
            }
          </>
      }

      {/* <Title size="xs" className='mb-4'>Fatture</Title>

      {loadingInvoices
        ? <LoaderInline />
        : <AccountInvoices invoices={invoices} />
      } */}
    </>
  )
}

export default AccountBilling
