import classNames from "classnames"
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { sidebarSections } from "../../constants/routes"
import useAutoClose from "../../hooks/useAutoClose"
import SidebarSection from "./SidebarSection"
import { hamburger } from "$util/refs"
import SidebarLogo from "./SidebarLogo"
import Icon from "../Icon"

export type SidebarRef = {
  toggle(): void
  open(): void
  close(): void
}

const Sidebar = forwardRef<SidebarRef>((_props, ref) => {
  const [expanded, setExpanded] = useState(localStorage.getItem("sidebar-expanded") === "true")
  const [open, setOpen] = useState(false)

  const trigger = useRef<HTMLButtonElement>(null)
  const sidebar = useRef<HTMLDivElement>(null)

  useAutoClose({
    condition: open,
    action: () => setOpen(false),
    excludeTargets: [trigger.current, sidebar.current, hamburger.current]
  })

  useImperativeHandle(ref, () => ({
    toggle: (): void => {
      setOpen(!open)
    },
    open: (): void => {
      setOpen(true)
    },
    close: (): void => {
      setOpen(false)
    }
  }))

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", `${expanded}`)
    document.body.classList.toggle("sidebar-expanded", expanded)
  }, [expanded])

  return (
    <>
      <div className={classNames(
        "sidebar__backdrop",
        open && "sidebar__backdrop--open"
      )} aria-hidden="true"></div>

      <div
        ref={sidebar}
        className={classNames(
          "sidebar",
          open && "sidebar--open"
        )}
      >

        <div className="sidebar__header">
          <button
            ref={trigger}
            className="sidebar__close"
            onClick={(): void => setOpen(!open)}
            aria-controls="sidebar"
            aria-expanded={open}
          >
            <span className="sr-only">Close sidebar</span>

            <Icon name="arrow-left" size={24} />
          </button>

          <SidebarLogo />
        </div>

        <div className="sidebar__sections">
          {sidebarSections.map((section, i) => (
            <SidebarSection
              key={i}
              section={section}
              expanded={expanded}
              setExpanded={setExpanded}
            />
          ))}
        </div>

        <div className="sidebar__expand">
          <div className="sidebar__expand-wrapper">
            <button onClick={(): void => setExpanded(!expanded)}>
              <span className="sr-only">Espandi / restringi sidebar</span>

              <Icon name={expanded ? "chevron-left" : "chevron-right"} size={24} className="text-gray-400" />
            </button>
          </div>
        </div>
      </div>
    </>
  )
})

export default Sidebar
