import { Appearance, Stripe, StripeElements } from "@stripe/stripe-js"
import { AxiosError } from "axios"
import classNames from "classnames"
import { FC, FormEvent, ReactNode, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import colors from "../../constants/colors"
import useAuth from "../../hooks/useAuth"
import useError from "../../hooks/useError"
import { CreatedSubscriptionDTO, CreateSubscriptionDTO } from "../../types/api"
import api from "../../util/api"
import Button from "../Button"
// import Checkbox from "../Checkbox"
import LoaderInline from "../LoaderInline"
import Row from "../Row"
import Text from "../Text"
import Title from "../Title"

const maxPollings = 5
const siteUrl = process.env.REACT_APP_SITE_URL as string
const publicKey = process.env.REACT_APP_STRIPE_API_KEY as string

const appearance: Appearance = {
  variables: {
    colorBackground: colors.white,
    colorText: colors.greyDark,
    fontFamily: "Montserrat, sans-serif",
    borderRadius: "4px"
  },
  rules: {
    ".Label": {
      fontSize: "16px",
      fontWeight: "500"
    }
  }
}

type Props = {
  planOptions?: CreateSubscriptionDTO
  isAddingMethod?: boolean
  onMethodAdded?(): void
  onCancel?(): void
}

const AccountPaymentForm: FC<Props> = ({
  planOptions,
  isAddingMethod,
  onMethodAdded,
  onCancel
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [mountingStripe, setMountingStripe] = useState<boolean>(true)
  const [paying, setPaying] = useState<boolean>(false)
  const [error, setError] = useState<ReactNode>()
  const [subscription, setSubscription] = useState<CreatedSubscriptionDTO | null>()
  const [clientSecret, setClientSecret] = useState<string>()
  const [isDefault] = useState<boolean>(true)
  const [fatalError, setFatalError] = useState<boolean>(false)

  const stripe = useRef<Stripe | null>()
  const stripeElements = useRef<StripeElements>()
  const pollingCount = useRef<number>(0)
  const pollingId = useRef<number>()

  const { updateCompany, updateUser } = useAuth()
  const { onError } = useError()

  const checkPaymentStatus = async(): Promise<void> => {
    try {
      const { data } = await api.payments.checkResult()

      switch (data.payload.id) {
        case 1:
          pollingId.current = setTimeout(() => {
            pollingCount.current += 1

            if (pollingCount.current < maxPollings) {
              void checkPaymentStatus()
            } else {
              toast.warning(planOptions?.trial ? "La carta non risulta ancora registrata, si prega di riprovare ad accedere tra poco." : "Il pagamento non risulta ancora registrato, si prega di riprovare ad accedere tra poco.", {
                autoClose: 5000
              })
            }
          // eslint-disable-next-line @typescript-eslint/ban-types
          }, 2000) as unknown as number
          break
        case 2:
          toast.error(
            planOptions?.trial
              ? "La carta non è stata registrata, si prega di riprovare"
              : "Il pagamento non è andato a buon fine, si prega di riprovare",
            {
              autoClose: 5000
            }
          )
          setPaying(false)
          break
        case 3:
          await updateUser()
          await updateCompany()
          toast.success(planOptions?.trial
            ? "Registrazione effettuata con successo"
            : "Pagamento effettuato con successo"
          )
          break
      }
    } catch (err) {}
  }

  const confirmPayment = async(e: FormEvent): Promise<void> => {
    e.preventDefault()

    if (!paying && stripe.current && stripeElements.current) {
      setPaying(true)

      const method = subscription
        ? subscription.setupIntent ? "confirmSetup" : "confirmPayment"
        : "confirmSetup"

      const { error } = await stripe.current[method]({
        elements: stripeElements.current,
        redirect: "if_required",
        confirmParams: {
          return_url: siteUrl
        }
      })

      if (error) {
        // console.warn("stripe error", error)
        toast.error(`Si è verificato un errore: ${error.message}`)
        setPaying(false)
      } else {
        if (isAddingMethod) {
          onMethodAdded?.()
        } else {
          void checkPaymentStatus()
        }
      }
    }
  }

  useEffect(() => {
    if ((subscription || clientSecret) && !stripe.current) {
      void import("@stripe/stripe-js").then(async({ loadStripe }) => {
        stripe.current = await loadStripe(publicKey)

        if (stripe.current) {
          stripeElements.current = stripe.current.elements({
            clientSecret: subscription ? subscription.clientSecret : clientSecret,
            appearance,
            locale: "it"
          })

          if (stripeElements.current) {
            const paymentElement = stripeElements.current.create("payment", {
              paymentMethodOrder: ["card"]
            })

            if (paymentElement) {
              paymentElement.on("ready", () => {
                setMountingStripe(false)
              })
              paymentElement.mount("#payment-element")
            } else {
              setError(<>Si è verificato un errore inaspettato: <Text as="span" className="font-bold" theme="error">PAYMENT_ELEMENT_MOUNT</Text>.<br/>Si prega di riprovare più tardi o di contattarci a <a href="mailto:support@tracymed.com" target="_blank" rel="nofollow noreferrer">support@tracymed.com</a> e comunicarci il codice di errore.</>)
            }
          } else {
            setError(<>Si è verificato un errore inaspettato: <Text as="span" className="font-bold" theme="error">STRIPE_ELEMENTS_INIT</Text>.<br/>Si prega di riprovare più tardi o di contattarci a <a href="mailto:support@tracymed.com" target="_blank" rel="nofollow noreferrer">support@tracymed.com</a> e comunicarci il codice di errore.</>)
          }
        } else {
          setError(<>Si è verificato un errore inaspettato: <Text as="span" className="font-bold" theme="error">STRIPE_LOAD</Text>.<br/>Si prega di riprovare più tardi o di contattarci a <a href="mailto:support@tracymed.com" target="_blank" rel="nofollow noreferrer">support@tracymed.com</a> e comunicarci il codice di errore.</>)
        }
      })
    }
  }, [subscription, clientSecret])

  useEffect(() => {
    void (async(): Promise<void> => {
      if (isAddingMethod) {
        try {
          const { data } = await api.payments.addPaymentMethod(isDefault)

          setLoading(false)
          setClientSecret(data.payload?.clientSecret)
        } catch (err) {
          onError(err)
          setLoading(false)
        }
      } else {
        if (planOptions) {
          try {
            const { data } = await api.payments.createSubscription({ ...planOptions })

            setLoading(false)
            setSubscription(data.payload)
          } catch (err) {
            // console.log((err as any).response)
            if ((err as AxiosError).response?.status === 500) {
              setFatalError(true)
            }
            onError(err)
            setLoading(false)
          }
        } else {
          toast.error("Si è verificato un errore di implementazione, si prega di contattare l'assistenza")
        }
      }
    })()

    return (): void => {
      if (pollingId.current) {
        clearTimeout(pollingId.current)
      }
    }
  }, [])

  return (
    fatalError
      ?
      <Text theme='error' size='lg' className='text-center'>Si è verificato un errore imprevisto. Provate a ricaricare la pagina e se il problema sussiste non esitate a contattarci alla mail <Text as="a" theme="error" size="lg" className="font-semibold" href="mailto:support@tracymed.com" target="_blank" rel="noreferrer">support@tracymed.com</Text></Text>
      :
      <>
        {!isAddingMethod &&
          <Title className='mb-4' uppercase align='center'>Completa il pagamento</Title>
        }

        {loading
          ? <LoaderInline label="Recupero dati in corso..." />
          :
          <>
            <form
              id="payment-form"
              className={classNames(paying && "disabled")}
              onSubmit={confirmPayment}
            >
              <div id="payment-element"></div>

              {mountingStripe
                ? <LoaderInline label="Connessione a Stripe in corso..." />
                :
                <>
                  {/* {isAddingMethod &&
                    <Checkbox
                      className='my-4'
                      checked={isDefault}
                      onChange={(checked: boolean): void => setIsDefault(checked)}
                      label="Imposta come metodo predefinito"
                    />
                  } */}

                  <Row className='mt-4' justify='center'>
                    <Button type="submit">Conferma</Button>
                  </Row>

                  {!isAddingMethod && !!onCancel &&
                    <Row justify='center' className='mt-4'>
                      <Text size="sm" className="underline cursor-pointer" onClick={onCancel}>Cambia piano</Text>
                    </Row>
                  }
                </>
              }

              {error
                ? <Text className="mt-4 font-semibold text-center" theme="error">{error}</Text>
                : null
              }
            </form>
          </>
        }
      </>
  )
}

export default AccountPaymentForm
