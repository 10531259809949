import classNames from "classnames"
import { createElement, FunctionComponent, HTMLProps } from "react"

export interface RowProps extends Omit<HTMLProps<HTMLElement>, "wrap"> {
  as?: keyof JSX.IntrinsicElements
  align?: "start" | "center" | "end" | "stretch"
  justify?: "start" | "center" | "end" | "between" | "around"
  wrap?: boolean
}

const Row: FunctionComponent<RowProps> = ({
  as = "div",
  align = "center",
  justify = "start",
  wrap,
  children,
  className,
  ...props
}) => {
  return (
    createElement(
      as,
      {
        className: classNames(
          "flex",
          `justify-${justify} items-${align}`,
          { wrap },
          className
        ),
        ...props
      },
      children
    )
  )
}

export default Row
