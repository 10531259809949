/* eslint-disable @typescript-eslint/no-unsafe-return */
import classNames from "classnames"
import { FC, HTMLProps, ReactElement, ReactNode, RefObject } from "react"
import ReactSelect, { components, Props as SelectProps } from "react-select"
import Icon from "./Icon"
import Label from "./Label"

interface Props extends Omit<SelectProps, "styles"> {
  isFilter?: boolean
  error?: ReactNode | boolean
  errorProps?: HTMLProps<HTMLDivElement>
  label?: ReactNode
  labelProps?: HTMLProps<HTMLLabelElement>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectRef?: RefObject<any>
}

const Select: FC<Props> = ({
  className,
  isSearchable = false,
  isFilter,
  error,
  errorProps,
  label,
  labelProps,
  selectRef,
  components: propsComponents,
  ...props
}) => {
  return (
    <div className={className}>
      {label
        ? <Label {...labelProps}>{label}</Label>
        : null
      }

      <ReactSelect
        className={classNames("select", !!error && "select--error")}
        classNamePrefix="select"
        controlShouldRenderValue={!isFilter}
        components={{
          IndicatorSeparator: (): ReactElement | null => null,
          DropdownIndicator: (props): ReactElement | null => (
            <components.DropdownIndicator {...props} className={classNames(props.className, !!error && "select__dropdown-indicator--error")}>
              <Icon name="chevron-down" />
            </components.DropdownIndicator>
          ),
          MultiValueContainer: (props): ReactElement | null => {
            return isFilter ? null : <components.MultiValueContainer {...props} />
          },
          MultiValueRemove: (props): ReactElement | null => {
            return isFilter ? null : <components.MultiValueRemove {...props} />
          },
          ClearIndicator: (props): ReactElement | null => {
            return (
              <components.ClearIndicator {...props}>
                <Icon name="circle-x" />
              </components.ClearIndicator>
            )
          },
          ...propsComponents
        }}
        isSearchable={isSearchable}
        ref={selectRef}
        menuShouldScrollIntoView={false}
        captureMenuScroll={false}
        {...props}
      />

      {error && typeof error !== "boolean"
        ?
        <div
          {...errorProps}
          className={classNames("input__error", errorProps?.className)}
        >
          {error}
        </div>
        : null
      }
    </div>
  )
}

export default Select
