/* eslint-disable @typescript-eslint/ban-types */
import { AxiosError } from "axios"
import { useContext, useState } from "react"
import { toast } from "react-toastify"
import { AuthContext, AuthContextType } from "../context/AuthContext"
import { handleError, HandleErrorParams } from "../util/api"
import cookies from "../util/cookies"

type APIFieldError<T extends string> = {
  field: T
  message: string
}

type FieldErrors<T extends string> = {
  [key in T]: string
}

type UseErrorReturnType<T extends string> = {
  onError(err: unknown, config?: HandleErrorParams): void
  fieldErrors: FieldErrors<T>
  clearErrors(keys?: T | T[]): void
}

export default function useError<T extends string>(): UseErrorReturnType<T> {
  const [fieldErrors, setFieldErrors] = useState<FieldErrors<T>>({} as FieldErrors<T>)

  const { setUser } = useContext<AuthContextType>(AuthContext)

  const onError = (err: unknown, config?: HandleErrorParams): void => {
    const error = err as AxiosError

    handleError(error, {
      onRequest: (err) => {
        if (config?.onRequest) {
          config.onRequest(err)
        } else {
          toast.error(`${err}`)
        }
      },
      onResponse: (res) => {
        if (config?.onResponse) {
          config.onResponse(res)
        } else {
          if (res.status === 401) {
            setUser(null)
            toast.warning("Sessione scaduta")
            cookies.delete("session")
          } else {
            let text = `Errore: ${res.statusText}`
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data = res.data as any
            if (data?.message) {
              if (data.message === "fieldError" && data.fieldErrors) {
                setFieldErrors((data.fieldErrors as APIFieldError<T>[]).reduce((obj, curr) => {
                  obj[curr.field] = curr.message
                  return obj
                }, {} as FieldErrors<T>))

                return
              } else {
                text = `${data.message}`
              }
            }
            toast.error(text)
          }
        }
      },
      onGeneric: (err) => {
        if (config?.onGeneric) {
          config.onGeneric(err)
        } else {
          toast.error(`Generic error: ${err}`)
        }
      }
    })
  }

  const clearErrors = (keys?: T | T[]): void => {
    setFieldErrors((fieldErrors) => {
      if (keys) {
        const newFields = {} as FieldErrors<T>
        const currentKeys = Object.keys(fieldErrors) as T[]

        if (typeof keys === "string") {
          return currentKeys
            .filter(k => k !== keys)
            .reduce((obj, k) => {
              obj[k] = fieldErrors[k]
              return obj
            }, newFields)
        } else {
          return currentKeys.reduce((obj, k) => {
            if (!keys.includes(k)) {
              obj[k] = fieldErrors[k]
            }
            return obj
          }, newFields)
        }
      } else {
        return {} as FieldErrors<T>
      }
    })
  }

  return {
    fieldErrors,
    onError,
    clearErrors
  }
}
