import cookiesMap, { CookieKey } from "../constants/cookies"

class Cookies {
  set(key: CookieKey, value: string | number, days?: number): void {
    const name = cookiesMap[key]
    let expires = ""
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
      expires = `; expires=${date.toUTCString()}`
    }
    document.cookie = `${name}=${value}${expires}; path=/`
  }

  get(key: CookieKey): string | null {
    const name = cookiesMap[key]
    const nameEQ = `${name}=`
    const ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === " ") {
        c = c.substring(1, c.length)
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length)
      }
    }
    return null
  }

  delete(key: CookieKey): void {
    this.set(key, "", -1)
  }
}

export default new Cookies()
