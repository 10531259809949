import { FC, useEffect, useState } from "react"
import BillingForm from "../components/form/BillingForm"
import AccountPayment from "../components/accountComplete/AccountPayment"
import AccountPhone from "../components/accountComplete/AccountCompletePhone"
import Icon from "../components/Icon"
import Row from "../components/Row"
import Spinner from "../components/Spinner"
import useAuth from "../hooks/useAuth"
import api from "../util/api"
import Card from "$components/Card"
import Header from "$components/header/Header"
import Col from "$components/Col"
import Message from "$components/Message"

const AccountComplete: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [billingAdded, setBillingAdded] = useState<boolean>(false)
  const [paymentStatus, setPaymentStatus] = useState<number>()

  const { user } = useAuth()

  useEffect(() => {
    void (async(): Promise<void> => {
      try {
        const { data } = await api.payments.checkResult()

        setPaymentStatus(data.payload.id)
      } catch (err) {}

      setLoading(false)
    })()
  }, [])

  return (
    <div className="page flex flex-col">
      <Header logoutOnly />

      <div className="container flex-1 pt-24 pb-16 grid grid-cols-11 items-center">
        <Col lg={5} className="lg:col-start-4">
          <Row className="mb-8" justify="center">
            <Icon name="logo" size={50} />
          </Row>

          <Card>
            {loading
              ?
              <>
                <div className="text-sm mb-4 text-center">Verifica dati in corso...</div>

                <Row justify="center">
                  <Spinner />
                </Row>
              </>
              : user
                ? user.status === 2
                  ? <AccountPhone />
                  : paymentStatus !== undefined
                    ? paymentStatus === 3
                      ?
                      <Message title="Operazione andata a buon fine">
                        Si prega di ricaricare la pagina.<br/>
                        Se continuate a vedere questo messaggio riprovate più tardi oppure contattateci a <a href="mailto:support@tracymed.com" target="_blank" className="text-blue underline">support@tracymed.com</a><br/>
                        <div className="text-xs font-bold mt-2">Codice: PAYMENT3</div>
                      </Message>
                      : billingAdded
                        ? <AccountPayment />
                        :
                        <BillingForm
                          onSuccess={(): void => {
                            setBillingAdded(true)
                          }}
                        />
                    :
                    <Message title="Si è verificato un errore" theme="danger">
                      Si prega di ricaricare la pagina.<br/>
                      Se continuate a vedere questo messaggio riprovate più tardi oppure contattateci a <a href="mailto:support@tracymed.com" target="_blank" className="text-blue underline">support@tracymed.com</a><br/>
                      <div className="text-xs font-bold mt-2">Codice: PAYMENT_UNDEFINED</div>
                    </Message>
                :
                <Message title="Si è verificato un errore" theme="danger">
                  Si prega di ricaricare la pagina.<br/>
                  Se continuate a vedere questo messaggio riprovate più tardi oppure contattateci a <a href="mailto:support@tracymed.com" target="_blank" className="text-blue underline">support@tracymed.com</a><br/>
                  <div className="text-xs font-bold mt-2">Codice: USER_NULL</div>
                </Message>
            }
          </Card>
        </Col>
      </div>
    </div>
  )
}

export default AccountComplete
