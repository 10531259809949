import DotMenu from "$components/DotMenu"
import { ReportDetail } from "$types/api"
import { formatDateHour } from "$util/format"
import { FC, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"

type Props = {
  message: ReportDetail
  onRead(reportId: number): Promise<void>
}

const PatientMessage: FC<Props> = ({
  message,
  onRead
}) => {
  const reportRow = useMemo(() => [...message.reportRows].reverse().find((row) => row.textAnswer), [message])
  const navigate = useNavigate()

  const readMessage = async(): Promise<void> => {
    if (reportRow) {
      await onRead(message.id)
    }
  }

  const options = useMemo(() => [
    { label: "Vedi paziente", action: (): void => {
      navigate(`/patient/${message.patient.id}`)
    } },
    { label: "Vedi monitoraggio", action: (): void => {
      navigate(`/monitoring/${message.monitoring}`)
    } },
    { label: "Nascondi", action: readMessage }
  ], [message])

  return (
    reportRow
      ?
      <li className="patient-message">
        <div className="patient-message__heading">
          <Link to={`/patient/${message.patient.id}`} className="patient-message__name">
            <div className="patient-message__name-text">{message.patient.firstName} {message.patient.lastName}</div>
          </Link>

          <div className="patient-message__date-container">
            <div className="patient-message__date">{formatDateHour(`${message.dateReceived} ${message.hourReceived}`)}</div>

            <DotMenu options={options} fixed />
          </div>
        </div>

        <div className="patient-message__content">
          <div className="patient-message__text">{reportRow.textAnswer}</div>
        </div>
      </li>
      : null
  )
}

export default PatientMessage
