import CopyToClipboard from "../../components/CopyToClipboard"
import Text from "../../components/Text"
import { PatientDetail } from "../../types/api"
import { CellRenderFunction, Column, TableColumnsConfig } from "../../types/table"
import { formatDate } from "../../util/format"

export const patientsTableColumns: Column[] = [
  {
    Header: "Cognome",
    accessor: "lastName",
    Cell: (({ value }) => {
      return <Text size="sm" className="font-semibold">{value}</Text>
    }) as CellRenderFunction<PatientDetail>
  },
  {
    Header: "Nome",
    accessor: "firstName"
  },
  {
    Header: "Data di nascita",
    accessor: "birthDay",
    Cell: (({ value }) => {
      return value ? formatDate(value) : "-"
    }) as CellRenderFunction<PatientDetail>
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: (({ value }) => {
      return value
        ? <div className="table__copy"><CopyToClipboard value={value} textProps={{ size: "sm" }} rowProps={{ justify: "between" }} /></div>
        : <Text size="sm">-</Text>
    }) as CellRenderFunction<PatientDetail>
  },
  {
    Header: "Telefono",
    accessor: "phone",
    Cell: (({ value, additionalProps: { data } }) => {
      return value
        ?
        <div className="table__copy">
          <CopyToClipboard label={data.phone} value={`${data.phone}`} textProps={{ size: "sm" }} rowProps={{ justify: "between" }} />
        </div>
        : <Text size="sm">-</Text>
    }) as CellRenderFunction<PatientDetail>
  }
]
export type PatientsSortable = "lastName"
export const patientsTableClickable = ["lastName"]

export type PatientsAccessors = "lastName" | "firstName" | "birthDay" | "email" | "phone"
// export const patientTableConfig: TableColumnsConfig<PatientsAccessors> = {
//   desktop: ["lastName", "firstName", "birthDay", "email", "phone"],
//   tablet: ["lastName", "firstName", "birthDay", "email", "phone"],
//   mobile: ["lastName", "firstName", "birthDay"]
// }
export const patientTableConfig: TableColumnsConfig<PatientsAccessors> = ["lastName", "firstName", "birthDay", "email", "phone"]
