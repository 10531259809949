import { FC } from "react"
import Text from "../Text"
import Title from "../Title"
import AccountPassword from "./AccountPassword"

const AccountSecurity: FC = () => {
  return (
    <>
      <Title size="s" className="mb-1">Sicurezza</Title>

      <Text size="sm" theme="grey" className="mb-4">In questa sezione potete modificare le impostazioni di sicurezza del vostro account.</Text>

      <AccountPassword />
    </>
  )
}

export default AccountSecurity
