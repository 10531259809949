import ReportTableMessage from "../../components/ReportTableMessage"
import ReportTableSend from "../../components/ReportTableSend"
import Text from "../../components/Text"
import { GetReportBasicDTO } from "../../types/api"
import { CellRenderFunction, TableColumnsConfig } from "../../types/table"
import { formatDate, formatDateHour } from "../../util/format"
import { ReportStatus, reportStatusLabels, reportStatusTypes } from "../monitorings"

export const reportsTableColumns = [
  {
    Header: "Data",
    accessor: "date",
    Cell: (({ value }) => {
      return formatDate(value)
    }) as CellRenderFunction
  },
  {
    Header: "Ora invio",
    accessor: "hour"
  },
  {
    Header: "Ora ricezione",
    accessor: "dateReceived",
    Cell: (({ value }) => {
      return <div className="whitespace-nowrap">{value ? formatDateHour(value) : "-"}</div>
    }) as CellRenderFunction
  },
  {
    Header: "Stato",
    accessor: "status",
    Cell: (({ value }) => {
      return <Text theme={reportStatusTypes[value as ReportStatus] ?? "default"} className="font-semibold" size="sm">{reportStatusLabels[value as ReportStatus] ?? "Non definito"}</Text>
    }) as CellRenderFunction
  },
  {
    Header: "",
    accessor: "id",
    Cell: (({ additionalProps }) => {
      return (
        <ReportTableMessage report={additionalProps.data} />
      )
    }) as CellRenderFunction<GetReportBasicDTO>
  }
]
export type ReportsTableCellProps = {
  sendMessage(reportId: number): Promise<void>
  canSend: boolean
}
export const reportsTableColumnsDemo = [
  ...reportsTableColumns,
  {
    Header: "",
    accessor: "monitoring",
    Cell: (({ additionalProps }) => {
      return (
        <ReportTableSend
          canSend={additionalProps.props.canSend}
          sendMessage={(): void => {
            void additionalProps.props.sendMessage(additionalProps.data.id)
          }}
        />
      )
    }) as CellRenderFunction<GetReportBasicDTO, ReportsTableCellProps>
  }
]

export type ReportsAccessors = "date" | "hour" | "dateReceived" | "status" | "id" | "monitoring"
// export const reportsTableConfig: TableColumnsConfig<ReportsAccessors> = {
//   desktop: ["date", "hour", "dateReceived", "status", "id", "monitoring"],
//   tablet: ["date", "hour", "dateReceived", "status", "id", "monitoring"],
//   mobile: ["date", "status", "id", "monitoring"]
// }
export const reportsTableConfig: TableColumnsConfig<ReportsAccessors> = ["date", "hour", "dateReceived", "status", "id", "monitoring"]
