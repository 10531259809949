/* eslint-disable @typescript-eslint/no-unsafe-return */
import Text from "../../components/Text"
import { CellRenderFunction, Column, TableColumnsConfig } from "../../types/table"
import { formatDate } from "../../util/format"

export const monitoringTableColumns: Column[] = [
  {
    Header: "Titolo",
    accessor: "title",
    Cell: (({ value }) => {
      return value ? value : "-"
    }) as CellRenderFunction
  },
  {
    Header: "Data inizio",
    accessor: "startDate",
    Cell: (({ value }) => {
      return formatDate(value)
    }) as CellRenderFunction
  },
  {
    Header: "Data fine",
    accessor: "endDate",
    Cell: (({ value }) => {
      return formatDate(value)
    }) as CellRenderFunction
  },
  {
    Header: "Intervento",
    accessor: "protocol",
    Cell: (({ value }) => {
      return <div className="truncate">{value}</div>
    }) as CellRenderFunction
  },
  {
    Header: "Stato",
    accessor: "active",
    Cell: (({ value }) => {
      return <Text size="sm" theme={value ? "success" : "default"}>{value ? "Attivo" : "Concluso"}</Text>
    }) as CellRenderFunction
  }
]

export type MonitoringAccessors = "title" | "startDate" | "endDate" | "protocol" | "active"
// export const monitoringTableConfig: TableColumnsConfig<MonitoringAccessors> = {
//   desktop: ["title", "startDate", "endDate", "protocol", "active"],
//   tablet: ["title", "startDate", "endDate", "protocol", "active"],
//   mobile: ["title", "protocol", "active"]
// }
export const monitoringTableConfig: TableColumnsConfig<MonitoringAccessors> = ["title", "startDate", "endDate", "protocol", "active"]