import classNames from "classnames"
import { FC, useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { MonitoringStatus, monitoringStatusLabels, monitoringStatusTextThemes, monitoringStatusTypes, monitoringWidgetStatuses } from "../constants/monitorings"
import useError from "../hooks/useError"
import useMonitoring from "../hooks/useMonitoring"
import { GetMonitoringDTO } from "../types/api"
import api from "../util/api"
import Button from "./Button"
import Col from "./Col"
import DotMenu, { MenuOption } from "./DotMenu"
import MonitoringModal from "./modals/MonitoringModal"
import PercentageWidget from "./PercentageWidget"
import Row from "./Row"
import Spinner from "./Spinner"
import Text from "./Text"
import Title from "./Title"
import { formatDate } from "../util/format"
import Card from "./Card"
import Message from "./Message"

type Props = {
  className?: string
  monitoring?: GetMonitoringDTO
  monitoringId?: number
  patientId?: number
}

const MonitoringPreview: FC<Props> = ({
  className,
  monitoring,
  monitoringId,
  patientId
}) => {
  const [data, setData] = useState<GetMonitoringDTO | null>(monitoring ?? null)
  const [loading, setLoading] = useState<boolean>(!!monitoringId)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { onError } = useError()
  const navigate = useNavigate()
  const { requestEnd, archiveMonitoring, restoreMonitoring, resolveWarning } = useMonitoring<GetMonitoringDTO>()

  const menuOptions = useMemo((): MenuOption[] => {
    const arr = []

    if (monitoringId) {
      arr.push({ label: "Visualizza", action: () => navigate(`/monitoring/${monitoringId}`) })
    }

    if (data) {
      if (data.active) {
        arr.push(
          { label: "Modifica", action: () => setModalOpen(true) },
          { label: "Concludi", action: () => requestEnd(data, data => setData(data)) }
        )
      } else {
        arr.push(data.archived
          ? { label: "Rendi visibile", action: (): void => restoreMonitoring(data.id, data => setData(data)) }
          : { label: "Archivia", action: (): void => archiveMonitoring(data.id, data => setData(data)) }
        )
      }

      if (data.status !== 1) {
        arr.push({ label: "Risolvi urgenza", action: () => resolveWarning(data.id, data => setData(data)) })
      }
    }

    return arr
  }, [data])

  useEffect(() => {
    if (monitoring) {
      setData(monitoring)
    }
  }, [monitoring])

  useEffect(() => {
    if (monitoringId) {
      void(async(): Promise<void> => {
        try {
          const res = await api.monitorings.getSingle(monitoringId)

          setData(res.data.payload)
        } catch (err) {
          onError(err)
        } finally {
          setLoading(false)
        }
      })()
    }
  }, [monitoringId])

  return (
    <>
      <Card className={classNames("monitoring-preview", className)}>
        {loading
          ?
          <Row justify="center">
            <Spinner color="blue" />
          </Row>
          : data
            ?
            <>
              <Row className="mb-4" justify="between" align="start">
                <Title size="s">
                  Monitoraggio{monitoring?.shortMonitoring && " rapido"}
                  <Title theme={monitoringStatusTextThemes[data.status as MonitoringStatus]} size="s">{data.active ? "attivo" : data.archived ? "archiviato" : "concluso"}</Title>
                </Title>

                {menuOptions.length > 0 &&
                  <DotMenu
                    className="ml-2"
                    options={menuOptions}
                  />
                }
              </Row>

              {/* PROGRESS */}
              <Row className="my-8" justify="center">
                <PercentageWidget
                  progress={data.percentage as number}
                  status={monitoringWidgetStatuses[data.status as 1 | 2 | 3]}
                />
              </Row>

              {/* DATA */}
              <Row className="mb-4">
                <Title size="xs">Dati monitoraggio</Title>
              </Row>
              <div className="grid grid-cols-12 gap-y-4">
                <Col>
                  <Text size="sm" className="font-semibold uppercase">Titolo</Text>
                  <Text size="sm">{data.title || "-"}</Text>
                </Col>

                <Col sm={6}>
                  <Text size="sm" className="font-semibold uppercase">Stato</Text>
                  <Text className="font-semibold uppercase" theme={monitoringStatusTypes[data.status as MonitoringStatus]} size="sm">{monitoringStatusLabels[data.status as MonitoringStatus]}</Text>
                </Col>

                <Col sm={6}>
                  <Text size="sm" className="font-semibold uppercase">Protocollo</Text>
                  <Text size="sm" className="overflow-hidden text-ellipsis">{data.protocol}</Text>
                </Col>

                <Col sm={6}>
                  <Text size="sm" className="font-semibold uppercase">Data inizio</Text>
                  <Text size="sm">{formatDate(data.startDate as string)}</Text>
                </Col>

                <Col sm={6}>
                  <Text size="sm" className="font-semibold uppercase">Data fine</Text>
                  <Text size="sm">{formatDate(data.endDate as string)}</Text>
                </Col>

                <Col sm={6}>
                  <Text size="sm" className="font-semibold uppercase">Orario invio</Text>
                  <Text size="sm">{data.contactTime}</Text>
                </Col>

                <Col>
                  <Text size="sm" className="font-semibold uppercase">Note</Text>
                  <Text size="sm">{data.notes || "-"}</Text>
                </Col>
              </div>

              {!!monitoringId &&
                <Row justify="center" className="mt-8">
                  <Link to={`/monitoring/${monitoringId}`}>
                    <Button>Apri cartella</Button>
                  </Link>
                </Row>
              }
            </>
            :
            <Message title="Nessun monitoraggio attivo" icon="screen-share-off">
              {!!patientId &&
                <Row justify="center">
                  <Link to={`/add-monitoring?id=${patientId}`}>
                    <Button>Nuovo monitoraggio</Button>
                  </Link>
                </Row>
              }
            </Message>
        }
      </Card>

      {!!data &&
        <MonitoringModal
          data={data}
          open={modalOpen}
          close={(): void => setModalOpen(false)}
          onSuccess={(data): void => {
            setData(data)
            setModalOpen(false)
            toast.success("Monitoraggio aggiornato con successo")
          }}
        />
      }
    </>
  )
}

export default MonitoringPreview
