import { FC, useEffect, useState } from "react"
import { CreateSubscriptionDTO } from "../../types/api"
import api from "../../util/api"
import Row from "../Row"
import Spinner from "../Spinner"
import AccountPaymentForm from "./AccountPaymentForm"
import AccountPaymentPlans from "./AccountPaymentPlans"

type Props = {
  trialEnabled?: boolean
}

const AccountPayment: FC<Props> = ({
  trialEnabled = true
}) => {
  const [planOptions, setPlanOptions] = useState<CreateSubscriptionDTO | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    void (async(): Promise<void> => {
      try {
        const { data } = await api.payments.getSubscription()

        if (data.payload?.priceId) {
          const { couponId, priceId, isTrial } = data.payload
          setPlanOptions({
            coupon: couponId,
            planId: priceId,
            trial: isTrial
          })
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("subscription not found")
      }

      setLoading(false)
    })()
  }, [])

  return (
    <>
      {loading
        ?
        <>
          <div className="text-sm mb-4 text-center">Verifica dati in corso...</div>

          <Row justify="center">
            <Spinner />
          </Row>
        </>
        : planOptions
          ? <AccountPaymentForm planOptions={planOptions} onCancel={(): void => setPlanOptions(null)} />
          : <AccountPaymentPlans onComplete={(opts: CreateSubscriptionDTO): void => setPlanOptions(opts)} trialEnabled={trialEnabled} />
      }
    </>
  )
}

export default AccountPayment
