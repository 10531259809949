import { IconName } from "$types/icon"
import { FC, MouseEvent, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import Icon from "./Icon"

type Item = {
  icon: IconName
  label: string
  path: string
}

const items: Item[] = [
  {
    label: "Crea monitoraggio",
    icon: "device-analytics",
    path: "/add-monitoring"
  },
  {
    label: "Aggiungi paziente",
    icon: "user-plus",
    path: "/add-patient"
  },
  {
    label: "Carica CSV",
    icon: "file-upload",
    path: "/csv-upload"
  }
]

const FloatWidget: FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const wrapper = useRef<HTMLDivElement>(null)

  const toggleOpen = (e: MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    setOpen(!open)
  }

  useEffect(() => {
    if (open) {
      const handler = (): void => {
        setOpen(false)
      }
      window.addEventListener("click", handler)

      return (): void => {
        window.removeEventListener("click", handler)
      }
    }
  }, [open])

  return (
    <>
      {open &&
        <div className="float-widget__bg" />
      }

      <div
        className="float-widget"
        onClick={toggleOpen}
        ref={wrapper}
      >
        <Icon name={open ? "x" : "plus"} />

        {open &&
          <ul className="float-widget__menu">
            {items.map(({ icon, label, path }, i) => (
              <li key={i}>
                <Link className="float-widget__item" to={path}>
                  <div className="float-widget__item-label">
                    {label}
                  </div>

                  <div className="float-widget__item-icon">
                    <Icon name={icon} />
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        }
      </div>
    </>
  )
}

export default FloatWidget
