import classNames from "classnames"
import { FC } from "react"
import { PlanDetailDTO } from "../types/api"
import Row from "./Row"

type Metadata = {
  annualCredits: string
}

type Coupon = {
  amountOff?: number
  percentOff?: number
}

type Props = {
  data: PlanDetailDTO
  coupon?: Coupon
  selected: boolean
  onSelect(priceId: string): void
}

const PaymentPlan: FC<Props> = ({
  data,
  coupon,
  selected,
  onSelect
}) => {
  return (
    <Row
      className={classNames("plan", {
        "plan--selected": selected
      })}
      align="start"
      justify="between"
      onClick={(): void => onSelect(data.priceId)}
    >
      <Row
        className={classNames("plan__check mr-2", {
          "plan__check--checked": selected
        })}
        justify="center"
      />

      <div className="flex-1 flex justify-between">
        <div>
          <div className="plan__name">{data.name}</div>

          <div className="text-sm">{(data.metadata as Metadata).annualCredits} Pazienti monitorati</div>
        </div>

        <div className="text-right">
          {!!coupon &&
            <div className="plan__price">{((data.amount - (coupon.percentOff ? coupon.percentOff * 0.01 * data.amount : (coupon.amountOff ?? 0))) * 0.01).toFixed(2)}€ /anno</div>
          }

          <div className={classNames("plan__price", !!coupon && "plan__price--striked")}>{(data.amount * 0.01).toFixed(2)}€ /anno</div>
        </div>
      </div>
    </Row>
  )
}

export default PaymentPlan
