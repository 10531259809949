import { FC } from "react"
import { NavLink } from "react-router-dom"
import Icon from "../Icon"

const SidebarLogo: FC = () => {
  return (
    <NavLink to="/" className="sidebar__logo">
      <Icon name="logo" size={32} />
    </NavLink>
  )
}

export default SidebarLogo
