import { FC } from "react"
import { GetChecksDTO } from "$types/api"
import Title from "$components/Title"
import Text from "$components/Text"
import Button from "$components/Button"
import Row from "$components/Row"
import Icon from "$components/Icon"
import Card from "$components/Card"
import Col from "$components/Col"

type Props = {
  data: GetChecksDTO
  onStart(): void
}

const CheckIntro: FC<Props> = ({
  data,
  onStart
}) => {
  return (
    <div className="container h-full grid grid-cols-12 items-center">
      <Col lg={4} className="lg:col-start-5">
        <Row className="mb-8" justify="center">
          <Icon name="logo" size={50} />
        </Row>

        <Card>
          <Title size="s" className="mb-4 text-center" align="center">Benvenuti su Tracymed!</Title>

          <Text className="mb-4 text-center">Progresso monitoraggio: <Text as="span" className="font-semibold">{parseInt(`${data.percentage}`)}%</Text></Text>

          <Text className="mb-8 text-center" size="sm">
            Gentile Paziente,<br/>
            in riferimento all"intervento odontoiatrico<br/>
            del <Text as="span" className="font-semibold" size="sm">{data.startDate}</Text>,<br/><br/>

            per porterle offrire un servizio sempre migliore le chiediamo<br/>
            gentilmente di rispondere alle domande presenti<br/>
            nel seguente questionario.
          </Text>

          <Row justify="center">
            <Button className="check__intro-button" onClick={onStart}>Iniziamo</Button>
          </Row>
        </Card>
      </Col>
    </div>
  )
}

export default CheckIntro
