import Button from "$components/Button"
import Modal from "$components/Modal"
import Row from "$components/Row"
import { FC, useState } from "react"
import ImportForm from "$components/form/ImportForm"
import { ImportMonitoringWrapper } from "$types/api"
import Spinner from "$components/Spinner"

type Props = {
  open: boolean
  close(): void
  onSuccess(row: ImportMonitoringWrapper): void
  row: ImportMonitoringWrapper
}

const ImportModal: FC<Props> = ({
  open,
  close,
  row,
  onSuccess
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <Modal
      title={"Modifica dati"}
      footer={
        <Row justify="end" className="gap-x-4">
          <Button className="w-[180px]" theme="red-outline" onClick={close}>Annulla</Button>

          <Button className="w-[180px]" type="submit" form="import-form">
            {loading
              ? <Spinner size={20} color="white" />
              : "Conferma"
            }
          </Button>
        </Row>
      }
      className="import-modal"
      open={open}
      close={close}
      closeOnOutsideClick={false}
    >
      <ImportForm
        row={row}
        onSuccess={onSuccess}
        loading={loading}
        setLoading={setLoading}
      />
    </Modal>
  )
}

export default ImportModal
