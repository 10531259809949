import { monitoringTableColumns, monitoringTableConfig } from "$constants/tables/monitoring"
import useError from "$hooks/useError"
import useTableColumns from "$hooks/useTableColumns"
import { GetPatientDTO, MonitoringDetails } from "$types/api"
import { Row, TableData } from "$types/table"
import api from "$util/api"
import { getTableRows } from "$util/table"
import classNames from "classnames"
import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Checkbox from "./Checkbox"
import Message from "./Message"
import Spinner from "./Spinner"
import Table from "./Table"
import Title from "./Title"

type Props = {
  className?: string
  patient: GetPatientDTO
}

const PatientMonitorings: FC<Props> = ({
  className,
  patient
}) => {
  const [monitorings, setMonitorings] = useState<MonitoringDetails[]>([])
  const [showArchived, setShowArchived] = useState<boolean>(false)
  const [tableData, setTableData] = useState<TableData<MonitoringDetails>>({ raw: [], table: [] })
  const tableColumns = useTableColumns({ columns: monitoringTableColumns, config: monitoringTableConfig })
  const [loading, setLoading] = useState<boolean>(true)
  const [updating, setUpdating] = useState<boolean>(true)

  const { onError } = useError()
  const navigate = useNavigate()

  const getMonitorings = async(): Promise<void> => {
    if (!loading) {
      setUpdating(true)
    }

    try {
      const res = await api.monitorings.getMultiple({
        patient: patient.id,
        showArchived
      })

      setMonitorings(res.data.payload.monitorings)
      setTableData({
        raw: res.data.payload.monitorings,
        // eslint-disable-next-line @typescript-eslint/ban-types
        table: getTableRows(res.data.payload.monitorings as unknown as Row[], monitoringTableColumns)
      })
    } catch (err) {
      onError(err)
    }

    setLoading(false)
    setUpdating(false)
  }

  useEffect(() => {
    void getMonitorings()
  }, [showArchived])

  return (
    <div className={className}>
      <Title>Monitoraggi</Title>

      <div className="flex items-center my-8">
        <Checkbox
          label="Mostra archiviati"
          checked={showArchived}
          onChange={(): void => setShowArchived(!showArchived)}
        />
      </div>

      {loading
        ?
        <div className="flex justify-center">
          <Spinner />
        </div>
        : monitorings.length > 0
          ?
          <Table
            className={classNames(updating && "opacity-50 pointer-events-none")}
            columns={tableColumns}
            data={tableData.table}
            onRowClick={(i: number): void => navigate(`/monitoring/${monitorings[i].id}`)}
          />
          :
          <Message icon="screen-share-off" title="Nessun monitoraggio presente" />
      }
    </div>
  )
}

export default PatientMonitorings
