import { FC, HTMLProps, useEffect, useMemo, useRef, useState } from "react"
import { ReportsTableCellProps, reportsTableColumns, reportsTableColumnsDemo, reportsTableConfig } from "$constants/tables/reports"
import useError from "$hooks/useError"
import { GetMonitoringDTO, GetPatientDTO, GetReportBasicDTO } from "$types/api"
import { Row as RowType, TableData } from "$types/table"
import api from "$util/api"
import { getTableRows } from "$util/table"
import Col from "./Col"
import MonitoringPreview from "./MonitoringPreview"
import Row from "./Row"
import Spinner from "./Spinner"
import Table from "./Table"
import Text from "./Text"
import { reportClickable } from "$util/monitorings"
import { ReportStatus } from "$constants/monitorings"
import useAuth from "$hooks/useAuth"
import useTableColumns from "$hooks/useTableColumns"
import ReportsModal from "./modals/ReportsModal"
import Alert from "./Alert"
import Button from "./Button"
import PhoneModal from "./modals/PhoneModal"

const maxPollingCount = 5

interface Props extends Omit<HTMLProps<HTMLDivElement>, "data"> {
  data: GetMonitoringDTO
  updateMonitoring(): Promise<void>
}

const MonitoringContent: FC<Props> = ({
  data,
  updateMonitoring,
  ...props
}) => {
  const [reports, setReports] = useState<TableData<GetReportBasicDTO, ReportsTableCellProps>>({ table: [], raw: [], cellProps: [] })
  const [loading, setLoading] = useState<boolean>(true)
  const [, setUpdating] = useState<boolean>(false)
  const [openReport, setOpenReport] = useState<number | null>(null)
  const [resending, setResending] = useState<boolean>(false)

  const [patient, setPatient] = useState<GetPatientDTO>()
  const [phoneModalOpen, setPhoneModalOpen] = useState<boolean>(false)

  const pollingCount = useRef<number>(0)
  const pollingId = useRef<number>()

  const { user } = useAuth()
  const { onError } = useError()
  const tableColumns = useTableColumns({ columns: !user?.isDemo ? reportsTableColumns : reportsTableColumnsDemo, config: reportsTableConfig })

  const lastSentReport = useMemo(() => {
    return reports.raw.find(r => r.status !== 6)
  }, [reports])

  const isSendingMessage = (): boolean => {
    return lastSentReport?.status === 9
  }

  const startPolling = (): void => {
    if (isSendingMessage() && ++pollingCount.current <= maxPollingCount) {
      pollingId.current = setTimeout(() => {
        void getReports()
        void updateMonitoring()
      }, 1000) as unknown as number // eslint-disable-line @typescript-eslint/ban-types
    }
  }

  const getReports = async(): Promise<void> => {
    if (!loading) {
      setUpdating(true)
    }

    try {
      const { data: { payload } } = await api.reports.getMultiple({ monitoring: data.id })

      setReports({
        raw: payload.reports,
        // eslint-disable-next-line @typescript-eslint/ban-types
        table: getTableRows(payload.reports as unknown as RowType[], reportsTableColumns),
        cellProps: user?.isDemo ? payload.reports.map(report => ({
          canSend: data.active && report.canSend,
          sendMessage: (reportId: number): Promise<void> => sendMessage(reportId)
        })) : undefined
      })
    } catch (err) {
      onError(err)
    }

    setLoading(false)
    setUpdating(false)
  }

  const sendMessage = async(reportId: number): Promise<void> => {
    try {
      await api.reports.sendMessage(reportId)

      void getReports()
    } catch (err) {
      onError(err)
    }
  }

  const resendMessage = async(reportId: number): Promise<void> => {
    if (!resending) {
      setResending(true)

      try {
        await api.reports.resend(reportId)

        void updateMonitoring()
        void getReports()
      } catch (err) {
        onError(err)
      }

      setResending(false)
    }
  }

  const getPatient = async(): Promise<void> => {
    try {
      const { data: { payload } } = await api.patients.getSingle(data.patient)

      setPatient(payload)
      setPhoneModalOpen(true)
    } catch (err) {
      onError(err)
    }
  }

  useEffect(() => {
    if (reports.raw.length) {
      startPolling()
    }

    return (): void => {
      clearTimeout(pollingId.current)
    }
  }, [reports])

  useEffect(() => {
    void getReports()
  }, [])

  return (
    <>
      <div className="monitoring" {...props}>
        <div className="grid grid-cols-12 gap-8">
          <Col xl={8} xxl={9} className="order-2 xl:order-none">
            {data.shortMonitoring &&
              <div className='mb-6 text-sm'>Questo è un <span className="text-sm font-semibold text-blue">monitoraggio rapido</span> {data.active ? "e verrà" : "ed è stato"} chiuso automaticamente al primo questionario che non {data.active ? "riporterà" : "ha riportato"} complicanze.</div>
            }

            {lastSentReport?.status === 8 &&
              <Alert className="mb-6 !items-start" icon="alert-triangle" iconSize={32}>
                Non è stato possibile inviare l'ultimo questionario.<br/>
                Il numero di telefono inserito potrebbe essere sbagliato oppure si è verificato un errore di rete.<br/>
                <div className="flex max-w-[420px] gap-x-2 mt-2 whitespace-nowrap">
                  <div className="flex-1">
                    <Button
                      size="full"
                      theme="blue-outline"
                      onClick={(): void => {
                        if (patient) {
                          setPhoneModalOpen(true)
                        } else {
                          void getPatient()
                        }
                      }}
                    >Modifica numero</Button>
                  </div>

                  <div className="flex-1">
                    <Button
                      size="full"
                      theme="blue"
                      disabled={resending}
                      onClick={(): void => {
                        void resendMessage(lastSentReport.id)
                      }}
                    >
                      {resending
                        ? <Spinner size={20} color="white" />
                        : "Ritenta invio"
                      }
                    </Button>
                  </div>
                </div>
              </Alert>
            }

            {loading
              ? <Row justify="center"><Spinner /></Row>
              : reports.table.length > 0
                ?
                <Table
                  data={reports.table}
                  cellData={reports.raw}
                  cellProps={reports.cellProps}
                  columns={tableColumns}
                  strictColumns={["id", "monitoring"]}
                  rowClickable={(i): boolean => reportClickable(reports.raw[i].status as ReportStatus)}
                  onRowClick={(i): void => {
                    setOpenReport(i)
                  }}
                />
                : <Text>Nessun report presente</Text>
            }
          </Col>

          <Col xl={4} xxl={3}>
            <MonitoringPreview monitoring={data} />
          </Col>
        </div>
      </div>

      {reports.raw.length > 0 &&
        <ReportsModal
          reports={reports.raw}
          initialReport={openReport}
          close={(): void => setOpenReport(null)}
          onRead={(report: GetReportBasicDTO): void => {
            const reportIndex = reports.raw.findIndex(r => r.id === report.id)

            if (reportIndex) {
              const newReports = [...reports.raw]
              newReports.splice(reportIndex, 1, report)
              setReports({
                raw: newReports,
                // eslint-disable-next-line @typescript-eslint/ban-types
                table: getTableRows(newReports as unknown as RowType[], reportsTableColumns),
                cellProps: user?.isDemo ? newReports.map(report => ({
                  canSend: data.active && report.canSend,
                  sendMessage: (reportId: number): Promise<void> => sendMessage(reportId)
                })) : undefined
              })
            }
          }}
        />
      }

      {!!patient &&
        <PhoneModal
          id={patient.id}
          data={{
            prefix: patient.phonePrefix!,
            phone: patient.phone!
          }}
          open={phoneModalOpen}
          close={(): void => setPhoneModalOpen(false)}
          onSuccess={(data): void => {
            setPatient(data)
            if (lastSentReport) {
              void resendMessage(lastSentReport.id)
            }
            setPhoneModalOpen(false)
          }}
        />
      }
    </>
  )
}

export default MonitoringContent
