import classNames from "classnames"
import { ChangeEvent, FC, HTMLProps, ReactNode, useState } from "react"
import Label from "./Label"

type Omitted = "label" | "onChange"
interface Props extends Omit<HTMLProps<HTMLTextAreaElement>, Omitted> {
  className?: string
  inputClassName?: string
  label?: ReactNode
  labelProps?: HTMLProps<HTMLLabelElement>
  error?: ReactNode
  errorProps?: HTMLProps<HTMLDivElement>
  onChange?(value: string): void
  onFocus?(): void
  onBlur?(): void
}

const Textarea: FC<Props> = ({
  className,
  inputClassName,
  label,
  labelProps,
  error,
  errorProps,
  onChange,
  onFocus,
  onBlur,
  ...props
}: Props) => {
  const [focused, setFocused] = useState<boolean>(false)

  return (
    <div className={classNames("textarea__container", {
      "textarea__container--error": !!error,
      "textarea__container--focused": focused
    }, className)}>
      {!!label &&
        <Label {...labelProps}>{label}</Label>
      }

      <textarea
        className={classNames("textarea", inputClassName)}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>): void => {
          if (onChange) {
            onChange(e.target.value)
          }
        }}
        onFocus={(): void => {
          setFocused(true)
          if (onFocus) {
            onFocus()
          }
        }}
        onBlur={(): void => {
          setFocused(false)
          if (onBlur) {
            onBlur()
          }
        }}
        {...props}
      />

      {error
        ?
        <div
          {...errorProps}
          className={classNames("input__error", errorProps?.className)}
        >
          {error}
        </div>
        : null
      }
    </div>
  )
}

export default Textarea
