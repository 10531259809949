import { hamburger } from "$util/refs"
import classNames from "classnames"
import { FC } from "react"
import { Link } from "react-router-dom"
import { sidebar } from "../../pages/wrappers/Private"
import Icon from "../Icon"
import HeaderUser from "./HeaderUser"

type MenuItem = {
  label: string
  path: string
}
const menuItems: MenuItem[] = [
  // {
  //   label: "Crea monitoraggio",
  //   path: "/add-monitoring"
  // },
  // {
  //   label: "Aggiungi paziente",
  //   path: "/add-patient"
  // }
]

type Props = {
  logoutOnly?: boolean
}

const Header: FC<Props> = ({
  logoutOnly
}) => {
  return (
    <header className={classNames("header", logoutOnly && "header--fixed")}>
      <div className="header__wrapper">
        <div className="header__content">

          <div className="header__left">
            {!logoutOnly &&
              <button
                className="header__toggle"
                aria-controls="sidebar"
                onClick={(): void => {
                  sidebar.current?.toggle()
                }}
                ref={hamburger}
              >
                <span className="sr-only">Open sidebar</span>

                <Icon name="menu" size={24} />
              </button>
            }

            {menuItems.length > 0 &&
              <ul className="header__menu">
                {menuItems.map(({ label, path }, i) => (
                  <li key={i}>
                    <Link to={path} className="header__menu-item">
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            }
          </div>

          <div className="header__right">
            <HeaderUser logoutOnly={logoutOnly} />
          </div>

        </div>
      </div>
    </header>
  )
}

export default Header
