import Button from "$components/Button"
import Col from "$components/Col"
import InputPassword from "$components/InputPassword"
import Row from "$components/Row"
import { passwordRegex } from "$constants/regex"
import useError from "$hooks/useError"
import { PasswordResetDTO } from "$types/api"
import api from "$util/api"
import classNames from "classnames"
import { FC, ReactElement, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"

type Props = {
  className?: string
  onCancel?(): void
  onSuccess?(): void
  showSubmit?: boolean
}

const PasswordEditForm: FC<Props> = ({
  className,
  showSubmit = true,
  onCancel,
  onSuccess
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  const { onError, fieldErrors } = useError()
  const { control, formState, getValues, handleSubmit } = useForm<PasswordResetDTO>()

  const onSubmit = async(data: PasswordResetDTO): Promise<void> => {
    setLoading(true)

    try {
      await api.account.updatePassword(data)

      toast.success("Password aggiornata con successo")

      if (onSuccess) {
        onSuccess()
      }
    } catch (err) {
      onError(err)
    }

    setLoading(false)
  }

  return (
    <form
      id="password-edit-form"
      className={classNames(loading && "disabled", className)}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="grid grid-cols-12 gap-y-2">
        <Col>
          <Controller
            name="oldPassword"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Campo obbligatorio"
              }
            }}
            defaultValue=""
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <InputPassword
                  label="Password attuale"
                  name="password"
                  value={value}
                  onChange={onChange}
                  error={fieldErrors.oldPassword || formState.errors.oldPassword?.message}
                />
              )
            }}
          />
        </Col>

        <Col>
          <Controller
            name="password"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Campo obbligatorio"
              },
              validate: (value: string): string | boolean => {
                return getValues("oldPassword") === value
                  ? "La nuova password non può essere uguale a quella attuale"
                  : passwordRegex.test(value) || "La password non rispetta le condizioni indicate"
              }
            }}
            defaultValue=""
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <InputPassword
                  label="Nuova password"
                  name="password"
                  value={value}
                  onChange={onChange}
                  error={fieldErrors.password || formState.errors.password?.message}
                />
              )
            }}
          />
        </Col>

        <Col>
          <Controller
            name="matchPassword"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Campo obbligatorio"
              },
              validate: (value): string | boolean => {
                return value === getValues("password") || "La password non corrisponde a quella scelta"
              }
            }}
            defaultValue=""
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <InputPassword
                  label="Conferma password"
                  name="password"
                  value={value}
                  onChange={onChange}
                  error={fieldErrors.matchPassword || formState.errors.matchPassword?.message}
                />
              )
            }}
          />
        </Col>

        <Col>
          <div className="text-xs mb-4">La password deve contenere almeno 8 caratteri, di cui almeno una lettera minuscola, una lettera maiuscola e un numero.</div>
        </Col>

        {showSubmit &&
          <Col>
            <Row justify="end">
              {onCancel &&
                <Button type="button" theme="red-outline" className="mr-2" onClick={onCancel}>Annulla</Button>
              }

              <Button type="submit">Conferma</Button>
            </Row>
          </Col>
        }
      </div>
    </form>
  )
}

export default PasswordEditForm
