/* eslint-disable @typescript-eslint/no-unsafe-return */
import Info from "$components/Info"
import MonitoringStatus from "$components/MonitoringStatus"
import MonitoringTableActions from "../../components/MonitoringTableActions"
import { MonitoringDetails } from "../../types/api"
import { CellRenderFunction, Column, TableColumnsConfig } from "../../types/table"
import { formatDate, formatDateHour } from "../../util/format"

export type MonitoringsTableCellProps = {
  onMonitoringEdit(): void
}
export type MonitoringsSortable = "lastName" | "lastReportReceived" | "percentage"
export const monitoringsTableClickable = ["lastName", "lastReportReceived", "startDate"]
export const monitoringsTableStrict = ["lastReportReceived", "startDate", "hasMessage", "id"]
export const monitoringsTableColumns: Column[] = [
  {
    Header: "Cognome",
    accessor: "lastName",
    Cell: (({ value }) => {
      return <div className="text-sm font-semibold truncate">{value}</div>
    }) as CellRenderFunction<MonitoringDetails, MonitoringsTableCellProps>
  },
  {
    Header: "Nome",
    accessor: "firstName",
    Cell: (({ value }) => {
      return <div className="text-sm truncate">{value}</div>
    }) as CellRenderFunction<MonitoringDetails, MonitoringsTableCellProps>
  },
  {
    Header: "Titolo",
    accessor: "title",
    Cell: (({ value }) => {
      return <div className="text-sm truncate">{value ? value : "-"}</div>
    }) as CellRenderFunction<MonitoringDetails, MonitoringsTableCellProps>
  },
  {
    Header: "Intervento",
    accessor: "protocol",
    Cell: (({ value }) => {
      return <div className="text-sm truncate">{value ? value : "-"}</div>
    }) as CellRenderFunction<MonitoringDetails, MonitoringsTableCellProps>
  },
  {
    Header: "Data inizio",
    accessor: "startDate",
    Cell: (({ value }) => {
      return value ? formatDate(value) : "-"
    }) as CellRenderFunction<MonitoringDetails, MonitoringsTableCellProps>
  },
  {
    Header: "Ultima ricezione",
    accessor: "lastReportReceived",
    Cell: (({ value }) => {
      return value ? formatDateHour(value) : "-"
    }) as CellRenderFunction<MonitoringDetails, MonitoringsTableCellProps>
  },
  {
    Header: "Stato",
    accessor: "percentage",
    Cell: (({ additionalProps: { data } }) => {
      return <MonitoringStatus monitoring={data} />
    }) as CellRenderFunction<MonitoringDetails, MonitoringsTableCellProps>
  },
  {
    Header: "",
    accessor: "hasMessage",
    Cell: (({ value }) => {
      return value
        ?
        <Info
          text="Il paziente ha lasciato una comunicazione"
          icon="mail"
          size="xs"
        />
        : null
    }) as CellRenderFunction<MonitoringDetails, MonitoringsTableCellProps>
  },
  {
    Header: "",
    accessor: "id",
    Cell: (({ additionalProps }) => {
      return (
        <MonitoringTableActions
          data={additionalProps.data}
          onSuccess={additionalProps.props.onMonitoringEdit}
        />
      )
    }) as CellRenderFunction<MonitoringDetails, MonitoringsTableCellProps>
  }
]

export type MonitoringsAccessors = "lastName" | "firstName" | "title" | "protocol" | "startDate" | "lastReportReceived" | "percentage" | "hasMessage" | "id"
// export const monitoringsTableConfig: TableColumnsConfig<MonitoringsAccessors> = {
//   desktop: ["lastName", "firstName", "title", "protocol", "startDate", "lastReportReceived", "percentage", "id"],
//   tablet: ["lastName", "firstName", "protocol", "lastReportReceived", "percentage"],
//   mobile: ["lastName", "protocol", "percentage"]
// }
export const monitoringsTableConfig: TableColumnsConfig<MonitoringsAccessors> = ["lastName", "firstName", "title", "protocol", "startDate", "lastReportReceived", "percentage", "hasMessage", "id"]
