import { MonitoringDetails } from "$types/api"
import classNames from "classnames"
import { FC } from "react"

type Props = {
  className?: string
  monitoring: MonitoringDetails
}

const statusClasses = {
  1: "success",
  2: "danger",
  3: "warning"
}
const statusLabels = {
  1: "Regolare",
  2: "Urgente",
  3: "In ritardo"
}

const MonitoringStatus: FC<Props> = ({
  className,
  monitoring: {
    active,
    archived,
    status,
    receivedReports,
    totalReports
  }
}) => {
  return (
    <div className={classNames("monitoring-status", className)}>
      <div className="monitoring-status__icon-wrapper group">
        <div className={classNames(
          "monitoring-status__icon",
          statusClasses[status! as 1 | 2 | 3],
          archived && "archived"
        )} />

        <div className="monitoring-status__tooltip">
          {statusLabels[status! as 1 | 2 | 3]}
        </div>
      </div>

      <div className="text-sm ml-2">
        {archived
          ? "Archiviato"
          : active
            ? `${receivedReports} di ${totalReports}`
            : "Concluso"
        }
      </div>
    </div>
  )
}

export default MonitoringStatus
