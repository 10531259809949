import classNames from "classnames"
import { createElement, FC, HTMLProps } from "react"
import { TextAlign, TextTheme, TextWeight } from "../types/text"

type TitleSizes = "xs" | "s" | "m" | "l"

interface Props extends Omit<HTMLProps<HTMLElement>, "size"> {
  as?: keyof JSX.IntrinsicElements
  size?: TitleSizes
  theme?: TextTheme
  weight?: TextWeight
  align?: TextAlign
  uppercase?: boolean
}

const Title: FC<Props> = ({
  as = "div",
  children,
  className,
  theme = "default",
  size = "m",
  align = "left",
  weight = 700,
  uppercase,
  ...props
}) => {
  return (
    createElement(
      as,
      {
        className: classNames("title", className, {
          [`title--${size}`]: true,
          [`text-${theme}`]: true,
          [`text-${align}`]: true,
          [`text-${weight}`]: true,
          "text-uppercase": uppercase
        }),
        ...props
      },
      children
    )
  )
}

export default Title
