import { FC } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import NavigationScroll from "../components/NavigationScroll"
import { routes } from "../constants/routes"
import NotFound from "./NotFound"
import AuthWrapper from "./wrappers/Auth"
import InfoWrapper from "./wrappers/Info"
import PrivateWrapper from "./wrappers/Private"
import PublicWrapper from "./wrappers/Public"

const wrappers = {
  auth: AuthWrapper,
  info: InfoWrapper,
  private: PrivateWrapper,
  public: PublicWrapper
}

const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route) => {
          const Wrapper = wrappers[route.wrapper]

          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Wrapper>
                  <route.component />
                </Wrapper>
              }
            />
          )
        })}

        <Route path="*" element={<NotFound />} />
      </Routes>

      <NavigationScroll />
    </BrowserRouter>
  )
}

export default Router
