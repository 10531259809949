import classNames from "classnames"
import { FC, useEffect, useState } from "react"
import { TextTheme } from "../types/text"

type Props = {
  className?: string
  progress: number
  status?: TextTheme
  size?: "xs" | "s" | "m"
}

const PercentageWidget: FC<Props> = ({
  className,
  progress,
  status = "default",
  size = "m"
}) => {
  const [value, setValue] = useState<number>(0)

  useEffect(() => {
    setValue(!isNaN(progress) ? parseInt(`${progress}`) : 0)
  }, [progress])

  return (
    <div
      className={classNames("percentage-widget", className, { [`percentage-widget--${status}`]: true, [`percentage-widget--${size}`]: true })}
      style={{ ["--progress" as string]: value }}
    >
      <svg className="percentage-widget__svg" viewBox="0 0 100 100" width="100" height="100">
        <circle cx="50" cy="50" r="44" fill="none" strokeWidth="10" strokeLinecap="round" />
      </svg>
      <div className="percentage-widget__inner">{value}%</div>
    </div>
  )
}

export default PercentageWidget
