export const prefixNumber = (number: number, digitCount = 2): string => {
  const str = `${number}`
  const diff = digitCount - str.length
  if (diff > 0) {
    let res = str
    for (let i = 0; i < diff; i++) {
      res = `0${res}`
    }
    return res
  }
  return str
}

export const formatDate = (date: string): string => {
  return date.replace(/\//g, ".")
}

export const isSameDay = (d1: Date, d2: Date): boolean => {
  return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()
}

export const isToday = (date: Date): boolean => {
  return isSameDay(date, new Date())
}

export const isYesterday = (date: Date): boolean => {
  const d = new Date(Date.now() - 24 * 60 * 60 * 1000)
  return isSameDay(d, date)
}

export const formatDateHour = (value: string): string => {
  const [date, hour] = value.split(" ")
  const [day, month, year] = date.split("/")
  const d = new Date(parseInt(year), (parseInt(month) - 1) < 0 ? 11 : parseInt(month) - 1, parseInt(day))
  return `${isToday(d)
    ? "Oggi"
    : isYesterday(d)
      ? "Ieri"
      : formatDate(date)
  } - ${hour}`
}

export const getFormattedToday = (): string => {
  const date = new Date()
  return `${prefixNumber(date.getDate())}/${prefixNumber(date.getMonth() + 1)}/${date.getFullYear()}`
}