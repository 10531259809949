export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)([\w~@#$%^&*+=`|{}:;!.?"()[\]-]?){8,}$/

export const phoneRegex = /^\d+$/

export const dateRegex = /\d\d\/\d\d\/\d\d\d\d/

export const hourRegex = /\d\d:\d\d/

export const marginRegex = /(\s|")(m(t|r|b|l|h|v)?)-((xs)|s|m|l|(xl))/
export const paddingRegex = /(\s|")(p(t|r|b|l|h|v)?)-((xs)|s|m|l|(xl))/
