import classNames from "classnames"
import { FC } from "react"
import { GetReportRowDTO } from "../types/api"
import Button from "./Button"
import Text from "./Text"

type Props = {
  data: GetReportRowDTO
}

const ReportAnswer: FC<Props> = ({
  data
}) => {
  const isNeutral = data.triggerValue === undefined

  return (
    <div className={classNames("report-answer")}>
      <div className="font-semibold uppercase">{data.title ?? "Titolo"}</div>

      {data.answerType !== "text" &&
        <Text className="mb-2" size="sm">{data.question}</Text>
      }

      {data.answerType === "boolean"
        ?
        <div className="flex gap-x-2">
          <Button
            className="report-answer__button"
            theme={isNeutral
              ? data.boolAnswer === false
                ? "blue"
                : "blue-outline"
              : data.boolAnswer === false
                ? "green"
                : "green-outline"
            }
            disabled={data.boolAnswer === true}
          >
            {data.falseLabel || "No"}
          </Button>

          <Button
            className="report-answer__button"
            theme={isNeutral
              ? data.boolAnswer === true
                ? "blue"
                : "blue-outline"
              : data.boolAnswer === true
                ? "red"
                : "red-outline"
            }
            disabled={data.boolAnswer === false}
          >
            {data.trueLabel || "Sì"}
          </Button>
        </div>
        : data.answerType === "text"
          ? <div className="text-sm">{data.textAnswer || "Nessuna comunicazione"}</div>
          : data.numAnswer
      }
    </div>
  )
}

export default ReportAnswer
