import { FC, HTMLProps } from "react"
import Row from "./Row"
import Spinner from "./Spinner"
import Text from "./Text"

interface Props extends HTMLProps<HTMLDivElement> {
  label?: string
}

const LoaderInline: FC<Props> = ({
  className,
  label,
  ...props
}) => {
  return (
    <div className={className} {...props}>
      {!!label &&
        <Text className="mb-4 text-center" size="sm">{label}</Text>
      }

      <Row justify="center">
        <Spinner />
      </Row>
    </div>
  )
}

export default LoaderInline
