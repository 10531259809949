import useAutoClose from "$hooks/useAutoClose"
import classNames from "classnames"
import { CSSProperties, FC, MouseEventHandler, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import Icon from "./Icon"

export type MenuOption = {
  label: string
  action(): void
}

type Props = {
  className?: string
  fixed?: boolean
  options: MenuOption[]
  onClick?: MouseEventHandler<HTMLDivElement>
}

const DotMenu: FC<Props> = ({
  className,
  fixed,
  options,
  onClick
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [listStyle, setListStyle] = useState<CSSProperties | null>(null)

  const container = useRef<HTMLDivElement>(null)
  const list = useRef<HTMLDivElement>(null)

  const close = (): void => {
    setOpen(false)
  }

  useAutoClose({
    action: close,
    condition: open,
    excludeTargets: [container.current]
  })

  useEffect(() => {
    if (fixed && open && container.current && list.current) {
      const containerRect = container.current.getBoundingClientRect()
      const listRect = list.current.getBoundingClientRect()

      // TODO: screen adaptable
      setListStyle({
        top: containerRect.top,
        left: containerRect.left - listRect.width
      })
    }
  }, [fixed, open])

  return (
    <div className={classNames("dot-menu", className)} ref={container}>
      <div className="dot-menu__icon" onClick={(e): void => {
        onClick?.(e)
        setOpen(open ? false : true)
      }}>
        <Icon name="dots-vertical" />
      </div>

      {open && (
        fixed
          ? createPortal(
            <div className={classNames("dot-menu__list-wrapper fixed", listStyle ? "opacity-100" : "opacity-0")} ref={list} style={listStyle || undefined}>
              <List options={options} close={close} />
            </div>,
            document.body
          )
          : <List options={options} close={close} />
      )}
    </div>
  )
}

type ListProps = {
  className?: string
  options: MenuOption[]
  close(): void
}
const List: FC<ListProps> = ({
  className,
  options,
  close
}) => {
  return (
    <ul className={classNames("dot-menu__list", className)} onClick={(e): void => e.stopPropagation()}>
      {options.map(({ label, action }, i) => (
        <li key={i} onClick={(): void => {
          action()
          close()
        }}>{label}</li>
      ))}
    </ul>
  )
}

export default DotMenu
