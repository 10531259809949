import Alert from "$components/Alert"
import Icon from "$components/Icon"
import Spinner from "$components/Spinner"
import { defaultContactTime } from "$constants/monitorings"
import useError from "$hooks/useError"
import { usePartialStore } from "$hooks/usePartialStore"
import api from "$util/api"
import { removeBOM } from "$util/string"
import classNames from "classnames"
import { ChangeEvent, FC, ReactNode, useEffect, useRef, useState } from "react"
import { useCsvStore } from "stores/csv"
import DropZone from "./DropZone"

type Props = {
  className?: string
}

const FileUploader: FC<Props> = ({
  className
}) => {
  const { file, setFile, setRows } = usePartialStore(useCsvStore, ["file", "setFile", "setRows"])
  const { onError } = useError()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ReactNode>(null)
  const reader = useRef<FileReader>(new FileReader())
  const fileInput = useRef<HTMLInputElement>(null)

  const sendFile = async(file: File): Promise<void> => {
    if (!loading) {
      setLoading(true)

      try {
        if (fileInput.current) {
          fileInput.current.value = ""
        }
        setFile(null)

        const { data: { payload } } = await api.monitorings.parseCSV(file)

        setRows(payload.rows
          .map(row => ({
            ...row,
            contactHour: row.contactHour || defaultContactTime
          }))
        )
      } catch (err) {
        onError(err)
      }

      setLoading(false)
    }
  }

  const onFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.length) {
      setFile(e.target.files[0])
    }
  }

  useEffect(() => {
    if (file) {
      reader.current.readAsText(file)
    }
  }, [file])

  useEffect(() => {
    const onFileRead = (): void => {
      setRows([])

      try {
        const content = removeBOM(reader.current.result as string)
        const lines = content.match(/[^\r\n]+/g)
        if (!lines?.length || lines.length === 1) {
          setError("Hai caricato un file vuoto")
        } else {
          setError(null)
          const blob = new Blob([lines.join("\n")], { type: "text/plain" })
          const parsed = new File([blob], "import.csv", { type: "text/plain" })
          void sendFile(parsed)
        }
      } catch (err) {
        setError(<>Errore di caricamento. Assicurarsi che il file sia formattato correttamente e che abbia l'estensione <b>.csv</b></>)
      }
    }
    const onError = (): void => {
      setError(<>Errore di caricamento. Assicurarsi che il file sia formattato correttamente e che abbia l'estensione <b>.csv</b></>)
    }
    reader.current.addEventListener("load", onFileRead)
    reader.current.addEventListener("error", onError)

    return (): void => {
      reader.current.removeEventListener("load", onFileRead)
      reader.current.removeEventListener("error", onError)
    }
  }, [])

  return (
    <div className={classNames("max-w-[600px] mx-auto", className)}>
      <label htmlFor="file" className="flex flex-col items-center justify-center border border-dashed border-grey-border rounded-lg p-8 text-center cursor-pointer">
        <input
          id="file"
          className="sr-only"
          type="file"
          accept=".csv"
          onChange={onFileChange}
          ref={fileInput}
        />

        {loading
          ? <Spinner size={48} />
          : <Icon name="file-upload" className="text-blue" size={48} />
        }

        <div className="text-xl font-bold text-blue-dark mt-4">Clicca qui per caricare il CSV</div>

        <div className="text-sm">oppure trascinalo nella finestra</div>
      </label>

      {!!error &&
        <Alert icon="alert-triangle" theme="danger" className="mt-2">
          <div className="w-full flex">
            <div className="flex-1">{error}</div>

            <div className="min-w-5 ml-2 flex items-start cursor-pointer" onClick={(): void => setError(null)}>
              <Icon name="x" />
            </div>
          </div>
        </Alert>
      }

      <DropZone
        onFileChange={(f): void => {
          setFile(f)
        }}
        onError={(err): void => {
          setError(err)
        }}
        maxLength={1}
      />
    </div>
  )
}

export default FileUploader
