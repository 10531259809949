import { ReactElement } from "react"
import Providers from "./context/Providers"
import Router from "./pages/Router"
import { ToastContainer } from "react-toastify"
import { toastDefaultProps } from "./constants/toast"
import ConfirmModal from "./components/modals/ConfirmModal"
import { confirmModal } from "$util/refs"

export default function App(): ReactElement {
  return (
    <Providers>
      <Router />

      <ToastContainer {...toastDefaultProps} />

      <ConfirmModal ref={confirmModal} />
    </Providers>
  )
}
