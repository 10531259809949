import PasswordEditModal from "$components/modals/PasswordEditModal"
import { FC, useState } from "react"
import Button from "../Button"
import Title from "../Title"

type Props = {
  className?: string
}

const AccountPassword: FC<Props> = ({
  className
}) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <div className={className}>
      <Title size="xs" className='mb-2'>Password</Title>

      <Button theme="blue-outline" onClick={(): void => setOpen(true)}>Modifica password</Button>

      <PasswordEditModal
        open={open}
        close={(): void => setOpen(false)}
      />
    </div>
  )
}

export default AccountPassword
