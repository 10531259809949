import Button from "$components/Button"
import Modal from "$components/Modal"
import PhoneConfirmationForm from "$components/form/PhoneConfirmationForm"
import PhoneVerificationForm from "$components/form/PhoneVerificationForm"
import Row from "$components/Row"
import useAuth from "$hooks/useAuth"
import { FC, useEffect, useState } from "react"
import { toast } from "react-toastify"

type Props = {
  close(): void
  open: boolean
}

const PhoneVerificationModal: FC<Props> = ({
  close,
  open
}) => {
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [phone, setPhone] = useState<string>("")

  const { updateUser } = useAuth()

  const goBack = (): void => {
    setPhone("")
    setSubmitted(false)
  }

  const onSuccess = (): void => {
    close()
    toast.success("Numero di telefono cambiato con successo")
    void updateUser()
  }

  useEffect(() => {
    if (!open) {
      setPhone("")
      setSubmitted(false)
    }
  }, [open])

  return (
    <Modal
      title="Aggiorna numero di telefono"
      footer={
        <Row justify="end" className="gap-x-2">
          <Button theme="red-outline" onClick={submitted ? goBack : close}>{submitted ? "Indietro" : "Annulla"}</Button>

          <Button type="submit" form={submitted ? "phone-confirmation-form" : "phone-verification-form"}>Conferma</Button>
        </Row>
      }
      open={open}
      close={close}
      className="phone-modal"
      closeOnOutsideClick={!submitted}
    >
      {submitted &&
        <div className="mb-2 text-sm">Inserite il codice ricevuto via SMS</div>
      }

      {!phone || !submitted
        ?
        <PhoneVerificationForm
          phone={phone}
          showSubmit={false}
          onSuccess={(phone): void => {
            setPhone(phone)
            setSubmitted(true)
          }}
        />
        :
        <PhoneConfirmationForm
          phone={phone}
          showSubmit={false}
          onSuccess={onSuccess}
        />
      }
    </Modal>
  )
}

export default PhoneVerificationModal
