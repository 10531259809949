import { useContext, createContext, FC } from "react"
import useIsInitialRender from "../../hooks/useIsInitialRender"
import { BaseTransitionProps } from "../../types/transition"
import CSSTransition from "./CSSTransition"

type TransitionContextType = {
  parent: {
    appear?: boolean
    isInitialRender?: boolean
    show?: boolean
  }
}

const TransitionContext = createContext<TransitionContextType>({
  parent: {}
})

const Transition: FC<BaseTransitionProps> = ({ show, appear, ...rest }) => {
  const { parent } = useContext(TransitionContext)
  const isInitialRender = useIsInitialRender()
  const isChild = show === undefined

  if (isChild) {
    return (
      <CSSTransition
        appear={parent.appear || !parent.isInitialRender}
        show={parent.show}
        {...rest}
      />
    )
  }

  return (
    <TransitionContext.Provider
      value={{
        parent: {
          show,
          isInitialRender,
          appear
        }
      }}
    >
      <CSSTransition appear={appear} show={show} {...rest} />
    </TransitionContext.Provider>
  )
}

export default Transition
