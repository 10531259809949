import { SelectOption } from "../types/components"

export type Sex = 1 | 2 | 4

export const sexLabels: { [key in Sex]: string } = {
  4: "Non specificato",
  2: "Femminile",
  1: "Maschile"
}

export const sexOptions: SelectOption<number>[] = Object.keys(sexLabels).reverse().map((key) => ({
  value: parseInt(key),
  label: sexLabels[parseInt(key) as Sex]
}))
