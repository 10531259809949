import classNames from "classnames"
import { createElement, FC, HTMLProps } from "react"
import { TextTheme } from "../types/text"

type TextSize = "xs" | "sm" | "md" | "lg"

export interface TextProps extends Omit<HTMLProps<HTMLElement>, "size"> {
  as?: keyof JSX.IntrinsicElements
  size?: TextSize
  theme?: TextTheme
}

const Text: FC<TextProps> = ({
  as = "div",
  children,
  className,
  size = "md",
  theme = "default",
  ...props
}) => {
  return (
    createElement(
      as,
      {
        className: classNames(
          "text",
          `text-${size}`,
          `text-${theme}`,
          className
        ),
        ...props
      },
      children
    )
  )
}

export default Text
