import { Column, Row, TableColumnsConfig } from "../types/table"
import { Device } from "../types/viewport"

export function getTableRows(data: Row[], columns: Column[]): Row[] {
  const keys = columns.map((col: Column) => col.accessor)

  return data.map((raw: Row) => {
    return keys.reduce((acc: Row, key: string) => {
      (acc as Row)[key] = raw[key]
      return acc
    }, {} as Row)
  })
}

type GetTableColsParams = {
  columns: Column[]
  config: TableColumnsConfig
  device: Device
}
export function getTableCols({ columns, config, device }: GetTableColsParams): Column[] {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return (Array.isArray(config) ? config : config[device]).map(accessor => columns.find(col => col.accessor === accessor)!).filter(col => !!col)
}
