import { useContext } from "react"
import { DataContext, DataContextType, GlobalData } from "../context/DataContext"
import { ContactMethodDetail, PhonePrefix, ProtocolDetails } from "../types/api"

type UseDataReturnType = {
  contactMethods: GlobalData<ContactMethodDetail>
  phonePrefixes: GlobalData<PhonePrefix, number>
  protocols: GlobalData<ProtocolDetails>
}

const useData = (): UseDataReturnType => {
  const { contactMethods, phonePrefixes, protocols } = useContext<DataContextType>(DataContext)

  return { contactMethods, phonePrefixes, protocols }
}

export default useData
