import useData from "$hooks/useData"
import { FC } from "react"

type Props = {
  className?: string
}

const ProtocolsTable: FC<Props> = ({
  className
}) => {
  const { protocols } = useData()

  return (
    <div className={className}>
      <table className="csv-table">
        <thead>
          <tr>
            <th>Protocollo</th>
            <th>Valore</th>
          </tr>
        </thead>

        <tbody>
          {protocols.data.map((protocol) => (
            <tr key={protocol.id}>
              <td>{protocol.name}</td>
              <td>{protocol.id}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ProtocolsTable
