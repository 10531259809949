import { ImportMonitoringWrapper } from "$types/api"
import create from "zustand"

export const csvSections = ["upload", "info"] as const
export type CSVSection = typeof csvSections[number]

type UpdateRowOptions = {
  row: ImportMonitoringWrapper
  index: number
}

type CSVStore = {
  file: File | null
  rows: ImportMonitoringWrapper[]
  activeSection: CSVSection
  setActiveSection(activeSection: CSVSection): void
  setFile(file: File | null): void
  setRows(rows: ImportMonitoringWrapper[]): void
  updateRow(options: UpdateRowOptions): void
  deleteRow(index: number): void
  reset(): void
}

export const useCsvStore = create<CSVStore>((set) => ({
  file: null,
  rows: [],
  activeSection: "upload",
  setActiveSection: (activeSection: CSVSection): void => set({ activeSection }),
  setFile: (file: File | null): void => set({ file }),
  setRows: (rows: ImportMonitoringWrapper[]): void => set({ rows }),
  updateRow: ({ row, index }: UpdateRowOptions): void => {
    set(store => {
      const rows = [...store.rows]
      rows.splice(index, 1, row)
      return { rows }
    })
  },
  deleteRow: (index: number): void => {
    set(store => {
      const rows = [...store.rows]
      rows.splice(index, 1)
      return { rows }
    })
  },
  reset: (): void => set({ file: null, rows: [] })
}))
