import classNames from "classnames"
import { FC, useEffect, useState } from "react"
import Button from "../Button"
import Row from "../Row"

type Props = {
  onSelect(value: boolean): void
  goNext(): void
  value?: boolean
  trueLabel?: string
  falseLabel?: string
}

const BoolAnswer: FC<Props> = ({
  onSelect,
  goNext,
  value,
  trueLabel
  // falseLabel
}) => {
  const [animated, setAnimated] = useState<0 | 1 | undefined>()
  const [selected, setSelected] = useState<0 | 1 | undefined>(value !== undefined ? (+!value as 0 | 1) : undefined)

  const isInverted = trueLabel?.toLowerCase() === "no"

  useEffect(() => {
    if (animated !== undefined) {
      if (selected !== undefined && selected !== animated) {
        setSelected(undefined)
      }

      setTimeout(() => {
        setSelected(animated)
        onSelect(!animated)
        goNext()
      }, 350)
    }
  }, [animated])

  return (
    <Row className="check__bool" justify="center">
      <Button
        className={classNames("button--check", {
          active: selected === (isInverted ? 1 : 0),
          animate: animated === (isInverted ? 1 : 0)
        })}
        onClick={(): void => {
          setAnimated(isInverted ? 1 : 0)
        }}
      >{"Sì"}</Button>
      <Button
        className={classNames("button--check", {
          active: selected === (isInverted ? 0 : 1),
          animate: animated === (isInverted ? 0 : 1)
        })}
        onClick={(): void => {
          setAnimated(isInverted ? 0 : 1)
        }}
      >{"No"}</Button>
    </Row>
  )
}

export default BoolAnswer
