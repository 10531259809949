type Debug = {
  api: {
    headers: boolean
    params: boolean
    response: boolean
    error: boolean
  }
}

export default {
  api: {
    headers: process.env.REACT_APP_LOG_HEADERS === "true",
    params: process.env.REACT_APP_LOG_PARAMS === "true",
    response: process.env.REACT_APP_LOG_RESPONSE === "true",
    error: process.env.REACT_APP_LOG_ERROR === "true"
  }
} as Debug
