import MonitoringEditForm from "$components/form/MonitoringEditForm"
import Modal from "$components/Modal"
import { FC } from "react"
import { GetMonitoringDTO } from "../../types/api"
import Button from "../Button"
import Row from "../Row"

type Props = {
  data: GetMonitoringDTO
  close(): void
  onSuccess(data: GetMonitoringDTO): void
  open: boolean
}

const MonitoringModal: FC<Props> = ({
  data,
  close,
  onSuccess,
  open
}) => {
  return (
    <Modal
      title="Modifica monitoraggio"
      footer={
        <Row justify="end" className="gap-x-2">
          <Button theme="red-outline" onClick={close}>Annulla</Button>

          <Button type="submit" form="monitoring-edit-form">Conferma</Button>
        </Row>
      }
      className="monitoring-modal"
      open={open}
      close={close}
      closeOnOutsideClick={false}
    >
      <MonitoringEditForm
        data={data}
        onSuccess={onSuccess}
      />
    </Modal>
  )
}

export default MonitoringModal