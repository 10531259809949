import Card from "$components/Card"
import Col from "$components/Col"
import Message from "$components/Message"
import Header from "$components/header/Header"
import Icon from "$components/Icon"
import { FC } from "react"
import { Link } from "react-router-dom"
import Button from "../components/Button"
import useAuth from "../hooks/useAuth"

const NotFound: FC = () => {
  const { user } = useAuth()

  return (
    <div className="page flex flex-col">
      {!!user &&
        <Header logoutOnly />
      }

      <div className="container flex-1 grid grid-cols-4 items-center">
        <Col lg={2} className="lg:col-start-2">
          {!user &&
            <div className="flex justify-center items-center mb-8">
              <Icon name="logo" size={50} />
            </div>
          }

          <Card>
            <Message title="Pagina non trovata">
              <div className="mt-6 flex flex-col items-center gap-2">
                {user
                  ?
                  <>
                    <Link to="/monitorings">
                      <Button>Monitoraggi</Button>
                    </Link>

                    <Link to="/patients">
                      <Button theme="blue-outline">Pazienti</Button>
                    </Link>
                  </>
                  :
                  <Link to="/login">
                    <Button>Vai al login</Button>
                  </Link>
                }
              </div>
            </Message>
          </Card>
        </Col>
      </div>
    </div>
  )
}

export default NotFound
