import { FC } from "react"
import { ProviderProps } from "../types/components"
import AuthProvider from "./AuthContext"
import DataProvider from "./DataContext"

const Providers: FC<ProviderProps> = ({ children }: ProviderProps) => {
  return (
    <AuthProvider>
      <DataProvider>
        {children}
      </DataProvider>
    </AuthProvider>
  )
}

export default Providers
