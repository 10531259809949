import { SetStateAction, useContext } from "react"
import { AuthContext, AuthContextType } from "../context/AuthContext"
import { GetCompanyDTO, GetUserDTO } from "../types/api"
import api from "../util/api"
import useError from "./useError"

type UpdateOptions<Data> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?(error: any): void
  onSuccess?(data: Data): void
}

type UseAuth = {
  user: GetUserDTO | null
  company: GetCompanyDTO | null
  setUser(user: SetStateAction<GetUserDTO | null>): void
  setCompany(user: SetStateAction<GetCompanyDTO | null>): void
  logout(): Promise<void>
  updateUser(options?: UpdateOptions<GetUserDTO>): Promise<void>
  updateCompany(options?: UpdateOptions<GetCompanyDTO>): Promise<void>
}

const useAuth = (): UseAuth => {
  const { user, company, setUser, setCompany } = useContext<AuthContextType>(AuthContext)
  const { onError } = useError()

  const updateUser = async(options?: UpdateOptions<GetUserDTO>): Promise<void> => {
    try {
      const { data: { payload } } = await api.account.getUser()

      setUser(payload)
      options?.onSuccess?.(payload)
    } catch (err) {
      options?.onError?.(err)
    }
  }
  const updateCompany = async(options?: UpdateOptions<GetCompanyDTO>): Promise<void> => {
    try {
      const { data: { payload } } = await api.account.getCompany()

      setCompany(payload)
      options?.onSuccess?.(payload)
    } catch (err) {
      options?.onError?.(err)
    }
  }

  const logout = async(): Promise<void> => {
    try {
      await api.auth.logout()
      setUser(null)
      setCompany(null)
    } catch (err) {
      onError(err)
    }
  }

  return {
    user: user as GetUserDTO | null,
    company: company as GetCompanyDTO | null,
    setCompany: setCompany as (user: SetStateAction<GetCompanyDTO | null>) => void,
    setUser: setUser as (user: SetStateAction<GetUserDTO | null>) => void,
    logout,
    updateUser,
    updateCompany
  }
}

export default useAuth
