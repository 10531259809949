import classNames from "classnames"
import { FC, ReactElement, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import Button from "../components/Button"
import Card from "../components/Card"
import Col from "../components/Col"
import Icon from "../components/Icon"
import Input from "../components/Input"
import Row from "../components/Row"
import useError from "../hooks/useError"
import { RequestPasswordDTO } from "../types/api"
import api from "../util/api"

const RequestResetPassword: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)

  const { control, formState, handleSubmit } = useForm<RequestPasswordDTO>()
  const { clearErrors, fieldErrors, onError } = useError()

  const onSubmit = async(data: RequestPasswordDTO): Promise<void> => {
    setLoading(true)

    try {
      await api.account.requestResetPassword(data)

      setSubmitted(true)
    } catch (err) {
      onError(err)
    }

    setLoading(false)
  }

  return (
    <div className="container h-full grid grid-cols-12 items-center">
      <Col lg={4} className="lg:col-start-5">
        <h1 className="flex justify-center mb-4" aria-label="Tracymed">
          <Icon name="logo" size={50} />
        </h1>

        <Card>
          <div className="text-xl font-bold text-center mb-2">{submitted
            ? "Richiesta effettuata"
            : "Recupera password"
          }</div>

          {!submitted
            ?
            <form
              className={classNames(loading && "disabled")}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }): ReactElement => {
                  return (
                    <Input
                      label="Email"
                      type="email"
                      value={value}
                      onChange={onChange}
                      error={fieldErrors?.email || formState.errors.email?.message}
                      onFocus={(): void => {
                        if (fieldErrors.email) {
                          clearErrors("email")
                        }
                      }}
                    />
                  )
                }}
              />

              <Row justify="center" className="mt-4">
                <Button type="submit">Invia</Button>
              </Row>

              <div className="mt-4 text-center text-sm">
                oppure torna al&nbsp;<Link to="/login" className="text-blue underline cursor-pointer">login</Link>
              </div>
            </form>
            :
            <>
              <div className="mb-4 text-sm text-center">A breve riceverete una mail all"indirizzo da voi indicato. Seguite le istruzioni fornite per recuperare la nuova password.</div>

              <Row justify="center">
                <Link to="/login">
                  <Button>Torna al login</Button>
                </Link>
              </Row>
            </>
          }
        </Card>
      </Col>
    </div>
  )
}

export default RequestResetPassword
