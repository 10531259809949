import classNames from "classnames"
import { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  className?: string
}

const Banner: FC<Props> = ({
  children,
  className
}) => {
  return (
    <div className={classNames("banner", className)}>
      {children}
    </div>
  )
}

export default Banner
