import { IconName } from "$types/icon"
import CSVLoad from "pages/CSVLoad"
import { ComponentType } from "react"
import Account from "../pages/Account"
import AccountComplete from "../pages/AccountComplete"
import Check from "../pages/Check"
import Dashboard from "../pages/Dashboard"
import DemoForm from "../pages/DemoForm"
import EmailConfirmation from "../pages/EmailConfirmation"
import Feedback from "../pages/Feedback"
import Login from "../pages/Login"
import Monitoring from "../pages/Monitoring"
import MonitoringAdd from "../pages/MonitoringAdd"
import Monitorings from "../pages/Monitorings"
import Patient from "../pages/Patient"
import PatientAdd from "../pages/PatientAdd"
import Patients from "../pages/Patients"
import Protocols from "../pages/Protocols"
import RequestResetPassword from "../pages/RequestResetPassword"
import ResetPassword from "../pages/ResetPassword"
import Signup from "../pages/Signup"
import SignupConfirmation from "../pages/SignupConfirmation"

import { SidebarGroup, SidebarLink, SidebarSection, WrapperType } from "../types/routes"

// USER BASED REDIRECTS
export const authRedirectPath = "/login"
export const privateRedirectPath = "/"

// TYPES
type SidebarSectionId = keyof typeof sidebarSectionsConfig
type SidebarGroupId = keyof typeof sidebarGroupsConfig
type SidebarOptions = {
  label: string
  section: SidebarSectionId
  group?: SidebarGroupId
  icon: IconName
}
type SectionsConfig = {
  [key in SidebarSectionId]: {
    label?: string
  }
}
type GroupsConfig = {
  [key in SidebarGroupId]: {
    label: string
    icon?: IconName
  }
}
type Route = {
  path: string
  component: ComponentType
  wrapper: WrapperType
  sidebarOptions?: SidebarOptions
}

export const routes: Route[] = [
  {
    component: Login,
    path: "/login",
    wrapper: "auth"
  },
  {
    component: Signup,
    path: "/signup",
    wrapper: "auth"
  },
  {
    component: Account,
    path: "/account",
    wrapper: "private"
  },
  {
    component: SignupConfirmation,
    path: "/account/confirmation/:token",
    wrapper: "public"
  },
  {
    component: EmailConfirmation,
    path: "/email-confirmation",
    wrapper: "private"
  },
  {
    component: AccountComplete,
    path: "/account-complete",
    wrapper: "private"
  },
  {
    component: RequestResetPassword,
    path: "/reset-password",
    wrapper: "auth"
  },
  {
    component: ResetPassword,
    path: "/account/pwdreset/:token",
    wrapper: "auth"
  },
  {
    component: Dashboard,
    path: "/",
    wrapper: "private",
    sidebarOptions: {
      section: "main",
      label: "Dashboard",
      icon: "apps"
    }
  },
  {
    component: Monitorings,
    path: "/monitorings",
    wrapper: "private",
    sidebarOptions: {
      section: "main",
      label: "Monitoraggi",
      icon: "device-desktop"
    }
  },
  {
    component: Monitoring,
    path: "/monitoring/:id",
    wrapper: "private"
  },
  {
    component: MonitoringAdd,
    path: "/add-monitoring",
    wrapper: "private"
  },
  {
    component: Patients,
    path: "/patients",
    wrapper: "private",
    sidebarOptions: {
      label: "Pazienti",
      section: "main",
      icon: "users"
    }
  },
  {
    component: Patient,
    path: "/patient/:id",
    wrapper: "private"
  },
  {
    component: PatientAdd,
    path: "/add-patient",
    wrapper: "private"
  },
  {
    component: CSVLoad,
    path: "/csv-upload",
    wrapper: "private",
    sidebarOptions: {
      label: "Carica CSV",
      section: "main",
      icon: "file-upload"
    }
  },
  {
    component: Check,
    path: "/check/:token",
    wrapper: "public"
  },
  {
    component: Check,
    path: "/demo/check",
    wrapper: "public"
  },
  {
    component: DemoForm,
    path: "/demo/form",
    wrapper: "public"
  },
  {
    component: Protocols,
    path: "/protocols",
    wrapper: "private",
    sidebarOptions: {
      label: "Protocolli",
      section: "info",
      icon: "book"
    }
  },
  {
    component: Feedback,
    path: "/feedback",
    wrapper: "private",
    sidebarOptions: {
      label: "Feedback/Report",
      section: "info",
      icon: "message-report"
    }
  }
]
export const sidebarSectionsConfig = {
  main: {},
  info: {
    label: "Informazioni"
  }
}
export const sidebarGroupsConfig = {
  group: {}
}
/*
  Injects types properly, we need both:
  - typed config to achieve stricter type checking inside `getSidebarSections` and `addRoute`
  - untyped config to exctract exact keys for SidebarSectionId and SidebarGroupId
*/
const sectionsConfig = sidebarSectionsConfig as SectionsConfig
const groupsConfig = sidebarGroupsConfig as GroupsConfig

// HELPERS
export const addRoute = (section: (SidebarGroup | SidebarLink)[], route: Route): void => {
  const sidebarOptions = route.sidebarOptions!
  if (sidebarOptions.group) {
    const groupId = sidebarOptions.group
    const group = section.find((item) => (item as SidebarGroup).id === sidebarOptions.group) as SidebarGroup
    if (group) {
      // add route to existing group
      group.children.push({ path: route.path, label: sidebarOptions.label })
    } else {
      // create group and add route as its first child
      section.push({
        id: groupId,
        label: groupsConfig[groupId].label,
        icon: groupsConfig[groupId].icon,
        children: [{ path: route.path, label: sidebarOptions.label }]
      } as SidebarGroup)
    }
  } else {
    // add route as SidebarLink
    section.push({
      path: route.path,
      label: sidebarOptions.label,
      icon: sidebarOptions.icon
    })
  }
}
const getSidebarSections = (): SidebarSection[] => {
  const mapping = routes.reduce((sections, route) => {
    if (route.sidebarOptions) {
      const sectionId = route.sidebarOptions.section
      if (sections[sectionId]) {
        addRoute(sections[sectionId], route)
      } else {
        sections[sectionId] = []
        addRoute(sections[sectionId], route)
      }
    }
    return sections
  }, {} as { [key in SidebarSectionId]: (SidebarGroup | SidebarLink)[] })

  return Object.keys(mapping).map((key: string) => {
    const k = key as SidebarSectionId
    return {
      label: sectionsConfig[k].label,
      items: mapping[k]
    }
  })
}

export const sidebarSections = getSidebarSections()
