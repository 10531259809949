import { FC } from "react"
import Button from "./Button"
import Icon from "./Icon"

type Props = {
  canSend?: boolean
  sendMessage(): void
}

const ReportTableSend: FC<Props> = ({
  canSend,
  sendMessage
}) => {
  return (
    canSend
      ?
      <div className="report-send">
        <Button onClick={sendMessage} size="square">
          <Icon name="send" />
        </Button>
      </div>
      : null
  )
}

export default ReportTableSend
