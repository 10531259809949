/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import debug from "../constants/debug"
import { AddToMailingListDTO, ConfirmationDTO, GenericResponse, GetMonitoringsUsingGetParams, GetPatientsUsingGetParams, NewPatientDTO, PasswordResetDTO, RegistrationDTO, GetMonitoringsResponse, GetMonitoringResponse, GetPatientsResponse, GetPatientResponse, NewMonitoringDTO, ContactMethodsResponse, GetPhonePrefixesResponse, EditPatientDTO, EditMonitoringDTO, GetProtocolsResponse, GetReportsResponse, GetReportsUsingGetParams, GetReportResponse, GetChecksResponse, AnswerChecksDTO, GetMonitoringsOverviewResponse, RequestConfirmationDTO, GetUserResponse, CreateSubscriptionDTO, CreateSubscriptionResponse, PaymentInfoDTO, GetBillingResponse, GetPlansResponse, PaymentStatusResponse, GetCouponResponse, GetSubscriptionInfoResponse, RequestPasswordDTO, NewPasswordDTO, PhoneNumberDTO, PhoneConfirmationDTO, AddToMailingListWithDemoDTO, UserReportDTO, GetPaymentMethodsResponse, AddPaymentMethodResponse, GetInvoicesResponse, GetForecastResponse, GetProtocolsStatsResponse, ParseCsvResponse, ValidateImportRowResponse, ValidateImportRowDTO, PostBatchMonitoringImportDTO, BatchImportResponse, GetCompanyResponse, GetCustomWelcomeMessageResponse, SetCustomWelcomeMessageUsingPostParams, GetRecentReportsUsingGetParams, GetRecentReportsResponse } from "../types/api"
import { LoginParams } from "../types/data"

const axiosInstance = axios.create({ baseURL: "https://api.tracymed.com", timeout: 10000 })

if (debug.api.response) {
  axiosInstance.interceptors.response.use(res => {
    console.log(`${res.config.url} response\n`, res.data)
    return res
  })
}

type SerializeObj = {
  [key: string | number]: SerializeObj | string | number
}

const serialize = (obj: SerializeObj, prefix?: string): string => {
  const str = []
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const k = prefix ? prefix + "[" + key + "]" : key
      const v = obj[key]
      str.push(v !== null
        ? Array.isArray(v)
          ? encodeURIComponent(k) + "=" + encodeURIComponent(v.join(","))
          : typeof v === "object"
            ? serialize(v, k)
            : encodeURIComponent(k) + "=" + encodeURIComponent(v)
        : encodeURIComponent(k) + "=" + encodeURIComponent(v)
      )
    }
  }
  return str.join("&")
}

const defaultConfig = { withCredentials: true }
const mergeConfig = (config: AxiosRequestConfig = {}): AxiosRequestConfig => ({ ...defaultConfig, ...config })

export default {
  account: {
    confirm: (data: ConfirmationDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GetUserResponse>> => {
      return axiosInstance.post<ConfirmationDTO, AxiosResponse<GetUserResponse>>("account/confirmation", data, mergeConfig(config))
    },
    getUser: (config?: AxiosRequestConfig): Promise<AxiosResponse<GetUserResponse>> => {
      return axiosInstance.get("account/info", mergeConfig(config))
    },
    getCompany: (config?: AxiosRequestConfig): Promise<AxiosResponse<GetCompanyResponse>> => {
      return axiosInstance.get("company", mergeConfig(config))
    },
    phone: (data: PhoneNumberDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.post<PhoneNumberDTO, AxiosResponse<GenericResponse>>("account/phone", data, mergeConfig(config))
    },
    phoneConfirmation: (data: PhoneConfirmationDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GetUserResponse>> => {
      return axiosInstance.post<PhoneConfirmationDTO, AxiosResponse<GetUserResponse>>("account/confirmphone", data, mergeConfig(config))
    },
    mailingList: (data: AddToMailingListDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.post<AddToMailingListDTO, AxiosResponse<GenericResponse>>("mailinglist", data, config)
    },
    mailingListDemo: (data: AddToMailingListWithDemoDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.post<AddToMailingListWithDemoDTO, AxiosResponse<GenericResponse>>("mailinglist/demo", data, config)
    },
    register: (data: RegistrationDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.post<RegistrationDTO, AxiosResponse<GenericResponse>>("account/registration", data, config)
    },
    requestResetPassword: (data: RequestPasswordDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.post<PasswordResetDTO, AxiosResponse<GenericResponse>>("account/requestpwd", data, config)
    },
    resetPassword: (data: NewPasswordDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.post<PasswordResetDTO, AxiosResponse<GenericResponse>>("account/newpwd", data, config)
    },
    updatePassword: (data: PasswordResetDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.post<PasswordResetDTO, AxiosResponse<GenericResponse>>("account/pwdreset", data, mergeConfig(config))
    },
    sendConfirmation: (data: RequestConfirmationDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.post<RequestConfirmationDTO, AxiosResponse<GenericResponse>>("account/sendconfirmation", data, config)
    },
    feedback: (data: UserReportDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.post<UserReportDTO, AxiosResponse<GenericResponse>>("account/userreport", data, mergeConfig(config))
    },
    getCustomWelcomeMessage: (config?: AxiosRequestConfig): Promise<AxiosResponse<GetCustomWelcomeMessageResponse>> => {
      return axiosInstance.get("account/customwelcomemessage", mergeConfig(config))
    },
    setCustomWelcomeMessage: (data: SetCustomWelcomeMessageUsingPostParams, config?: AxiosRequestConfig): Promise<AxiosResponse<GetCustomWelcomeMessageResponse>> => {
      return axiosInstance.post<UserReportDTO, AxiosResponse<GetCustomWelcomeMessageResponse>>("account/customwelcomemessage", data, mergeConfig(config))
    }
  },
  auth: {
    login: (data: LoginParams, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      const params = new URLSearchParams()
      for (const key in data) {
        params.append(key, `${(data as { [key: string]: string | boolean })[key]}`)
      }
      return axiosInstance.post<LoginParams, AxiosResponse<GenericResponse>>("login", params, mergeConfig(config))
    },
    logout: (config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.get("logout", mergeConfig(config))
    }
  },
  checks: {
    get: (token: string, config?: AxiosRequestConfig): Promise<AxiosResponse<GetChecksResponse>> => {
      return axiosInstance.get(`checks/${token}`, config)
    },
    send: (token: string, data: AnswerChecksDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.post(`checks/${token}`, data, config)
    }
  },
  data: {
    getContactMethods: (config?: AxiosRequestConfig): Promise<AxiosResponse<ContactMethodsResponse>> => {
      return axiosInstance.get("contactmethods", mergeConfig(config))
    },
    getPhonePrefixes: (config?: AxiosRequestConfig): Promise<AxiosResponse<GetPhonePrefixesResponse>> => {
      return axiosInstance.get("phoneprefixes", mergeConfig(config))
    },
    getProtocols: (config?: AxiosRequestConfig): Promise<AxiosResponse<GetProtocolsResponse>> => {
      return axiosInstance.get("protocols", mergeConfig(config))
    }
  },
  monitorings: {
    getMultiple: (data: GetMonitoringsUsingGetParams, config?: AxiosRequestConfig): Promise<AxiosResponse<GetMonitoringsResponse>> => {
      return axiosInstance.get(`monitorings?${serialize(data as SerializeObj)}`, mergeConfig(config))
    },
    getSingle: (id: string | number, config?: AxiosRequestConfig): Promise<AxiosResponse<GetMonitoringResponse>> => {
      return axiosInstance.get(`monitorings/${id}`, mergeConfig(config))
    },
    add: (data: NewMonitoringDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GetMonitoringResponse>> => {
      return axiosInstance.post<NewMonitoringDTO, AxiosResponse<GetMonitoringResponse>>("monitorings", data, mergeConfig(config))
    },
    edit: (id: number, data: EditMonitoringDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GetMonitoringResponse>> => {
      return axiosInstance.put<EditMonitoringDTO, AxiosResponse<GetMonitoringResponse>>(`monitorings/${id}`, data, mergeConfig(config))
    },
    resolveWarning: (id: string | number, config?: AxiosRequestConfig): Promise<AxiosResponse<GetMonitoringResponse>> => {
      return axiosInstance.get(`monitorings/${id}/resolvewarning`, mergeConfig(config))
    },
    parseCSV: (file: File, config?: AxiosRequestConfig): Promise<AxiosResponse<ParseCsvResponse>> => {
      const data = new FormData()
      data.append("file", file)
      return axiosInstance.post<FormData, AxiosResponse<ParseCsvResponse>>("monitorings/batchimport/parsecsv", data, mergeConfig({ ...config, headers: { "Content-Type": "multipart/form-data" } }))
    },
    validateCSVRow: (data: ValidateImportRowDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<ValidateImportRowResponse>> => {
      return axiosInstance.post<ValidateImportRowDTO, AxiosResponse<ValidateImportRowResponse>>("monitorings/batchimport/validaterow", data, mergeConfig(config))
    },
    sendCSVRows: (data: PostBatchMonitoringImportDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<BatchImportResponse>> => {
      return axiosInstance.post<PostBatchMonitoringImportDTO, AxiosResponse<BatchImportResponse>>("monitorings/batchimport/import", data, mergeConfig(config))
    }
  },
  patients: {
    getMultiple: (data: GetPatientsUsingGetParams, config?: AxiosRequestConfig): Promise<AxiosResponse<GetPatientsResponse>> => {
      return axiosInstance.get(`patients?${serialize(data as SerializeObj)}`, mergeConfig(config))
    },
    getSingle: (id: string | number, config?: AxiosRequestConfig): Promise<AxiosResponse<GetPatientResponse>> => {
      return axiosInstance.get(`patients/${id}`, mergeConfig(config))
    },
    add: (data: NewPatientDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GetPatientResponse>> => {
      return axiosInstance.post<NewPatientDTO, AxiosResponse<GetPatientResponse>>("patients", data, mergeConfig(config))
    },
    edit: (id: number, data: EditPatientDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GetPatientResponse>> => {
      return axiosInstance.put<EditPatientDTO, AxiosResponse<GetPatientResponse>>(`patients/${id}`, data, mergeConfig(config))
    },
    delete: (id: string | number, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.delete(`patients/${id}`, mergeConfig(config))
    },
    search: (searchString: string, config?: AxiosRequestConfig): Promise<AxiosResponse<GetPatientsResponse>> => {
      return axiosInstance.get(`patients/search?${serialize({ searchString })}`, mergeConfig(config))
    }
  },
  payments: {
    updateBillingData: (data: PaymentInfoDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.post<PaymentInfoDTO, AxiosResponse<GenericResponse>>("payment/billing", data, mergeConfig(config))
    },
    getBillingData: (config?: AxiosRequestConfig): Promise<AxiosResponse<GetBillingResponse>> => {
      return axiosInstance.get("payment/billing", mergeConfig(config))
    },
    checkCoupon: (coupon: string, config?: AxiosRequestConfig): Promise<AxiosResponse<GetCouponResponse>> => {
      return axiosInstance.get(`payment/coupon/${coupon}`, mergeConfig(config))
    },
    checkResult: (config?: AxiosRequestConfig): Promise<AxiosResponse<PaymentStatusResponse>> => {
      return axiosInstance.get("payment/checkresult", mergeConfig(config))
    },
    createSubscription: (data: CreateSubscriptionDTO, config?: AxiosRequestConfig): Promise<AxiosResponse<CreateSubscriptionResponse >> => {
      return axiosInstance.post<CreateSubscriptionDTO, AxiosResponse<CreateSubscriptionResponse>>("payment/subscription", data, mergeConfig(config))
    },
    deleteSubscription: (config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.post<null, AxiosResponse<GenericResponse>>("payment/subscription/end", null, mergeConfig(config))
    },
    undoDeleteSubscription: (config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.post<null, AxiosResponse<GenericResponse>>("payment/subscription/end/undo", null, mergeConfig(config))
    },
    endTrial: (config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.get("payment/endtrial", mergeConfig(config))
    },
    getSubscription: (config?: AxiosRequestConfig): Promise<AxiosResponse<GetSubscriptionInfoResponse>> => {
      return axiosInstance.get("payment/subscription", mergeConfig(config))
    },
    getPlans: (config?: AxiosRequestConfig): Promise<AxiosResponse<GetPlansResponse>> => {
      return axiosInstance.get("payment/plans", mergeConfig(config))
    },
    getPaymentMethods: (config?: AxiosRequestConfig): Promise<AxiosResponse<GetPaymentMethodsResponse>> => {
      return axiosInstance.get("payment/methods", mergeConfig(config))
    },
    addPaymentMethod: (isDefault: boolean, config?: AxiosRequestConfig): Promise<AxiosResponse<AddPaymentMethodResponse>> => {
      return axiosInstance.post<null, AxiosResponse<AddPaymentMethodResponse>>(`payment/methods?default=${isDefault}`, null, mergeConfig(config))
    },
    deletePaymentMethod: (id: string | number, config?: AxiosRequestConfig): Promise<AxiosResponse<GetPaymentMethodsResponse>> => {
      return axiosInstance.delete(`payment/methods/${id}`, mergeConfig(config))
    },
    getInvoices: (config?: AxiosRequestConfig): Promise<AxiosResponse<GetInvoicesResponse>> => {
      return axiosInstance.get("payment/invoices", mergeConfig(config))
    },
    payInvoice: (id: string | number, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.post(`payment/invoices/pay/${id}`, null, mergeConfig(config))
    }
  },
  reports: {
    getMultiple: (data: GetReportsUsingGetParams, config?: AxiosRequestConfig): Promise<AxiosResponse<GetReportsResponse>> => {
      // eslint-disable-next-line @typescript-eslint/ban-types
      return axiosInstance.get(`reports?${serialize(data as unknown as SerializeObj)}`, mergeConfig(config))
    },
    getSingle: (id: string | number, config?: AxiosRequestConfig): Promise<AxiosResponse<GetReportResponse>> => {
      return axiosInstance.get(`reports/${id}`, mergeConfig(config))
    },
    sendMessage: (id: string | number, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.get(`reports/${id}/sendimmediately`, mergeConfig(config))
    },
    resend: (id: string | number, config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.get(`reports/${id}/trytosendagain`, mergeConfig(config))
    },
    getRecents: (data: GetRecentReportsUsingGetParams, config?: AxiosRequestConfig): Promise<AxiosResponse<GetRecentReportsResponse>> => {
      // eslint-disable-next-line @typescript-eslint/ban-types
      return axiosInstance.get(`reports/recents?${serialize(data as unknown as SerializeObj)}`, mergeConfig(config))
    },
    markAsRead: (ids: (string | number)[], config?: AxiosRequestConfig): Promise<AxiosResponse<GetReportsResponse>> => {
      return axiosInstance.post(`reports/markasread/${ids.join(",")}`, null, mergeConfig(config))
    },
    unlist: (ids: (string | number)[], config?: AxiosRequestConfig): Promise<AxiosResponse<GetReportsResponse>> => {
      return axiosInstance.post(`reports/unlist/${ids.join(",")}`, null, mergeConfig(config))
    },
    unlistAll: (config?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> => {
      return axiosInstance.post("reports/unlist/all", null, mergeConfig(config))
    }
  },
  stats: {
    forecast: (config?: AxiosRequestConfig): Promise<AxiosResponse<GetForecastResponse>> => {
      return axiosInstance.get("stats/forecast", mergeConfig(config))
    },
    overview: (config?: AxiosRequestConfig): Promise<AxiosResponse<GetMonitoringsOverviewResponse>> => {
      return axiosInstance.get("stats/overview", mergeConfig(config))
    },
    protocols: (config?: AxiosRequestConfig): Promise<AxiosResponse<GetProtocolsStatsResponse>> => {
      return axiosInstance.get("stats/protocols", mergeConfig(config))
    }
  }
}

export type HandleErrorParams<T = unknown> = {
  onResponse?: (res: AxiosResponse<T>) => void
  onRequest?: (req: AxiosError<T>) => void
  onGeneric?: (err: unknown) => void
}

export function handleError<T>(err: AxiosError<T>, params: HandleErrorParams<T>): void {
  if (err.response) {
    if (debug.api.error) {
      console.warn("response error", err)
    }

    if (params.onResponse) {
      params.onResponse(err.response)
    }
  } else if (err.request) {
    if (debug.api.error) {
      console.warn("request error", err)
    }

    if (params.onRequest) {
      params.onRequest(err)
    }
  } else {
    if (debug.api.error) {
      console.warn("generic error", err)
    }

    if (params.onGeneric) {
      params.onGeneric(err)
    }
  }
}
