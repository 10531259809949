import classNames from "classnames"
import { FC } from "react"
import Icon from "./Icon"
import Row from "./Row"
import Text from "./Text"

type Props = {
  className?: string
  label: string
  onClose?(): void
}

const Chip: FC<Props> = ({
  className,
  label,
  onClose
}) => {
  return (
    <Row className={classNames("chip", className)} justify="between">
      <Text className="chip__text" size="sm" theme="white">{label}</Text>

      {!!onClose &&
        <div className="chip__icon" onClick={onClose}>
          <Icon name="circle-x" color="white" />
        </div>
      }
    </Row>
  )
}

export default Chip
