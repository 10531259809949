import useConfirmModal from "$hooks/useConfirmModal"
import classNames from "classnames"
import { FC, useEffect, useState } from "react"
import { toast } from "react-toastify"
import useError from "../hooks/useError"
import { PaymentMethodDetails } from "../types/api"
import api from "../util/api"
import PaymentIcon, { PaymentIconName } from "./PaymentIcon"
import Row from "./Row"
import Text from "./Text"

type Props = {
  className?: string
  method: PaymentMethodDetails
  onDelete?(methods: PaymentMethodDetails[]): void
  deletable?: boolean
  disabled?: boolean
}

const PaymentMethod: FC<Props> = ({
  className,
  method,
  onDelete,
  deletable = true,
  disabled = false
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled || false)

  const { onError } = useError()
  const { openModal } = useConfirmModal()

  const deletePaymentMethod = async(): Promise<void> => {
    setIsDisabled(true)

    try {
      const { data } = await api.payments.deletePaymentMethod(method.id!)

      toast.success("Metodo di pagamento eliminato con successo")

      setIsDisabled(false)

      if (onDelete) {
        onDelete(data.payload?.paymentMethods ?? [])
      }
    } catch (err) {
      onError(err)
      setIsDisabled(false)
    }
  }

  useEffect(() => {
    if (disabled !== isDisabled) {
      setIsDisabled(disabled)
    }
  }, [disabled])

  return (
    <Row className={classNames("payment-method", {
      "payment-method--disabled": isDisabled
    }, className)} justify="between">
      <Row>
        {method.brand &&
          <PaymentIcon name={method.brand.toLowerCase().replace(/ /g, "") as PaymentIconName} className="mr-4" />
        }

        <div>
          <Text size="sm" className="font-semibold">**** {method.last4digits}</Text>

          {method.expiryMonth && method.expiryYear
            ?
            <Text size="xs">Scadenza: {method.expiryMonth}/{method.expiryYear}</Text>
            : null
          }
        </div>
      </Row>

      {method.isDefault
        ? <Text size="xs" theme="info">Default</Text>
        :
        <Row>
          {/* <Text theme="info" size='xs' className="underline cursor-pointer">Imposta come default</Text> */}

          {deletable &&
            <Text className='ml-4 cursor-pointer underline' size='xs' theme='error' onClick={(): void => {
              openModal({
                description: "Eliminare questo metodo di pagamento? L'operazione è irreversibile.",
                onConfirm: (): void => {
                  void deletePaymentMethod()
                }
              })
            }}>Elimina</Text>
          }
        </Row>
      }
    </Row>
  )
}

export default PaymentMethod
