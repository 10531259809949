import classNames from "classnames"
import { FC, MouseEvent, useMemo, useRef, useState } from "react"
import { months, weekDays } from "$constants/date"
import { getSplittedDate, isLeapYear } from "$util/date"
import CalendarPicker from "./CalendarPicker"
import Icon from "./Icon"

type Props = {
  className?: string
  initialValue?: Date | string
  onSelect?(value: Date): void
  minYear?: number
  maxYear?: number
}

const Calendar: FC<Props> = ({
  className,
  initialValue = new Date(),
  onSelect,
  minYear = 1900,
  maxYear = 2030
}) => {
  const [initialDay, initialMonth, initialYear] = useRef(getSplittedDate(initialValue)).current

  const [day] = useState<number>(initialDay)
  const [month, setMonth] = useState<number>(initialMonth)
  const [year, setYear] = useState<number>(initialYear)

  const years = useMemo(() => {
    return Array.from({ length: maxYear - minYear }).map((_, i) => ({ label: minYear + i, value: minYear + i }))
  }, [minYear, maxYear])

  const monthInfo = useMemo(() => {
    const date = new Date(year, month - 1, 1)
    const isLeap = isLeapYear(year)
    const days = months[month - 1].days + (isLeap && month === 2 ? 1 : 0)

    return {
      days,
      daysArr: [...Array(days + 1).keys()].slice(1),
      firstWeekDay: date.getDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6
    }
  }, [month, year])

  const [openSelector, setOpenSelector] = useState<"month" | "year" | null>(null)

  const goPrev = (): void => {
    if (month === 1) {
      setMonth(12)
      setYear(year - 1)
    } else {
      setMonth(month - 1)
    }
  }

  const goNext = (): void => {
    if (month === 12) {
      setMonth(1)
      setYear(year + 1)
    } else {
      setMonth(month + 1)
    }
  }

  const onWrapperClick = (e: MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
  }

  return (
    <div
      className={classNames("calendar", className)}
      onClick={onWrapperClick}
    >
      <div className="calendar__header">
        <div className="calendar__arrow" onClick={goPrev}>
          <Icon name="chevron-left" />
        </div>

        <div className="calendar__header-titles">
          <div
            className="calendar__header-title"
            onClick={(): void => {
              setOpenSelector("month")
            }}
          >
            {months[month - 1].label}
          </div>

          <div
            className="calendar__header-title"
            onClick={(): void => {
              setOpenSelector("year")
            }}
          >
            {year}
          </div>
        </div>

        <div className="calendar__arrow" onClick={goNext}>
          <Icon name="chevron-right" />
        </div>
      </div>

      <div className="calendar__content">
        {openSelector === "month" &&
          <CalendarPicker
            items={months}
            selected={month}
            onSelect={(m): void => {
              setMonth(m)
              setOpenSelector(null)
            }}
          />
        }

        {openSelector === "year" &&
          <CalendarPicker
            items={years}
            selected={year}
            onSelect={(y): void => {
              setYear(y)
              setOpenSelector(null)
            }}
          />
        }

        <>
          <div className="calendar__weekdays">
            {weekDays.map((label, i) => (
              <div
                key={i}
                className="calendar__weekday"
              >{label}</div>
            ))}
          </div>

          <div className="calendar__days">
            {monthInfo.daysArr.map((label, i) => (
              <div
                key={`${year}-${month}-${label}`}
                className={classNames(
                  "calendar__day",
                  initialYear === year && initialMonth === month && day === label && "calendar__day--selected",
                  i === 0 && `col-start-${1 + monthInfo.firstWeekDay}`
                )}
                onClick={(): void => {
                  onSelect?.(new Date(year, month - 1, label))
                }}
              >{label}</div>
            ))}
          </div>
        </>
      </div>
    </div>
  )
}

export default Calendar
