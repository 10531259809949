import { createRoot } from "react-dom/client"

import "react-toastify/dist/ReactToastify.css"
import "./styles/index.css"

import App from "./App"

const root = createRoot(document.getElementById("root")!)

root.render(<App/>)
