import Input from "$components/Input"
import Textarea from "$components/Textarea"
import useError from "$hooks/useError"
import { GetMonitoringDTO } from "$types/api"
import api from "$util/api"
import { getObjDifference } from "$util/objects"
import classNames from "classnames"
import { FC, ReactElement, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"

type FormData = {
  title?: string
  notes?: string
}

type Props = {
  data: GetMonitoringDTO
  onSuccess(data: GetMonitoringDTO): void
}

const MonitoringEditForm: FC<Props> = ({
  data,
  onSuccess
}) => {
  const initialData = useRef<Pick<GetMonitoringDTO, "title" | "notes">>({ title: data.title || "", notes: data.notes || "" })
  const [loading, setLoading] = useState<boolean>(false)
  const { control, handleSubmit } = useForm<FormData>({ defaultValues: initialData.current })
  const { onError } = useError()

  const onSubmit = async(formData: FormData): Promise<void> => {
    if (!loading) {
      const diff = getObjDifference(initialData.current, formData)

      if (Object.keys(diff).length) {
        setLoading(true)

        try {
          const res = await api.monitorings.edit(data.id, formData)

          onSuccess(res.data.payload)
        } catch (err) {
          onError(err)
        }

        setLoading(false)
      } else {
        onSuccess(data)
      }
    }
  }

  return (
    <form
      className={classNames(loading && "disabled")}
      id="monitoring-edit-form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }): ReactElement => {
          return (
            <Input
              label="Titolo"
              value={value}
              onChange={onChange}
              className="mb-4"
            />
          )
        }}
      />

      <Controller
        name="notes"
        control={control}
        render={({ field: { onChange, value } }): ReactElement => {
          return (
            <Textarea
              label="Note"
              placeholder="Scrivi delle note aggiuntive"
              value={value}
              onChange={onChange}
              className="mb-4"
            />
          )
        }}
      />
    </form>
  )
}

export default MonitoringEditForm
