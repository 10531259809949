import classNames from "classnames"
import { FC, useRef, useState } from "react"
import useAuth from "$hooks/useAuth"
import useAutoClose from "$hooks/useAutoClose"
import useError from "$hooks/useError"
import Transition from "$components/transition/Transition"
import { Link } from "react-router-dom"

type Props = {
  logoutOnly?: boolean
}

const HeaderUser: FC<Props> = ({
  logoutOnly
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [loggingOut, setLoggingOut] = useState<boolean>(false)

  const { logout, user } = useAuth()
  const { onError } = useError()

  const trigger = useRef<HTMLButtonElement>(null)
  const dropdown = useRef<HTMLDivElement>(null)

  useAutoClose({
    condition: open,
    action: () => setOpen(false),
    excludeTargets: [trigger.current, dropdown.current]
  })

  const handleLogout = async(): Promise<void> => {
    if (!loggingOut) {
      setLoggingOut(true)
      try {
        await logout()
      } catch (err) {
        onError(err)
        setLoggingOut(false)
      }
    }
  }

  return (
    <div className="header__user">
      <button
        ref={trigger}
        className="header__user-toggle"
        aria-haspopup="true"
        onClick={(): void => setOpen(!open)}
        aria-expanded={open}
      >
        <div className="header__user-avatar">
          {user?.firstName?.[0]}{user?.lastName?.[0]}
        </div>

        <div className="header__user-select">
          <span className="header__user-name">{user?.studioName}</span>

          <svg className="header__user-icon" viewBox="0 0 12 12">
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
      </button>

      <Transition
        className={"header__dropdown"}
        show={open}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={(): void => setOpen(true)}
          onBlur={(): void => setOpen(false)}
        >
          <div className="header__dropdown-header">
            <div className="header__dropdown-name">{user?.firstName} {user?.lastName}</div>

            <div className="header__dropdown-role">{user?.email}</div>
          </div>

          <ul className="header__dropdown-list">
            {!logoutOnly &&
              <li>
                <Link className="header__dropdown-item" to="/account" onClick={(): void => setOpen(false)}>Impostazioni</Link>
              </li>
            }

            <li>
              <span
                className={classNames("header__dropdown-item header__dropdown-item--blue", loggingOut && "disabled")}
                onClick={handleLogout}
              >
                Logout
              </span>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  )
}

export default HeaderUser
