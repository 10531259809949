import { useEffect, useState } from "react"

type UseDrag = {
  dragging: boolean
  setDragging(dragging: boolean): void
}

const useDrag = (element: HTMLElement = document.body): UseDrag => {
  const [dragging, setDragging] = useState<boolean>(false)

  useEffect(() => {
    const onDragEnter = (e: DragEvent): void => {
      e.preventDefault()
      setDragging(true)
    }

    element.addEventListener("dragenter", onDragEnter)

    return (): void => {
      element.removeEventListener("dragenter", onDragEnter)
    }
  }, [])

  return {
    dragging,
    setDragging
  }
}

export default useDrag
