import { FC } from "react"
import Icon from "./Icon"
import Row from "./Row"
import Text from "./Text"

const MobileBanner: FC = () => {
  return (
    <Row className="mobile-banner px-16" justify="center">
      <div>
        <Row justify="center">
          <Icon name="logo" />
        </Row>

        <Text size="sm" className="mt-16 text-center">La versione mobile è in sviluppo e sarà a breve disponibile.<br/>Si invita a utilizzare la piattaforma dal vostro computer.</Text>
      </div>
    </Row>
  )
}

export default MobileBanner
