import useDrag from "$hooks/useDrag"
import { DragEventHandler, DragEvent, FC, ReactNode } from "react"
import { createPortal } from "react-dom"

type Props = {
  onFileChange(file: File): void
  onError(error: ReactNode): void
  maxLength?: number
}

const DropZone: FC<Props> = ({
  onFileChange,
  onError,
  maxLength
}) => {
  const { dragging, setDragging } = useDrag()

  const onDragEnter: DragEventHandler<HTMLDivElement> = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const onDragOver: DragEventHandler<HTMLDivElement> = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }
  const onDragLeave: DragEventHandler<HTMLDivElement> = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragging(false)
  }
  const onDrop: DragEventHandler<HTMLDivElement> = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.dataTransfer.files.length) {
      if (maxLength && e.dataTransfer.files.length > maxLength) {
        onError(<>Caricare al massimo <b>{maxLength} file</b> alla volta.</>)
      } else {
        if (e.dataTransfer.files[0].name.endsWith(".csv")) {
          onFileChange(e.dataTransfer.files[0])
        } else {
          onError(<>Formato file errato. Assicurarsi che il nome del file termini in <b>.csv</b></>)
        }
      }
    } else {
      onError(<>Errore di caricamento. Se il problema persiste caricare il file cliccando sulla zona apposita.</>)
    }
    setDragging(false)
  }

  return (
    dragging
      ? createPortal(
        <div
          className="dropzone"
          onDragEnter={onDragEnter}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDragEnd={onDragLeave}
          onDrop={onDrop}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <g className="dropzone__paths">
              <path d="M19 11v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
              <path d="M13 13l9 3l-4 2l-2 4l-3 -9"></path>
            </g>
            <line x1="3" y1="3" x2="3" y2="3.01"></line>
            <line x1="7" y1="3" x2="7" y2="3.01"></line>
            <line x1="11" y1="3" x2="11" y2="3.01"></line>
            <line x1="15" y1="3" x2="15" y2="3.01"></line>
            <line x1="3" y1="7" x2="3" y2="7.01"></line>
            <line x1="3" y1="11" x2="3" y2="11.01"></line>
            <line x1="3" y1="15" x2="3" y2="15.01"></line>
          </svg>

          <div className="dropzone__title">Rilasciare il file</div>
        </div>,
        document.body
      )
      : null
  )
}

export default DropZone
