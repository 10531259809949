type DateErrorType = "format" | "max" | "min"
type ValidateDateOptions = {
  min?: string
  max?: string
}

export const validateDate = (date: string, options?: ValidateDateOptions): DateErrorType | true => {
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) {
    return "format"
  }

  const parts = date.split("/")
  const day = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10)
  const year = parseInt(parts[2], 10)

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  if (new Date(year, 1, 29).getDate() === 29) {
    monthLength[1] = 29
  }
  if (!day || !monthLength[month - 1] || day > monthLength[month - 1]) {
    return "format"
  }

  if (options?.min || options?.max) {
    const parsed = new Date(year, month - 1, day)
    if (options.min) {
      const [minDay, minMonth, minYear] = options.min.split("/").map(v => +v)
      if (parsed < new Date(minYear, minMonth - 1, minDay)) {
        return "min"
      }
    }
    if (options.max) {
      const [maxDay, maxMonth, maxYear] = options.max.split("/").map(v => +v)
      if (parsed > new Date(maxYear, maxMonth - 1, maxDay)) {
        return "max"
      }
    }
  }

  return true
}

type HourErrorType = "format"

export const validateHour = (time: string): HourErrorType | true => {
  if (!/^\d{2}:\d{2}$/.test(time)) {
    return "format"
  }

  const parts = time.split(":")
  const hour = parseInt(parts[0], 10)
  const minutes = parseInt(parts[1], 10)

  if (isNaN(hour) || isNaN(minutes) || hour > 23 || minutes > 59) {
    return "format"
  }

  return true
}