import Button from "$components/Button"
import Modal from "$components/Modal"
import Row from "$components/Row"
import { FC } from "react"
import { GetPatientDTO } from "$types/api"
import PatientForm from "$components/form/PatientForm"

type Props = {
  data?: GetPatientDTO
  onSuccess(data: GetPatientDTO): void
  open: boolean
  close(): void
}

const PatientModal: FC<Props> = ({
  data,
  onSuccess,
  open,
  close
}) => {
  return (
    <Modal
      title={data ? "Modifica paziente" : "Aggiungi paziente"}
      footer={
        <Row justify="end" className="gap-x-4">
          <Button theme="red-outline" onClick={close}>Annulla</Button>

          <Button type="submit" form="patient-form">Conferma</Button>
        </Row>
      }
      className="patient-modal"
      open={open}
      close={close}
      closeOnOutsideClick={false}
    >
      <PatientForm onSuccess={onSuccess} data={data} showSubmit={false} />
    </Modal>
  )
}

export default PatientModal
