import PasswordEditForm from "$components/form/PasswordEditForm"
import Modal from "$components/Modal"
import { FC } from "react"
import Button from "../Button"
import Row from "../Row"

type Props = {
  close(): void
  open: boolean
}

const PasswordEditModal: FC<Props> = ({
  close,
  open
}) => {
  return (
    <Modal
      title="Aggiorna password"
      footer={
        <Row justify="end" className="gap-x-2">
          <Button theme="red-outline" onClick={close}>Annulla</Button>

          <Button type="submit" form="password-edit-form">Conferma</Button>
        </Row>
      }
      open={open}
      close={close}
      className="phone-modal"
      closeOnOutsideClick={false}
    >
      <PasswordEditForm
        showSubmit={false}
        onSuccess={close}
      />
    </Modal>
  )
}

export default PasswordEditModal
