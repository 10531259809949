import { useEffect, useRef } from "react"

const useIsInitialRender = (): boolean => {
  const isInitialRender = useRef<boolean>(true)

  useEffect(() => {
    isInitialRender.current = false
  }, [])

  return isInitialRender.current
}

export default useIsInitialRender
