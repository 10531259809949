import { FC, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Button from "../components/Button"
import Row from "../components/Row"
import { MonitoringsSortable, MonitoringsTableCellProps, monitoringsTableClickable, monitoringsTableColumns, monitoringsTableConfig, monitoringsTableStrict } from "../constants/tables/monitorings"
import usePageSize from "../hooks/usePageSize"
import { GetMonitoringsUsingGetParams, MonitoringDetails } from "../types/api"
import api from "../util/api"
import { getTableRows } from "../util/table"
import { OrderByStatus, Row as RowType, TableData } from "../types/table"
import { SelectOption } from "../types/components"
import Pagination from "../components/Pagination"
import Select from "../components/Select"
import useError from "../hooks/useError"
import Table from "../components/Table"
import Title from "../components/Title"
import Text from "../components/Text"
import MonitoringsFilters from "../components/MonitoringsFilters"
import Listing from "../components/Listing"
import useTableColumns from "../hooks/useTableColumns"
import Message from "$components/Message"
import useQuery from "$hooks/useQuery"
import { monitoringStatusOptions } from "$constants/monitorings"

type RenderItems = {
  pageSizeOption: SelectOption<number>
  orderBy: OrderByStatus<MonitoringsSortable>
  protocol: SelectOption<number>[]
  statuses: SelectOption<number>[]
  showArchived: boolean
}

const Monitorings: FC = () => {
  const queryParams = useQuery()
  const [mounted, setMounted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [updating, setUpdating] = useState<boolean>(false)

  // params
  const { pageSize, setPageSize, pageSizeOption, pageSizeOptions } = usePageSize()
  const [pagination, setPagination] = useState<{ current: number, total: number }>({ current: 1, total: 1 })
  const [orderBy, setOrderBy] = useState<OrderByStatus<MonitoringsSortable>>({ orderBy: "lastReportReceived", direction: "desc" })
  const [protocol, setProtocol] = useState<SelectOption<number>[]>([])
  const [statuses, setStatuses] = useState<SelectOption<number>[]>(((): SelectOption<number>[] => {
    const param = +(queryParams?.get("monitoringStatus") as string)
    const option = monitoringStatusOptions.find(s => s.value === param)
    return param
      ? option
        ? [option]
        : []
      : [] as SelectOption<number>[]
  })())
  const [showArchived, setShowArchived] = useState<boolean>(false)
  const [params, setParams] = useState<GetMonitoringsUsingGetParams>({
    page: pagination.current,
    pageSize,
    ...orderBy,
    statuses: statuses.map(s => s.value)
  })

  // data
  const [data, setData] = useState<TableData<MonitoringDetails>>({ raw: [], table: [] })
  const tableColumns = useTableColumns({ columns: monitoringsTableColumns, config: monitoringsTableConfig })

  // render
  const [renderItems, setRenderItems] = useState<RenderItems>({ pageSizeOption, orderBy, protocol, statuses, showArchived })

  // hooks
  const { onError } = useError()
  const navigate = useNavigate()

  const getMonitorings = async(): Promise<void> => {
    if (mounted) {
      setUpdating(true)
    }

    try {
      const res = await api.monitorings.getMultiple(params)

      setData({
        raw: res.data.payload.monitorings,
        table: getTableRows(
          // eslint-disable-next-line @typescript-eslint/ban-types
          res.data.payload.monitorings as unknown as RowType[],
          monitoringsTableColumns
        )
      })
      setPagination({ current: res.data.payload.currentPage, total: res.data.payload.totalPages })
    } catch (err) {
      onError(err)
    } finally {
      if (mounted) {
        setUpdating(false)
        setRenderItems({ pageSizeOption, orderBy, protocol, statuses, showArchived })
      } else {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (mounted) {
      setParams(p => {
        return { ...p, ...orderBy, showArchived }
      })
    }
  }, [orderBy])

  useEffect(() => {
    if (mounted) {
      setParams(p => {
        const params = { ...p, page: 1, pageSize, showArchived }
        if (protocol) {
          params.protocol = protocol.map(({ value }) => value)
        }
        if (statuses) {
          params.statuses = statuses.map(({ value }) => value)
        }
        return params
      })
    }
  }, [showArchived, protocol, statuses, pageSize])

  useEffect(() => {
    if (mounted) {
      void getMonitorings()
    }
  }, [params])

  useEffect(() => {
    void (async(): Promise<void> => {
      await getMonitorings()
    })()

    setMounted(true)
  }, [])

  return (
    <>
      <Row className="heading mb-8" justify="between">
        <Title>Monitoraggi</Title>

        <Link to="/add-monitoring">
          <Button>Crea Monitoraggio</Button>
        </Link>
      </Row>

      <Listing loading={loading} updating={updating}>
        <MonitoringsFilters
          protocol={renderItems.protocol}
          setProtocol={setProtocol}
          statuses={renderItems.statuses}
          setStatuses={setStatuses}
          showArchived={renderItems.showArchived}
          setShowArchived={setShowArchived}
        />

        {data.table.length > 0
          ?
          <>
            <Table<MonitoringDetails[], MonitoringsTableCellProps>
              className="my-8"
              columns={tableColumns}
              data={data.table}
              cellData={data.raw}
              cellProps={{
                onMonitoringEdit: (): void => {
                  void getMonitorings()
                }
              }}
              clickableColumns={monitoringsTableClickable}
              strictColumns={monitoringsTableStrict}
              activeCol={{
                id: renderItems.orderBy.orderBy,
                value: renderItems.orderBy.direction
              }}
              onColumnClick={(id): void => {
                setOrderBy({
                  orderBy: id as MonitoringsSortable,
                  direction: params.orderBy ===  id
                    ? params.direction === "desc" ? "asc" : "desc"
                    : "asc"
                })
              }}
              onRowClick={(i: number): void => {
                navigate(`/monitoring/${data.raw[i].id}`)
              }}
            />

            <Row className="table__pagination" justify="between">
              <Row>
                <Select
                  options={pageSizeOptions}
                  value={renderItems.pageSizeOption}
                  onChange={(option): void => {
                    setPageSize((option as SelectOption<number>).value)
                  }}
                  menuPlacement="top"
                />

                <Text size="sm" className="ml-4">Risultati per pagina</Text>
              </Row>

              {pagination.total > 1 &&
                <Pagination current={pagination.current} total={pagination.total} onChange={(page: number): void => setParams({ ...params, page })} />
              }
            </Row>
          </>
          :
          <Message className="pl-4 mt-6" icon="screen-share-off" title="Nessun monitoraggio trovato" />
        }
      </Listing>
    </>
  )
}

export default Monitorings
