import classNames from "classnames"
import { forwardRef, HTMLProps, ReactNode, useEffect, useImperativeHandle, useRef, useState } from "react"
import { Props as SelectProps } from "react-select"
import useDebounce from "$hooks/useDebounce"
import useError from "$hooks/useError"
import { GetPatientDTO, PatientDetail } from "$types/api"
import { SelectOption } from "$types/components"
import api from "$util/api"
import Button from "./Button"
import Label from "./Label"
import Select from "./Select"

interface Props extends Omit<SelectProps, "onChange" | "isSearchable" | "components" | "styles"> {
  onChange(id: number): void
  onAddClick?(): void
  isPreselected: boolean
  patientData: GetPatientDTO | PatientDetail | null
  error?: ReactNode
  errorProps?: HTMLProps<HTMLDivElement>
}

export type PatientSelectRef = {
  setSelected(data: GetPatientDTO | PatientDetail): void
}

const PatientSelect = forwardRef<PatientSelectRef, Props>(({
  className,
  onChange,
  onAddClick,
  isPreselected,
  patientData,
  error,
  errorProps,
  ...props
}, ref) => {
  const [search, setSearch] = useState<string>("")
  const debouncedSearch = useDebounce(search, 500)
  const [patients, setPatients] = useState<{ data: (PatientDetail | GetPatientDTO)[], options: SelectOption[] }>({ data: [], options: [] })
  const [preselectedSet, setPreselectedSet] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { onError } = useError()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectRef = useRef<any>()

  const setSelected = (data: GetPatientDTO | PatientDetail): void => {
    const { id, firstName, lastName } = data
    const option = { value: id, label: `${firstName} ${lastName}` }
    setPatients({
      data: [data],
      options: [option]
    })
    selectRef.current.setValue(option)
  }

  useImperativeHandle(ref, () => {
    return {
      setSelected
    }
  })

  useEffect(() => {
    if (debouncedSearch) {
      void (async(): Promise<void> => {
        setLoading(true)
        try {
          const res = await api.patients.search(debouncedSearch)

          setPatients({
            data: res.data.payload.patients,
            options: res.data.payload.patients.map(({ firstName, lastName, id }) => ({ label: `${lastName} ${firstName}`, value: id }))
          })
        } catch (err) {
          onError(err)
        } finally {
          setLoading(false)
        }
      })()
    }
  }, [debouncedSearch])

  useEffect(() => {
    if (isPreselected && patientData && !preselectedSet) {
      setSelected(patientData)
      setPreselectedSet(true)
    }
  }, [patientData])

  return (
    <div className={classNames("patient-select", { "select--error": !!error }, className)}>
      <Label>Seleziona paziente</Label>

      <div className="patient-select__container">
        <Select
          className="w-full md:w-auto md:flex-1"
          isSearchable
          options={patients.options}
          onChange={(option): void => {
            const id = (option as SelectOption<number>)?.value
            onChange(id)
          }}
          placeholder="Cerca paziente"
          isClearable
          onInputChange={(v): void => {
            setSearch(v)
            if (!v) {
              setPatients({ data: [], options: [] })
            }
          }}
          noOptionsMessage={(): ReactNode => (
            search === ""
              ? "Inserire nome paziente"
              : debouncedSearch !== search
                ? "Ricerca in corso"
                : loading
                  ? "Ricerca in corso"
                  : patients.options.length
                    ? "Ricerca in corso"
                    : "Nessun paziente trovato"
          )}
          selectRef={selectRef}
          {...props}
        />

        {onAddClick &&
          <>
            <span className="w-full md:w-auto my-2 md:mx-2 text-xs text-center">oppure</span>

            <Button className="w-full md:w-auto whitespace-nowrap" onClick={onAddClick}>Nuovo paziente</Button>
          </>
        }
      </div>

      {error
        ?
        <div
          {...errorProps}
          className={classNames("input__error", errorProps?.className)}
        >
          {error}
        </div>
        : null
      }
    </div>
  )
})

export default PatientSelect
