import { ReactElement, ReactNode } from "react"
import { Navigate } from "react-router-dom"
import { privateRedirectPath } from "../../constants/routes"
import useAuth from "../../hooks/useAuth"

type Props = {
  children: ReactNode
}

export default function AuthWrapper({ children }: Props): ReactElement {
  const { user } = useAuth()

  return (
    <>
      {user
        ? <Navigate to={privateRedirectPath} replace/>
        : children
      }
    </>
  )
}
