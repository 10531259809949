import { FC } from "react"
import { GetChecksDTO } from "../../types/api"
import Title from "../Title"
import Text from "../Text"
import Row from "../Row"
import Icon from "../Icon"
import Card from "$components/Card"
import Col from "$components/Col"

type Props = {
  data: GetChecksDTO
}

const CheckEnd: FC<Props> = ({
  data
}) => {
  return (
    <div className="container h-full grid grid-cols-12 items-center">
      <Col lg={4} className="lg:col-start-5">
        <Row className="mb-8" justify="center">
          <Icon name="logo" size={50} />
        </Row>

        <Card>
          <Title size="s" className="mb-4" align="center">Grazie per il<br/>Vostro contributo!</Title>

          <Row className="check__end-icon mb-4 text-green" justify="center">
            <Icon name="circle-check" />
          </Row>

          <Text className="text-center" size="sm">
            Le risposte del questionario sono state inviate<br/>
            al Vostro studio dentistico di riferimento.<br/><br/>
            In caso di urgenza verrete contattati quanto prima.<br/><br/>

            {data.nextCheck
              ?
              <>
                Vi ricordiamo che il prossimo questionario è programmato<br/>
                per il <Text as="span" className="font-semibold" size="sm">{data.nextCheck}</Text>.
              </>
              :
              <>
                Avete concluso il monitoraggio. Vi ringraziamo per la collaborazione<br/>
                ed il contributo offerto. Cordiali saluti
              </>
            }
          </Text>
        </Card>
      </Col>
    </div>
  )
}

export default CheckEnd
