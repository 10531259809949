import { SelectOption } from "$types/components"
import { TextTheme } from "../types/text"

export type MonitoringStatus = 1 | 2 | 3

// monitoring
export const monitoringStatusLabels = {
  1: "Regolare",
  2: "Intervento richiesto",
  3: "In ritardo"
}
export const monitoringStatusTypes: { [key in MonitoringStatus]: TextTheme } = {
  1: "success",
  2: "error",
  3: "warning"
}
export const monitoringStatusTextThemes: { [key in MonitoringStatus]: TextTheme } = {
  1: "success",
  2: "error",
  3: "warning"
}
export const monitoringWidgetStatuses: { [key in MonitoringStatus]: TextTheme } = {
  1: "success",
  2: "error",
  3: "warning"
}

// report
export type ReportStatus = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

export const reportStatusLabels = {
  1: "Regolare",
  2: "Intervento richiesto",
  3: "In ritardo",
  4: "Nullo",
  5: "Inviato",
  6: "Invio programmato",
  7: "Annullato",
  8: "Errore invio",
  9: "Invio in corso"
}
export const reportStatusTypes: { [key in ReportStatus]: TextTheme } = {
  1: "success",
  2: "error",
  3: "warning",
  4: "warning",
  5: "default",
  6: "default",
  7: "default",
  8: "error",
  9: "default"
}

// selects
export const monitoringStatusOptions: SelectOption<number>[] = [
  { label: "Regolare", value: 1 },
  { label: "Urgente", value: 2 },
  { label: "Ritardo", value: 3 }
]

// default values
export const defaultContactTime = "18:00"
