import classNames from "classnames"
import { FC, ReactNode } from "react"
import Card from "./Card"
import Icon from "./Icon"
import Row from "./Row"
import Text from "./Text"

type Props = {
  active: boolean
  setActive?(): void
  className?: string
  disabled?: boolean
  title: string
  value?: string
  children?: ReactNode
}

const FeedbackSection: FC<Props> = ({
  active,
  setActive,
  children,
  className,
  title,
  value
}) => {
  return (
    <Card className={classNames("feedback-section", className)}>
      <Row>
        <Text className="feedback-section__title font-semibold">{title}</Text>

        {!active && !!value &&
          <>
            <Text className="ml-2 font-semibold" theme="info">{value}</Text>

            <div className="flex items-start" onClick={setActive}>
              <Icon className="feedback-section__edit ml-2" name="edit" />
            </div>
          </>
        }
      </Row>

      {active &&
        <div className="feedback-section__content">{children}</div>
      }
    </Card>
  )
}

export default FeedbackSection
