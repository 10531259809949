import { defaultPhonePrefix } from "$constants/data"
import classNames from "classnames"
import { FC, ReactElement, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { phoneRegex } from "../../constants/regex"
import useData from "../../hooks/useData"
import useError from "../../hooks/useError"
import { GetPatientDTO } from "../../types/api"
import api from "../../util/api"
import { ContactMethodPhoneData } from "../ContactMethod"
import Input from "../Input"

type Data = {
  phonePrefix: number
  phone: string
}

type Props = {
  id: number
  data?: ContactMethodPhoneData
  onSuccess(data: GetPatientDTO): void
}

const PhoneForm: FC<Props> = ({
  id,
  data,
  onSuccess
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  const { phonePrefixes } = useData()
  const { onError, fieldErrors } = useError()
  const { control, formState, handleSubmit } = useForm<Data>({ mode: "onChange", defaultValues: {
    phonePrefix: data?.prefix || phonePrefixes.options[0].value || defaultPhonePrefix,
    phone: data?.phone || ""
  } })

  const onSubmit = async(data: Data): Promise<void> => {
    if (!loading) {
      setLoading(true)

      try {
        const res = await api.patients.edit(id, data)

        onSuccess(res.data.payload)
      } catch (err) {
        onError(err)
      }

      setLoading(false)
    }
  }

  return (
    <form
      id="phone-form"
      className={classNames(loading && "disabled")}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <Controller
        name="phone"
        control={control}
        rules={{
          pattern: {
            value: phoneRegex,
            message: "Campo non valido, inserire solo numeri senza spazi o prefisso"
          },
          required: {
            value: true,
            message: "Campo obbligatorio"
          }
        }}
        render={({ field: { onChange, value } }): ReactElement => {
          return (
            <Input
              type="tel"
              value={value}
              onChange={onChange}
              autoComplete="new-password"
              error={fieldErrors.phone || formState.errors.phone?.message}
            />
          )
        }}
      />
    </form>
  )
}

export default PhoneForm
