import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react"
import { ConfirmModalOptions } from "$types/components"
import Button from "../Button"
import Modal from "../Modal"
import Row from "../Row"

export type ConfirmModalRef = {
  openModal(modalOptions: ConfirmModalOptions): void
}

const ConfirmModal = forwardRef<ConfirmModalRef>((_props, ref) => {
  const [open, setOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<ConfirmModalOptions | null>(null)

  useImperativeHandle(ref, () => ({
    openModal: (options: ConfirmModalOptions): void => {
      setOptions(options)
    }
  }))

  const close = useCallback((): void => {
    setOpen(false)
  }, [])

  const onClose = useCallback(() => {
    setOptions(null)
  }, [])

  useEffect(() => {
    if (options) {
      setOpen(true)
    }
  }, [options])

  return (
    <Modal
      open={open}
      close={close}
      title={options?.title}
      onClose={onClose}
      footer={
        <Row justify="end">
          <Button theme={options?.cancelButtonTheme || "red-outline"} className="mr-2" onClick={close}>
            {options?.cancelLabel || "Annulla"}
          </Button>

          <Button theme={options?.confirmButtonTheme || "blue"} onClick={(): void => {
            options?.onConfirm()
            close()
          }}>
            {options?.confirmLabel || "Conferma"}
          </Button>
        </Row>
      }
    >
      {options?.description}
    </Modal>
  )
})

export default ConfirmModal
