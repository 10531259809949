import classNames from "classnames"
import { FC, useEffect, useState } from "react"
import Icon from "./Icon"
import Row, { RowProps } from "./Row"
import Text, { TextProps } from "./Text"

type Props = {
  className?: string
  value: string
  label?: string
  textProps?: TextProps
  rowProps?: Omit<RowProps, "className">
  iconClassName?: string
}

const CopyToClipboard: FC<Props> = ({
  className,
  value,
  label,
  textProps,
  rowProps,
  iconClassName
}) => {
  const [copied, setCopied] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const copy = async(): Promise<void> => {
    try {
      await navigator.clipboard.writeText(value)
      setCopied(true)
    } catch (err) {
      setError(true)
    }
  }

  useEffect(() => {
    if (copied || error) {
      const id = setTimeout(() => {
        if (copied) {
          setCopied(false)
        }
        if (error) {
          setError(false)
        }
      }, 2000)

      return (): void => {
        clearTimeout(id)
      }
    }
  }, [copied, error])

  return (
    <Row className={classNames("copy-clipboard", className)} {...rowProps}>
      <Text {...textProps}>{label ? label : value}</Text>

      <div
        className={classNames("copy-clipboard__svg ml-4", iconClassName, {
          "copy-clipboard__svg--copied": copied,
          "copy-clipboard__svg--error": error
        })}
        onClick={(e): void => {
          e.stopPropagation()
          if (!copied && !error) {
            void copy()
          }
        }}
      >
        <Icon name={copied ? "check" : error ? "x" : "copy"} />
      </div>
    </Row>
  )
}

export default CopyToClipboard
