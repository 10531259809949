import { FC, ReactNode, SetStateAction } from "react"
import classNames from "classnames"
import Row from "./Row"

export type Props = {
  checked: boolean
  className?: string
  onChange?(checked: SetStateAction<boolean>): void
  neutral?: boolean
  label?: ReactNode
}

const Switch: FC<Props> = ({
  checked,
  className,
  onChange,
  neutral,
  label
}: Props) => {
  const onClick = (): void => {
    if (onChange) {
      onChange(!checked)
    }
  }

  return (
    <Row className={classNames("switch__container", className)} onClick={onClick}>
      <div className={classNames("switch", {
        ["switch--checked"]: checked,
        ["switch--neutral"]: neutral
      })} />

      {!!label &&
        <div className="ml-2 text-sm">{label}</div>
      }
    </Row>
  )
}

export default Switch
