import Card from "$components/Card"
import Icon from "$components/Icon"
import useQuery from "$hooks/useQuery"
import { IconName } from "$types/icon"
import classNames from "classnames"
import { ComponentType, FC, useState } from "react"
import Col from "../Col"
import AccountBilling from "./AccountBilling"
import AccountGeneral from "./AccountGeneral"
import AccountPayments from "./AccountPayments"
import AccountPlans from "./AccountPlans"
import AccountSecurity from "./AccountSecurity"
// import MonitoringsSettings from "./MonitoringsSettings"

type SectionId = "general" | "security"| "monitorings" | "plans" | "billing" | "payments"
type Section = {
  id: SectionId
  label: string
  component: ComponentType
  icon: IconName
}
type SectionsMap = {
  [key in SectionId]: Section
}

const sections: Section[] = [
  {
    id: "general",
    label: "Generale",
    component: AccountGeneral,
    icon: "user"
  },
  {
    id: "security",
    label: "Sicurezza",
    component: AccountSecurity,
    icon: "shield-lock"
  },
  // {
  //   id: "monitorings",
  //   label: "Monitoraggi",
  //   component: MonitoringsSettings,
  //   icon: "device-desktop-analytics"
  // },
  {
    id: "plans",
    label: "Piani",
    component: AccountPlans,
    icon: "layout-cards"
  },
  {
    id: "billing",
    label: "Fatturazione",
    component: AccountBilling,
    icon: "file-invoice"
  },
  {
    id: "payments",
    label: "Metodi di pagamento",
    component: AccountPayments,
    icon: "credit-card"
  }
]
const sectionsMap = sections.reduce((sections: SectionsMap, section: Section) => {
  sections[section.id] = section
  return sections
}, {} as SectionsMap)

const AccountTabs: FC = () => {
  const query = useQuery()
  const queryActive = query.get("active")
  const [activeSection, setActiveSection] = useState<SectionId>(queryActive
    ? queryActive in sectionsMap
      ? queryActive as SectionId
      : "general"
    : "general"
  )

  const Component = sectionsMap[activeSection].component

  return (
    <Card className="account-tabs">
      <div className="grid grid-cols-12">
        <Col lg={3} className="account-tabs__nav">
          <ul className="account-tabs__list">
            {sections.map((section) => (
              <li
                key={section.id}
                className={classNames("account-tabs__item", {
                  "account-tabs__item--active": activeSection === section.id
                })}
                onClick={(): void => setActiveSection(section.id)}
              >
                <Icon name={section.icon} size={24} />

                <span className="account-tabs__item-label">{section.label}</span>
              </li>
            ))}
          </ul>
        </Col>

        <Col lg={9} className="account-tabs__section">
          <Component />
        </Col>
      </div>
    </Card>
  )
}

export default AccountTabs
