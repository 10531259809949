import Button from "$components/Button"
import Message from "$components/Message"
import Spinner from "$components/Spinner"
import useError from "$hooks/useError"
import { ReportDetail } from "$types/api"
import api from "$util/api"
import classNames from "classnames"
import { FC, RefObject, useState } from "react"
import PatientMessage from "./PatientMessage"

type Props = {
  messages?: ReportDetail[]
  onMessageRead(reportId: number): void
  onReadAll(): void
  onScroll?(): void
  listRef?: RefObject<HTMLUListElement>
}

const PatientsMessages: FC<Props> = ({
  messages,
  onMessageRead,
  onReadAll,
  onScroll,
  listRef
}) => {
  const [unlistingAll, setUnlistingAll] = useState<boolean>(false)
  const { onError } = useError()

  const unlistAll = async(): Promise<void> => {
    if (!unlistingAll) {
      setUnlistingAll(true)
      try {
        await api.reports.unlistAll()
        onReadAll()
      } catch (err) {
        onError(err)
      }
      setUnlistingAll(false)
    }
  }

  const unlistMessage = async(reportId: number): Promise<void> => {
    try {
      await api.reports.unlist([reportId])
      onMessageRead(reportId)
    } catch (err) {
      onError(err)
    }
  }

  return (
    messages?.length
      ?
      <>
        <ul className={classNames("flex-1 flex flex-col overflow-y-auto max-h-[500px] md:max-h-none -mx-5 px-5", unlistingAll && "opacity-50 pointer-events-none")} ref={listRef} onScroll={onScroll}>
          {messages?.map((message) => (
            <PatientMessage
              key={message.id}
              message={message}
              onRead={unlistMessage}
            />
          ))}
        </ul>

        {messages.length > 1 &&
          <div className="flex justify-center pt-4">
            <div className="w-[200px]">
              <Button
                size="full"
                className="px-0"
                onClick={unlistAll}
              >
                {
                  unlistingAll
                    ? <Spinner color="white" />
                    : "Nascondi tutti"
                }
              </Button>
            </div>
          </div>
        }
      </>
      :
      <Message
        title="Nessuna nuova comunicazione"
        icon="message-off"
        className="my-auto"
      />
  )
}

export default PatientsMessages
