import classNames from "classnames"
import { FC, HTMLProps, ReactNode, RefObject } from "react"

export interface CardProps extends HTMLProps<HTMLDivElement> {
  heading?: ReactNode
  headingProps?: HTMLProps<HTMLDivElement>
  contentProps?: HTMLProps<HTMLDivElement>
  contentRef?: RefObject<HTMLDivElement>
}

const Card: FC<CardProps> = ({
  children,
  className,
  heading,
  headingProps,
  contentProps,
  contentRef,
  ...props
}) => {
  return (
    <div
      className={classNames("card", className)}
      {...props}
    >
      {heading
        ?
        <div
          {...headingProps}
          className={classNames(
            "card-heading",
            headingProps?.className
          )}
        >
          {heading}
        </div>
        : null
      }

      <div {...contentProps} className={classNames("card-content", contentProps?.className)} ref={contentRef}>
        {children}
      </div>
    </div>
  )
}

export default Card
