import Card from "$components/Card"
import ForecastChart from "$components/charts/ForecastChart"
import Title from "$components/Title"
import { FC, useEffect, useRef, useState } from "react"
import StatusWidgets from "../components/dashboard/StatusWidgets"
import Row from "../components/Row"
import Spinner from "../components/Spinner"
import { GetForecastDTO, GetMonitoringOverviewDTO, GetRecentReportsResponse, ReportDetail } from "../types/api"
import api from "../util/api"
import Info from "$components/Info"
import PatientsMessages from "$components/dashboard/PatientsMessages"
import { AxiosResponse } from "axios"

const scrollThreshold = 10

type ListSizes = {
  height: number
  scrollHeight: number
}

const Dashboard: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [updatingMessages, setUpdatingMessages] = useState<boolean>(false)

  const [overview, setOverview] = useState<GetMonitoringOverviewDTO>()
  const [messages, setMessages] = useState<ReportDetail[]>()
  const [forecast, setForecast] = useState<GetForecastDTO["forecast"]>()

  const [messagesMaxHeight, setMessagesMaxHeight] = useState<number>()
  const [messagesPage, setMessagesPage] = useState<number>(1)

  const messagesNextPage = useRef<number>(1)
  const leftBoxRef = useRef<HTMLDivElement>(null)
  const listSizes = useRef<ListSizes>({ height: 0, scrollHeight: 0 })
  const messagesListRef = useRef<HTMLUListElement>(null)
  const reachedEnd = useRef<boolean>(false)

  const getMessages = async(): Promise<AxiosResponse<GetRecentReportsResponse>> => {
    return api.reports.getRecents({
      page: messagesPage,
      pageSize: 25,
      listed: true,
      withMessage: true
    })
  }

  // left box observer
  useEffect(() => {
    if (!loading && leftBoxRef.current) {
      const leftBoxObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          setMessagesMaxHeight(entry.contentRect.height)
        })
      })
      leftBoxObserver.observe(leftBoxRef.current)
      setMessagesMaxHeight(leftBoxRef.current.clientHeight)

      return (): void => {
        leftBoxObserver.disconnect()
      }
    }
  }, [loading])

  // initial list sizes
  useEffect(() => {
    if (messagesMaxHeight && messagesListRef.current) {
      listSizes.current = {
        height: messagesListRef.current.clientHeight || 0,
        scrollHeight: messagesListRef.current.scrollHeight || 0
      }
    }
  }, [messagesMaxHeight])

  // messages observer
  useEffect(() => {
    if (!loading && messagesListRef.current) {
      const listObserver = new MutationObserver(() => {
        listSizes.current = {
          height: messagesListRef.current?.clientHeight || 0,
          scrollHeight: messagesListRef.current?.scrollHeight || 0
        }
      })
      listObserver.observe(messagesListRef.current, { childList: true })

      return (): void => {
        listObserver.disconnect()
      }
    }
  }, [loading])

  // init
  useEffect(() => {
    void (async(): Promise<void> => {
      const results = await Promise.allSettled([
        api.stats.overview(),
        getMessages(),
        api.stats.forecast()
      ])

      if (results[0].status === "fulfilled") {
        setOverview(results[0].value.data.payload)
      }
      if (results[1].status === "fulfilled") {
        const data = results[1].value.data.payload
        setMessages(data.reports)
        messagesNextPage.current = data.totalPages > messagesPage ? messagesPage + 1 : messagesPage
      }
      if (results[2].status === "fulfilled") {
        if (results[2].value.data.payload.forecast) {
          setForecast(results[2].value.data.payload.forecast)
        }
      }

      setLoading(false)
    })()
  }, [])

  // messages scroll update
  useEffect(() => {
    if (!loading && !updatingMessages) {
      setUpdatingMessages(true)

      void (async(): Promise<void> => {
        try {
          const { data: { payload } } = await getMessages()

          setMessages(messages => ([...messages || [], ...payload.reports]))
          messagesNextPage.current = payload.totalPages > messagesPage ? messagesPage + 1 : messagesPage
        } catch {}

        setUpdatingMessages(false)
      })()
    }
  }, [messagesPage])

  const onScroll = (): void => {
    const y = messagesListRef.current?.scrollTop || 0
    if (y + listSizes.current.height + scrollThreshold >= listSizes.current.scrollHeight) {
      if (!reachedEnd.current) {
        reachedEnd.current = true
        if (!loading && !updatingMessages && messagesNextPage.current !== messagesPage) {
          setMessagesPage(p => p + 1)
        }
      }
    } else {
      reachedEnd.current = false
    }
  }

  const onMessageRead = (reportId: number): void => {
    setMessages(messages => {
      const index = messages?.findIndex(m => m.id === reportId)
      if (!isNaN(index as number)) {
        const result = [...messages || []]
        result.splice(index!, 1)
        return result
      }
      return messages
    })
  }
  const onReadAll = (): void => {
    setMessages([])
  }

  return (
    <>
      {loading
        ?
        <Row justify="center">
          <Spinner />
        </Row>
        :
        <>
          <div className="heading pb-10">
            <Title>Dashboard</Title>
          </div>

          <div className="flex flex-col lg:flex-row gap-y-10 lg:gap-y-0 lg:gap-x-5 items-start">
            <div className="w-full lg:w-[67%] flex flex-col gap-y-10 lg:gap-y-5" ref={leftBoxRef}>
              {/* OVERVIEW */}
              {overview
                ?
                <Card
                  className="w-full"
                  heading={
                    <div className="flex items-center gap-2">
                      Panoramica monitoraggi
                    </div>
                  }
                >
                  <StatusWidgets data={overview} />
                </Card>
                : null
              }

              {/* FORECAST CHART */}
              <Card
                className="w-full"
                heading={
                  <div className="flex items-center gap-2">
                    Forecast settimanale

                    <Info text="Numero di risposte attese nei prossimi 7 giorni" />
                  </div>
                }
              >
                <ForecastChart data={forecast} />
              </Card>
            </div>

            <div className="w-full lg:w-[33%]">
              {/* MESSAGES */}
              <Card
                heading={
                  <div className="flex items-center gap-2">
                    Messaggi dei pazienti

                    <Info text="Lista di monitoraggi in cui i pazienti hanno lasciato una comunicazione scritta" />
                  </div>
                }
                className="flex flex-col w-full"
                contentProps={{
                  className: "flex-1 flex flex-col overflow-y-auto"
                }}
                style={{ maxHeight: messagesMaxHeight }}
              >
                <PatientsMessages
                  messages={messages}
                  onMessageRead={onMessageRead}
                  onReadAll={onReadAll}
                  listRef={messagesListRef}
                  onScroll={onScroll}
                />
              </Card>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default Dashboard
