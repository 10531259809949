import FloatWidget from "$components/FloatWidget"
import { createRef } from "react"
import { ReactElement, ReactNode } from "react"
import { Navigate, useLocation } from "react-router-dom"
import Header from "../../components/header/Header"
import Sidebar, { SidebarRef } from "../../components/sidebar/Sidebar"
import { authRedirectPath } from "../../constants/routes"
import useAuth from "../../hooks/useAuth"

type Props = {
  children: ReactNode
}

export const sidebar = createRef<SidebarRef>()

export default function PrivateWrapper({ children }: Props): ReactElement {
  const { user, company } = useAuth()
  const location = useLocation()

  const renderPage = (children: ReactNode): ReactNode => {
    return (
      <div className="flex h-screen overflow-hidden">
        <Sidebar ref={sidebar} />

        <div className="page__content">
          <Header />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 pt-8 pb-24 w-full max-w-9xl mx-auto">
              {children}
            </div>
          </main>
        </div>

        <FloatWidget />
      </div>
    )
  }

  const handleRedirect = (pathname: string, withSidebar = false): ReactNode => {
    return location.pathname !== pathname
      ? <Navigate to={{ pathname }} state={{ from: location }} />
      : withSidebar ? renderPage(children) : children
  }

  const renderByCompanyStatus = (): ReactNode => {
    switch (company?.status) {
      case 1:
        return handleRedirect("/account-complete")
      case 3:
      case 4:
      case 6:
        // rinnovo fallito
        return handleRedirect("/account")
      case 2:
      case 5:
      default:
        if (user?.newPwdRequested) {
          return handleRedirect("/account")
        }
        return location.pathname !== "/email-confirmation" && location.pathname !== "/account-complete"
          ? renderPage(children)
          : <Navigate to={{ pathname: authRedirectPath }} state={{ from: location }} />
    }
  }

  const renderByUserStatus = (): ReactNode => {
    switch (user?.status) {
      case 1:
        return location.pathname !== "/email-confirmation"
          ? <Navigate to={{ pathname: "/email-confirmation" }} state={{ from: location }} />
          : children
      case 2:
        return handleRedirect("/account-complete")
      case 3:
      case 4:
      default:
        return renderByCompanyStatus()
    }
  }

  return (
    <>
      {user
        ? renderByUserStatus()
        : <Navigate to={authRedirectPath} state={{ from: location }} replace/>
      }
    </>
  )
}
