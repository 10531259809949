import { FC, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import MonitoringAddForm from "../components/form/MonitoringAddForm"
import Title from "../components/Title"
import useError from "../hooks/useError"
import useQuery from "../hooks/useQuery"
import { GetPatientDTO } from "../types/api"
import api from "../util/api"

type Params = {
  id?: string
}

const Monitoring: FC = () => {
  const { id } = useParams<Params>()
  const query = useQuery()
  const patId = query.get("id")
  const { onError } = useError()

  // monitoring
  const monitoringId = useRef<number | null>(!isNaN(parseInt(`${id}`)) ? parseInt(`${id}`) : null).current

  // patient
  const patientId = useRef<number | null>(!isNaN(parseInt(`${patId}`)) ? parseInt(`${patId}`) : null).current
  const [patientLoading, setPatientLoading] = useState<boolean>(!!patientId || !!monitoringId)
  const [patientData, setPatientData] = useState<GetPatientDTO | null>(null)

  const getPatient = async(id: number): Promise<void> => {
    try {
      const res = await api.patients.getSingle(id)

      setPatientData(res.data.payload)
    } catch (err) {
      onError(err)
    } finally {
      setPatientLoading(false)
    }
  }

  useEffect(() => {
    if (patientId) {
      void (async(): Promise<void> => {
        await getPatient(patientId)
      })()
    }
  }, [])

  return (
    <>
      <div className="heading mb-10">
        <Title>Nuovo monitoraggio</Title>
      </div>

      <MonitoringAddForm
        isPreselected={!!patientId}
        patientData={patientData}
        setPatientData={setPatientData}
        patientLoading={patientLoading}
        setPatientLoading={setPatientLoading}
      />
    </>
  )
}

export default Monitoring
