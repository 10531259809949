import { useEffect, useState } from "react"
import { Device } from "../types/viewport"

type Sizes = {
  width: number
  height: number
}

type UseViewport = Sizes & {
  device: Device
}

const breakpoints = {
  767: "tablet",
  1180: "desktop"
}

const getDevice = (width: number): Device => {
  return Object.keys(breakpoints).reduce((device, w) => {
    // eslint-disable-next-line @typescript-eslint/ban-types
    return width > parseInt(w) ? breakpoints[w as unknown as keyof typeof breakpoints] : device
  }, "mobile") as Device
}

const useViewport = (): UseViewport => {
  const [state, setState] = useState<UseViewport>({
    width: window.innerWidth,
    height: window.innerHeight,
    device: getDevice(window.innerWidth)
  })

  useEffect(() => {
    const listener = (): void => {
      setState({
        width: window.innerWidth,
        height: window.innerHeight,
        device: getDevice(window.innerWidth)
      })
    }

    window.addEventListener("resize", listener)

    return (): void => {
      window.removeEventListener("resize", listener)
    }
  }, [])

  return state
}

export default useViewport
